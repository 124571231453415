import { fromUnixTime, format } from "date-fns";

export const formatUnixTickAsMMMyyyy = (tickItem: number) => {
  let date = fromUnixTime(tickItem);
  let formatted: string = format(date, "MMM yyyy");
  return formatted;
};

export const numberFormatter = (value: number) => {
  const floor: number = Math.floor(value);
  const asString: string = floor.toString();
  return asString;
};

export const formatUnixTickAsdoMMMyyyy = (tickItem: number) => {
  let date = fromUnixTime(tickItem);
  let formatted: string = format(date, "do MMM yyyy");
  return formatted;
};

export const formatUnixTickAsy = (tickItem: number) => {
  let date = fromUnixTime(tickItem);
  let formatted: string = format(date, "y");
  return formatted;
};

export function tooltipNumberFormatter(value: number) {
  let truncated = Math.trunc(value);
  return truncated.toLocaleString();
}

export function tooltipNumberFormatterDecimal1(value: number) {
  let truncated = formatNumber(value, 1);
  return truncated;
}

export function formatNumber(value: number, decimalPlaces: number) {
  let truncated = value.toFixed(decimalPlaces);
  return truncated.toLocaleString();
}

export function tooltipNumberFormatterStringToString(value: string) {
  let asNumber = parseFloat(value);
  let truncated = Math.trunc(asNumber);
  return truncated.toLocaleString();
}

export function tooltipNumberFormatterStringToString1dp(value: string) {
  let asNumber = parseFloat(value);
  let truncated = asNumber.toFixed(1);
  return truncated.toLocaleString();
}
