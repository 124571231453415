import { Typography } from "@mui/material";
import React from "react";

type Props = {
  children: React.ReactNode;
};

export const InlineItalic: React.FC<Props> = ({ children }) => {
  return (
    <Typography
      component="span"
      sx={{ fontStyle: "italic", display: "inline" }}
    >
      {children}
    </Typography>
  );
};
