import { createContext, useContext } from "react";
import { CareerDefn } from "../../Components/Inputs/Career/CareerDefinitionForm";
import {
  ReadAllCareerDefinitionsResponseDto,
  ReadAllExpenditureDefinitionsResponseDto,
} from "../../Types/apiResponses";
import {
  ProjectionScenariosRequest,
  UpdateAccountDefinitionRequest,
} from "../../Types/requests";
import { RechartsGenericMultipleInputs } from "../../Types/recharts";
import { ExpenditureDefn } from "../../Components/Inputs/Expenditure/ExpenditureDefinitionForm";
import { ProjectionsWithCaseInputsProps } from "../../Components/Blog/BlogPieces/2_InteractiveIntroductionToFF/ProjectionCharts/ProjectionsWithCaseInputs";
import { ChartSliderTableData } from "../../Components/Blog/BlogPieces/2_InteractiveIntroductionToFF/ProjectionCharts/ChartSliderTable";

export interface ICareerDefinitionApis {
  create: (careerDefinition: CareerDefn) => Promise<boolean>;
  readAll: () => Promise<ReadAllCareerDefinitionsResponseDto>;
  update: (
    careerDefinitionAndId: CareerDefn & { id: number }
  ) => Promise<boolean>;
  updateCurrent: (id: number) => Promise<boolean>;
  delete: (id: number) => Promise<boolean>;
}

export interface IExpenditureDefinitionApis {
  create: (expenditureDefinition: ExpenditureDefn) => Promise<boolean>;
  readAll: () => Promise<ReadAllExpenditureDefinitionsResponseDto>;
  update: (
    expenditureDefinitionAndId: ExpenditureDefn & { id: number }
  ) => Promise<boolean>;
  updateCurrent: (id: number) => Promise<boolean>;
  delete: (id: number) => Promise<boolean>;
}

export interface IAccountDefinitionApis {
  create: () => void;
  update: (dto: UpdateAccountDefinitionRequest) => void;
  delete: (accountId: number) => void;
}

export interface IProjectionApis {
  career: () => Promise<RechartsGenericMultipleInputs>;
}

export interface ISandboxProjectionApis {
  blank: (
    request: ProjectionScenariosRequest
  ) => Promise<RechartsGenericMultipleInputs>;
  scenarios: (
    request: ProjectionScenariosRequest
  ) => Promise<ProjectionsWithCaseInputsProps>;
  sliderScenarios: (
    request: ProjectionScenariosRequest
  ) => Promise<ChartSliderTableData>;
}

export interface IApiContext {
  accountDefinitionApis: IAccountDefinitionApis;
  expenditureDefinitionApis: IExpenditureDefinitionApis;
  careerDefinitionApis: ICareerDefinitionApis;
  projectionApis: IProjectionApis;
  sandboxProjectionApis: ISandboxProjectionApis;
}

export const ApiContext = createContext<IApiContext>(null!);
export const useApis = () => useContext(ApiContext);
