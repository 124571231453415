import React, { useContext, useState } from "react";
import {
  WhatIsYourFFAgeForm,
  WhatIsYourFFAgeFormValues,
} from "./WhatIsYourFFAgeForm";
import { WhatIsYourFFAgeResults } from "./WhatIsYourFFAgeResults";
import { ApiContext } from "../../../../Common/Context/ApiContext";
import { ProjectionScenariosRequest } from "../../../../Types/requests";
import { ProjectionsWithCaseInputsProps } from "../2_InteractiveIntroductionToFF/ProjectionCharts/ProjectionsWithCaseInputs";
import { getSandboxDobRequest } from "../../../../Types/inputs";
import { CreateAbstractUTCDate } from "../../../../Common/functions";

type Props = {};

export const WhatIsYourFFAge: React.FC<Props> = () => {
  const apis = useContext(ApiContext);
  const api = apis.sandboxProjectionApis.scenarios;

  const [calculationsComplete, setCalculationsComplete] = useState(false);
  const [results, setResults] = useState<ProjectionsWithCaseInputsProps | null>(
    null
  );

  const submitForm = async (formValues: WhatIsYourFFAgeFormValues) => {
    if (formValues.dateOfBirth === null)
      throw new Error("Can't process form if dob is null.");
    let dateFromForm = CreateAbstractUTCDate(formValues.dateOfBirth);

    try {
      const converted: ProjectionScenariosRequest = {
        generalInputs: {
          sandboxNetWorth: Number(formValues.netWorth),
          sandboxAge: getSandboxDobRequest(dateFromForm.toISOString()),
          personalDetail: {
            inheritanceToLeave: null,
            returnAboveInflationAssumption: 5,
            statePensionAge: null,
            statePensionAmount: null,
          },
          sandboxEarnings: Number(formValues.annualIncome),
          sandboxExpenditure: Number(formValues.annualExpenditure),
        },
        expenditureScenario: null,
        realReturnScenario: null,
        borrowingScenario: null,
      };
      const response: ProjectionsWithCaseInputsProps = await api(converted);
      setResults(response);
      setCalculationsComplete(true);
    } catch (error) {
      setResults(null);
      setCalculationsComplete(false);
    }
  };

  return (
    <>
      {calculationsComplete && results ? (
        <WhatIsYourFFAgeResults results={results} />
      ) : (
        <WhatIsYourFFAgeForm submitForm={submitForm} />
      )}
    </>
  );
};
