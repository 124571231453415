import React from "react";
import { Grid, Container } from "@mui/material/";
import { Form } from "formik";
import { MyFieldWithTooltip } from "../Inputs/MyFieldWithTooltip";
import { FieldWrappedDatePicker } from "../Inputs/FieldWrappedDatePicker";

interface Props {}

export const GeneralInputsComponentSignUp: React.FC<Props> = () => {
  return (
    <Container disableGutters sx={{ py: 1, my: 1 }}>
      <Form>
        <Grid container spacing={6} alignItems="start">
          <Grid item xs={12}>
            {/* <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold", pb: "0.5rem" }}
                >
                  Personal details
                </Typography> */}
            <Grid container spacing={1}>
              <Grid item xs={12} container direction="row">
                <FieldWrappedDatePicker
                  label="Date of birth"
                  fieldName="dateOfBirth"
                />
              </Grid>

              <MyFieldWithTooltip
                tooltipText={"How much money you spend per year."}
                fieldName={"expenditure"}
                fieldLabel={"Lifestyle cost per year"}
              />
              <MyFieldWithTooltip
                tooltipText={
                  "After tax income (including pension). Don't include return from investments - this is accounted for in the investment return."
                }
                fieldName={"netEarnings"}
                fieldLabel={"Earnings per year (after tax)"}
              />
            </Grid>
          </Grid>
        </Grid>
      </Form>
      {/* <pre>{JSON.stringify(values, null, 2)}</pre>
          <pre>{JSON.stringify(errors, null, 2)}</pre>
          <pre>{JSON.stringify(touched, null, 2)}</pre> */}
    </Container>
  );
};
