import React from "react";
import {
  examplePersonGeneralInputs,
  examplePersonSetup,
} from "../ExamplePersonGeneralInputs";
import { ProjectionsFromCaseInputsSlider } from "./ProjectionsFromCaseInputsSlider";
import { RechartsGenericMultipleInputs } from "../../../../../Types/recharts";
import { formatNumber } from "../../../../../Common/formattingFunctions";

type Props = {};

export const WithInvestingProjectionSlider: React.FC<Props> = () => {
  return (
    <ProjectionsFromCaseInputsSlider
      initialSliderId={"Return0"}
      projectionScenariosRequest={{
        generalInputs: examplePersonGeneralInputs,
        expenditureScenario: examplePersonSetup.expenditureScenario,
        realReturnScenario: null,
        borrowingScenario: null,
        sliderRequest: "Return",
      }}
      updateResponse={(response: RechartsGenericMultipleInputs) => {
        return { ...response, yAxisDomain: [0, 450000] };
      }}
      valueLabelFormat={function (value: number, index: number): string {
        return formatNumber(value * 100, 1) + "%";
      }}
    />
  );
};
