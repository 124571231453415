import React from "react";
import { Form, Formik, Field, FormikProps, FieldArray, getIn } from "formik";
import * as Yup from "yup";
import { FormTextField } from "../FormTextField";
import { Box, Button, TextField, Typography } from "@mui/material";

import {
  GetTodayNoHours,
  opulColors,
} from "../../../Common/Constants/Constants";

const now = GetTodayNoHours();

const validationSchema = Yup.object({
  name: Yup.string().required("Required"),
  earnings: Yup.array()
    .of(
      Yup.object().shape({
        date: Yup.date()
          .required("Date is required.")
          .typeError("Invalid date"),
        value: Yup.number()
          .required("Value is required.")
          .typeError("Value must be a number"),
      })
    )
    .required("Add at least one date")
    .min(1, "Add at least one date")
    .test("first-date-max", `First date can't be in the future`, (earnings) => {
      if (!earnings || earnings.length === 0) return true; // Pass validation if no dates
      const firstDate = earnings[0];
      return firstDate.date! <= now;
    })
    .test(
      "are-dates-in-order",
      "Dates must be unique and in chronological order",
      (earnings) => {
        if (!earnings) return false;

        for (let i = 0; i < earnings.length - 1; i++) {
          if (earnings === undefined) {
            return false;
          }
          const ithDate = earnings[i].date;
          const nextDate = earnings[i + 1].date;
          if (ithDate && nextDate && ithDate >= nextDate) {
            return false;
          }
        }

        return true;
      }
    ),
  costs: Yup.array()
    .of(
      Yup.object().shape({
        date: Yup.date()
          .required("Date is required.")
          .typeError("Invalid date"),
        value: Yup.number()
          .required("Value is required.")
          .typeError("Value must be a number"),
      })
    )
    .test(
      "are-dates-in-order",
      "Dates must be unique and in chronological order",
      (costs) => {
        if (!costs) return false;

        for (let i = 0; i < costs.length - 1; i++) {
          if (costs === undefined) {
            return false;
          }
          const ithDate = costs[i].date;
          const nextDate = costs[i + 1].date;
          if (ithDate && nextDate && ithDate >= nextDate) {
            return false;
          }
        }

        return true;
      }
    ),
});

export interface CareerDefn {
  name: string;
  earnings: { date: string; value: number }[];
  costs: { date: string; value: number }[];
}

const defaultInitialValues: CareerDefn = {
  name: "New career definition",
  earnings: [{ date: "", value: 0 }],
  costs: [],
};

type Props = {
  handleFormSubmit: (careerDefinition: CareerDefn) => void;
  initialValues?: CareerDefn;
  extraButtons?: {
    handleDeleteClick: () => void;
    handleMakeDefaultClick: () => void;
  };
};

export const CareerDefinitionForm: React.FC<Props> = ({
  handleFormSubmit,
  initialValues = defaultInitialValues,
  extraButtons,
}) => {
  const handleSubmit = (
    values: CareerDefn,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    setSubmitting(true);
    handleFormSubmit(values);
    setSubmitting(false);
  };

  return (
    <>
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) =>
          handleSubmit(values, { setSubmitting })
        }
      >
        {({
          values,
          handleChange,
          handleBlur,
          errors,
          touched,
        }: FormikProps<CareerDefn>) => (
          <Form>
            <Field
              name="name"
              label="Name"
              component={FormTextField}
              margin="dense"
            />
            <br />
            <br />

            <Typography variant="h6">Earnings by date</Typography>
            <FieldArray
              name="earnings"
              render={(arrayHelpers) => (
                <div>
                  {values.earnings.map((pair, index) => (
                    <div key={index}>
                      <Field
                        as={TextField}
                        name={`earnings.${index}.date`}
                        type="date"
                        size="small"
                        margin="dense"
                        value={pair.date}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        sx={{ mt: 1 }}
                        error={Boolean(
                          getIn(errors, `earnings[${index}].date`) &&
                            getIn(touched, `earnings[${index}].date`)
                        )}
                        helperText={
                          getIn(touched, `earnings[${index}].date`) &&
                          getIn(errors, `earnings[${index}].date`)
                            ? getIn(errors, `earnings[${index}].date`)
                            : ""
                        }
                      />
                      <Field
                        as={TextField}
                        name={`earnings.${index}.value`}
                        type="number"
                        label="Value"
                        size="small"
                        margin="dense"
                        value={pair.value}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        sx={{ mt: 1, ml: 2 }}
                        error={Boolean(
                          getIn(errors, `earnings[${index}].value`) &&
                            getIn(touched, `earnings[${index}].value`)
                        )}
                        helperText={
                          getIn(touched, `earnings[${index}].value`) &&
                          getIn(errors, `earnings[${index}].value`)
                            ? getIn(errors, `earnings[${index}].value`)
                            : ""
                        }
                      />
                      <Button
                        type="button"
                        onClick={() => arrayHelpers.remove(index)}
                        sx={{ m: 1 }}
                      >
                        Remove
                      </Button>
                    </div>
                  ))}
                  <Button
                    type="button"
                    onClick={() => arrayHelpers.push({ date: "", value: 0 })}
                    sx={{ m: 1 }}
                  >
                    Add earnings date
                  </Button>
                </div>
              )}
            />

            {errors.earnings && !Array.isArray(errors.earnings) && (
              <Typography
                sx={{ mb: 2, color: (theme) => theme.palette.error.main }}
              >
                {errors.earnings}
              </Typography>
            )}

            <br />
            <Typography variant="h6">One-off costs by date</Typography>
            <FieldArray
              name="costs"
              render={(arrayHelpers) => (
                <div>
                  {values.costs.map((pair, index) => (
                    <div key={index}>
                      <Field
                        as={TextField}
                        name={`costs.${index}.date`}
                        type="date"
                        size="small"
                        margin="dense"
                        // label="Date"
                        value={pair.date}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        sx={{ mt: 1 }}
                        error={Boolean(
                          getIn(errors, `costs[${index}].date`) &&
                            getIn(touched, `costs[${index}].date`)
                        )}
                        helperText={
                          getIn(touched, `costs[${index}].date`) &&
                          getIn(errors, `costs[${index}].date`)
                            ? getIn(errors, `costs[${index}].date`)
                            : ""
                        }
                      />
                      <Field
                        as={TextField}
                        name={`costs.${index}.value`}
                        type="number"
                        label="Value"
                        size="small"
                        margin="dense"
                        value={pair.value}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        sx={{ mt: 1, ml: 2 }}
                        error={Boolean(
                          getIn(errors, `costs[${index}].value`) &&
                            getIn(touched, `costs[${index}].value`)
                        )}
                        helperText={
                          getIn(touched, `costs[${index}].value`) &&
                          getIn(errors, `costs[${index}].value`)
                            ? getIn(errors, `costs[${index}].value`)
                            : ""
                        }
                      />
                      <Button
                        type="button"
                        onClick={() => arrayHelpers.remove(index)}
                        sx={{ m: 1 }}
                      >
                        Remove
                      </Button>
                    </div>
                  ))}
                  <Button
                    type="button"
                    onClick={() => arrayHelpers.push({ date: "", value: 0 })}
                    sx={{ m: 1 }}
                  >
                    Add cost
                  </Button>
                </div>
              )}
            />

            {errors.costs && !Array.isArray(errors.costs) && (
              <Typography
                sx={{ mb: 2, color: (theme) => theme.palette.error.main }}
              >
                {errors.costs}
              </Typography>
            )}

            <Box display="flex" justifyContent="space-between">
              <Button
                type="submit"
                variant="contained"
                size="large"
                sx={{
                  textTransform: "none",
                  backgroundColor: opulColors.opulDarkBlue,
                  color: "common.white",
                  py: 1,
                  px: 7,
                  fontSize: 20,
                }}
              >
                Save
              </Button>
              {extraButtons && (
                <Button
                  variant="contained"
                  size="large"
                  sx={{
                    textTransform: "none",
                    backgroundColor: opulColors.opulDarkGreen,
                    // "&:hover": {
                    //   backgroundColor: opulColors.deleteRedHover,
                    // },
                    color: "common.white",
                    py: 1,
                    px: 7,
                    fontSize: 20,
                  }}
                  onClick={extraButtons.handleMakeDefaultClick}
                >
                  Set as current
                </Button>
              )}
              {extraButtons && (
                <Button
                  variant="contained"
                  size="large"
                  sx={{
                    textTransform: "none",
                    backgroundColor: opulColors.deleteRed,
                    "&:hover": {
                      backgroundColor: opulColors.deleteRedHover,
                    },
                    color: "common.white",
                    py: 1,
                    px: 7,
                    fontSize: 20,
                  }}
                  onClick={extraButtons.handleDeleteClick}
                >
                  Delete definition
                </Button>
              )}
            </Box>
            {/* <div>Values</div>
            <pre>{JSON.stringify(values, null, 2)}</pre>
            <div>Errors</div>
            <pre>{JSON.stringify(errors, null, 2)}</pre>
            <div>Touched</div>
            <pre>{JSON.stringify(touched, null, 2)}</pre> */}
          </Form>
        )}
      </Formik>
    </>
  );
};
