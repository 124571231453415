import React from "react";
import { Grid, Button, Container, Typography, Box, Link } from "@mui/material/";
import { Form, Formik, Field } from "formik";
import { FormTextField } from "../FormTextField";
import * as Yup from "yup";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { opulColors } from "../../../Common/Constants/Constants";
import { MyFieldWithTooltip } from "../MyFieldWithTooltip";
import { HtmlTooltip } from "../../Generic/HtmlTooltip";
import { PersistentUserGeneralInputs } from "../../../Types/inputs";
import { convertStrToNumNullable } from "../../../Common/functions";
import { FieldWrappedDatePicker } from "../FieldWrappedDatePicker";

interface IGeneralInputsProps {
  generalInputs: PersistentUserGeneralInputs | undefined;
  handleGeneralInputsChange: (
    generalInputs: PersistentUserGeneralInputs
  ) => void;
}

export const GeneralInputsComponent: React.FC<IGeneralInputsProps> = ({
  generalInputs,
  handleGeneralInputsChange,
}) => {
  const today = new Date();
  today.setHours(23, 59, 59, 999);
  const validationSchema = Yup.object().shape({
    dateOfBirth: Yup.date()
      .required("Required")
      .typeError("Invalid date")
      .max(today, "Can't be in the future")
      .min(new Date(1900, 0, 1), "Can't be before 1900")
      .nullable(),
    personalDetail: Yup.object().shape({
      inheritanceToLeave: Yup.number()
        .min(0, "Must be positive")
        .typeError("Must be a number")
        .nullable(),
      returnAboveInflationAssumption: Yup.number()
        .required("Required")
        .min(0, "Must be positive")
        .max(8, "Must be 8 or less")
        .typeError("Must be a number"),
      statePensionAge: Yup.number()
        .min(55, "Must be 55 or more")
        .max(75, "Must be 75 or less")
        .typeError("Must be a number")
        .nullable(),
      statePensionAmount: Yup.number()
        .min(0, "Must be positive")
        .typeError("Must be a number")
        .nullable(),
    }),
  });

  const initialFormikState = GetInitialState(generalInputs);

  function transformToCleanedData(
    values: InitialGeneralInputsFormState
  ): PersistentUserGeneralInputs {
    if (!values.dateOfBirth) throw new Error();
    return {
      dateOfBirth: values.dateOfBirth,
      personalDetail: {
        inheritanceToLeave: convertStrToNumNullable(
          values.personalDetail.inheritanceToLeave
        ),
        returnAboveInflationAssumption: Number(
          values.personalDetail.returnAboveInflationAssumption
        ),
        statePensionAge: convertStrToNumNullable(
          values.personalDetail.statePensionAge
        ),
        statePensionAmount: convertStrToNumNullable(
          values.personalDetail.statePensionAmount
        ),
      },
    };
  }

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={initialFormikState}
      onSubmit={(data, { setSubmitting }) => {
        setSubmitting(true);

        let newGeneralInputs = transformToCleanedData(data);
        handleGeneralInputsChange(newGeneralInputs);

        setSubmitting(false);
      }}
    >
      {({ values, errors, setFieldValue, touched, setFieldTouched }) => (
        <Container sx={{ py: 1, my: 1 }}>
          <Form>
            <Grid container spacing={6} alignItems="start">
              <Grid container item md={6} maxWidth="xs">
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold", pb: "0.5rem" }}
                >
                  Personal details
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xs={12} container direction="row">
                    <FieldWrappedDatePicker
                      label="Date of birth"
                      fieldName="dateOfBirth"
                    />
                  </Grid>
                  <MyFieldWithTooltip
                    tooltipText={"Money left at the end of your life."}
                    fieldName={"personalDetail.inheritanceToLeave"}
                    fieldLabel={"Inheritance to leave behind"}
                  />
                </Grid>
              </Grid>
              <Grid container item md={6} maxWidth="xs">
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold", pb: "0.5rem" }}
                >
                  Model assumptions
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xs={11}>
                    <Field
                      name="personalDetail.returnAboveInflationAssumption"
                      label="Investment return above inflation (% pa)"
                      component={FormTextField}
                      margin="dense"
                      fullWidth
                      size="small"
                    />
                  </Grid>
                  <Grid
                    container
                    item
                    xs={1}
                    sx={{ alignItems: "center", justifyContent: "center" }}
                  >
                    <HtmlTooltip
                      title={
                        <>
                          This is a long-term model, and so calls for long-term
                          assumptions.{" "}
                          <Link
                            color="#ffffff"
                            href="https://www.credit-suisse.com/media/assets/corporate/docs/about-us/research/publications/credit-suisse-global-investment-returns-yearbook-2022-summary-edition.pdf"
                          >
                            Credit Suisse Yearbook 2022
                          </Link>{" "}
                          puts the annualised return above inflation for
                          equities at around 5-6% (based on ~120 years of data).
                        </>
                      }
                    >
                      <InfoOutlinedIcon />
                    </HtmlTooltip>
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      name="personalDetail.statePensionAge"
                      label="State pension age"
                      component={FormTextField}
                      fullWidth
                      margin="dense"
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      name="personalDetail.statePensionAmount"
                      label="State pension amount per year"
                      component={FormTextField}
                      fullWidth
                      margin="dense"
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography sx={{ ml: 1.5, my: 1 }}>
                      You live in a developed country
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography sx={{ ml: 1.5, my: 1 }}>
                      Everything is linked to inflation
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <br />
            <Box display="flex" justifyContent="center">
              <Button
                type="submit"
                variant="contained"
                size="large"
                sx={{
                  textTransform: "none",
                  backgroundColor: opulColors.opulDarkBlue,
                  color: "common.white",
                  py: 1,
                  px: 7,
                  fontSize: 20,
                }}
              >
                Save
              </Button>
            </Box>
          </Form>
          {/* <pre>{JSON.stringify(values, null, 2)}</pre>
          <pre>{JSON.stringify(errors, null, 2)}</pre>
          <pre>{JSON.stringify(touched, null, 2)}</pre> */}
        </Container>
      )}
    </Formik>
  );
};

function GetInitialState(
  generalInputs: PersistentUserGeneralInputs | undefined
) {
  let initialFormikState: InitialGeneralInputsFormState;
  if (generalInputs) {
    initialFormikState = {
      dateOfBirth: generalInputs.dateOfBirth,
      personalDetail: {
        inheritanceToLeave:
          generalInputs.personalDetail.inheritanceToLeave?.toString(),
        returnAboveInflationAssumption:
          generalInputs.personalDetail.returnAboveInflationAssumption.toString(),
        statePensionAge:
          generalInputs.personalDetail.statePensionAge?.toString(),
        statePensionAmount:
          generalInputs.personalDetail.statePensionAmount?.toString(),
      },
    };
  } else {
    initialFormikState = GetBlankInitialState();
  }
  return initialFormikState;
}

function GetBlankInitialState() {
  const initialBlankFormikState = {
    dateOfBirth: null,
    personalDetail: {
      inheritanceToLeave: null,
      returnAboveInflationAssumption: "5",
      statePensionAge: null,
      statePensionAmount: null,
    },
  };
  return initialBlankFormikState;
}

type InitialGeneralInputsFormState = {
  dateOfBirth: Date | null;
  personalDetail: {
    inheritanceToLeave: string | null | undefined;
    returnAboveInflationAssumption: string;
    statePensionAge: string | null | undefined;
    statePensionAmount: string | null | undefined;
  };
};
