import { ProjectionUrls } from "../../Constants/Urls";
import { UserMode } from "../../Context/UserModeContext";
import { postApiRequest } from "../genericApiFunctions";

export const getProjectionRequests = (
  urls: ProjectionUrls,
  sessionToken: string,
  userMode: UserMode
) => {
  return {
    career: async () => {
      const url = urls.career;
      const response = await postApiRequest(url, sessionToken, {}, userMode);
      return response;
    },
  };
};
