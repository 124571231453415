import { AssetInputs } from "./assetTypes";

export type AllUserInputs = {
  generalInputs: PersistentUserGeneralInputs;
  assetInputs?: AssetInputs;
};

export type SandboxGeneralInputs = {
  sandboxNetWorth: number;
  sandboxAge: AgeOrDobRequest;
  personalDetail: PersonalDetailBasic;
  sandboxEarnings: number;
  sandboxExpenditure: number;
};

export enum SandboxAgeRequestType {
  Age = "Age",
  Dob = "Dob",
}

export const getSandboxDobRequest = (dob: string) => {
  const output: AgeOrDobRequest = {
    sandboxDob: dob,
    type: SandboxAgeRequestType.Dob,
  };
  return output;
};

export const getSandboxAgeRequest = (age: number) => {
  const output: AgeOrDobRequest = {
    sandboxAge: age,
    type: SandboxAgeRequestType.Age,
  };
  return output;
};

export type AgeOrDobRequest = {
  sandboxAge?: number;
  sandboxDob?: string;
  type: SandboxAgeRequestType;
};

export type PersistentUserGeneralInputs = {
  dateOfBirth: Date;
  personalDetail: PersonalDetailBasic;
};

export type PersonalDetailBasic = {
  inheritanceToLeave: number | null;
  returnAboveInflationAssumption: number;
  statePensionAge: number | null;
  statePensionAmount: number | null;
};

export type SalaryDefinition = {
  netAnnualEarnings: number;
  salaryGrowthAssumption: number | null;
  salaryGrowthYearsAssumption: number | null;
};

export type SavingsRateDefinition = {
  expenditure: number;
  netEarnings?: number;
};
