import { UpdateAccountDefinitionRequest } from "../../../Types/requests";
import { BaseCaseModelResults } from "../../../Types/results";
import { AccountDefinitionUrls } from "../../Constants/Urls";
import {
  getApiRequestThatSetsModelState,
  postApiRequestThatSetsModelState,
} from "../genericApiFunctions";

export const getAccountDefinitionRequests = (
  urls: AccountDefinitionUrls,
  sessionToken: string,
  setBaseResults: React.Dispatch<
    React.SetStateAction<BaseCaseModelResults | null>
  >,
  parseAndSetAllUserInputs: (data: any) => void
) => {
  return {
    update: (dto: UpdateAccountDefinitionRequest) => {
      const url = urls.update;
      postApiRequestThatSetsModelState(
        dto,
        url,
        sessionToken,
        setBaseResults,
        parseAndSetAllUserInputs
      );
    },

    create: () => {
      console.log("trying to create account.");
      const url = urls.create;
      getApiRequestThatSetsModelState(
        url,
        sessionToken,
        setBaseResults,
        parseAndSetAllUserInputs
      );
    },

    delete: (accountId: number) => {
      const url = urls.delete;
      postApiRequestThatSetsModelState(
        accountId,
        url,
        sessionToken,
        setBaseResults,
        parseAndSetAllUserInputs
      );
    },
  };
};
