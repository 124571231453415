import React, { useContext } from "react";
import {
  ProjectionsWithCaseInputs,
  ProjectionsWithCaseInputsProps,
} from "./ProjectionsWithCaseInputs";
import { ProjectionScenariosRequest } from "../../../../../Types/requests";
import { RechartsGenericMultipleInputs } from "../../../../../Types/recharts";
import { ApiContext } from "../../../../../Common/Context/ApiContext";
import { ComponentWrapperWithUseEffect } from "../../../../Generic/ComponentWrappers/ComponentWrapperWithUseEffect";

type Props = {
  projectionScenariosRequest: ProjectionScenariosRequest;
  updateResponse?: (
    chartInputs: RechartsGenericMultipleInputs
  ) => RechartsGenericMultipleInputs;
};

export const ProjectionsFromCaseInputs: React.FC<Props> = ({
  projectionScenariosRequest,
  updateResponse,
}) => {
  const apis = useContext(ApiContext);
  const api = apis.sandboxProjectionApis.scenarios;
  const apiClosure = () => api(projectionScenariosRequest);

  return (
    <ComponentWrapperWithUseEffect
      dataRetrieval={apiClosure}
      renderItem={(response: ProjectionsWithCaseInputsProps) => {
        let projections = response.projections;
        if (updateResponse) {
          projections = updateResponse(response.projections);
        }
        return (
          <ProjectionsWithCaseInputs
            projections={projections}
            tableData={response.tableData}
          />
        );
      }}
    />
  );
};
