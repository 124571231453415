import React, { useContext } from "react";
import { Box, Typography, Tab, Tabs } from "@mui/material";
import { PortfolionCalibrationRequest } from "../../../../Types/requests";
import { AssetInputs } from "../../../../Types/assetTypes";
import { MyTitle } from "../../../Generic/Title";
import { DashboardPaper } from "../../../Toolkit/Dashboard/DashboardPaper";
import { RechartsUnixSecondsStackedArea } from "../../../Toolkit/Charts/rechartsUnixSecondsStackedArea";
import { BaseCaseModelResultsContext } from "../../../../Common/Context/BaseCaseModelResultsContext";
import { MediumWidthContainer } from "../../../Generic/MediumWidthContainer";
import { a11yProps, TabPanel } from "../../../Generic/TabPanel";
import { AddCalibration } from "./AddCalibration";
import { EditCalibration } from "./EditCalibration";

type Props = {
  assetInputs: AssetInputs;
  portfolioCalibrationRequest: (dto: PortfolionCalibrationRequest) => void;
};

export const PortfolioAtDate: React.FC<Props> = ({
  assetInputs,
  portfolioCalibrationRequest,
}) => {
  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const baseResultsContext = useContext(BaseCaseModelResultsContext);

  return (
    <MediumWidthContainer pxForMediumScreen={0}>
      <MyTitle>Value calibrations</MyTitle>
      <Typography>
        This is where you add the values of your current portfolio. You can add
        values at the individual asset level for a complete calibration, or to
        make a quick update you can add values at the account level.
      </Typography>

      <br />
      <br />
      <DashboardPaper
        childComponent={
          baseResultsContext?.history?.accountHistory && (
            <RechartsUnixSecondsStackedArea
              setup={baseResultsContext.history.accountHistory}
            />
          )
        }
        titleText="Portfolio history"
        lgBreakpoint={7}
        xlBreakpoint={7}
        height={360}
      />
      <br />
      <br />

      <Box
        sx={{
          // border: "1px solid grey",
          bgcolor: "white",
          height: "100%",
          overflow: "auto",
        }}
      >
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            justifyContent: "center",
          }}
        >
          <Tabs centered value={tabValue} onChange={handleTabChange}>
            <Tab label="Add" {...a11yProps(0)} />
            <Tab label="Edit" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={tabValue} index={0}>
          <AddCalibration
            assetInputs={assetInputs}
            portfolioCalibrationRequest={portfolioCalibrationRequest}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <EditCalibration
            assetInputs={assetInputs}
            portfolioCalibrationRequest={portfolioCalibrationRequest}
          />
        </TabPanel>
      </Box>
    </MediumWidthContainer>
  );
};
