import { BaseCaseModelResults } from "../../../Types/results";
import { Urls } from "../../Constants/Urls";
import { IApiContext } from "../../Context/ApiContext";
import { UserMode } from "../../Context/UserModeContext";
import { getAccountDefinitionRequests } from "./accountDefinitionApiFunctions";
import { getCareerDefinitionRequests } from "./careerDefinitionApiFunctions";
import { getExpenditureDefinitionRequests } from "./expenditureDefinitionApiFunctions";
import { getProjectionRequests } from "./projectionApiFunctions";
import { getSandboxProjectionRequests } from "./sandboxProjectionApiFunctions";

export const getApiRequests = (
  urls: Urls,
  sessionToken: string,
  userMode: UserMode,
  setBaseResults: React.Dispatch<
    React.SetStateAction<BaseCaseModelResults | null>
  >,
  parseAndSetAllUserInputs: (data: any) => void
): IApiContext => {
  return {
    careerDefinitionApis: getCareerDefinitionRequests(
      urls.careerDefinitionUrls,
      sessionToken,
      userMode
    ),
    expenditureDefinitionApis: getExpenditureDefinitionRequests(
      urls.expenditureDefinitionUrls,
      sessionToken,
      userMode
    ),
    accountDefinitionApis: getAccountDefinitionRequests(
      urls.accountDefinitionUrls,
      sessionToken,
      setBaseResults,
      parseAndSetAllUserInputs
    ),
    projectionApis: getProjectionRequests(
      urls.projectionUrls,
      sessionToken,
      userMode
    ),
    sandboxProjectionApis: getSandboxProjectionRequests(
      urls.sandboxProjectionUrls
    ),
  };
};
