import React, { Dispatch, SetStateAction, useContext, useState } from "react";
import {
  Button,
  Grid,
  Typography,
  Container,
  Paper,
  Slider,
  Box,
  Tooltip,
} from "@mui/material/";
import { opulColors, pageIds } from "../../Common/Constants/Constants";
import { RechartsChartMultipleGeneric } from "../Toolkit/Charts/RechartsChartMultipleGeneric";
import { useNavigate } from "react-router-dom";
import testData from "./TestData.json";
import { getApiRequestThatSetsModelState } from "../../Common/Apis/genericApiFunctions";
import { UserMode } from "../../Common/Context/UserModeContext";
import { UrlContext } from "../../Common/Context/UrlContext";
import { UserTokenContext } from "../../Common/Context/UserTokenContext";
import { BaseCaseModelResults } from "../../Types/results";
import { AllUserInputs } from "../../Types/inputs";
import {
  numberFormatter,
  tooltipNumberFormatterStringToString,
} from "../../Common/formattingFunctions";
import { MediumWidthContainer } from "../Generic/MediumWidthContainer";

const colorOfHighlightedLine = opulColors.opulDarkBlue;

const defaultSliderValue = 60;
const mapFromSliderValueToId = new Map();
mapFromSliderValueToId.set(10, 0);
mapFromSliderValueToId.set(20, 1);
mapFromSliderValueToId.set(30, 2);
mapFromSliderValueToId.set(40, 3);
mapFromSliderValueToId.set(50, 4);
mapFromSliderValueToId.set(60, 5);
mapFromSliderValueToId.set(70, 6);
mapFromSliderValueToId.set(80, 7);
mapFromSliderValueToId.set(90, 8);

const GetInitialKeys = () => {
  const key: number = mapFromSliderValueToId.get(defaultSliderValue);

  const initialKeys = testData.keys.map((c, i) => {
    if (i === key) {
      return { ...c, resultsColor: colorOfHighlightedLine };
    } else {
      return { ...c, resultsColor: opulColors.baseCaseColor };
    }
  });

  return initialKeys;
};
let initialKeys = GetInitialKeys();

const financialFreedomDefinitionText =
  "A state of freedom where you can spend your time as you wish, free from the need to work for money. Waking up each day and deciding how you want to live it. The only requirement? Enough wealth to support your desired lifestyle. The default age for financial freedom is after we have used up most of our health and time aged around 70, when the state provides a pension.";

interface IHomepageProps {
  resetApp(): void;
  setResults: Dispatch<SetStateAction<BaseCaseModelResults | null>>;
  setAllUserInputs: Dispatch<SetStateAction<AllUserInputs | null>>;
  setUserMode: Dispatch<SetStateAction<UserMode>>;
}

export const Homepage: React.FC<IHomepageProps> = ({
  resetApp,
  setResults,
  setAllUserInputs,
  setUserMode,
}) => {
  const urls = useContext(UrlContext);
  const sessionToken = useContext(UserTokenContext);
  const navigate = useNavigate();
  const [stateKeys, setStateKeys] = useState(initialKeys);
  const [sliderValue, setSliderValue] = useState(defaultSliderValue);

  const onSliderChange = (
    event: Event,
    value: number | number[],
    activeThumb: number
  ) => {
    const newSliderId: number = mapFromSliderValueToId.get(value);

    const newStateKeys = stateKeys.map((k, i) => {
      if (i === newSliderId) {
        return { ...k, resultsColor: colorOfHighlightedLine }; // Edit the property of the matching item
      } else {
        return { ...k, resultsColor: opulColors.baseCaseColor };
      }
    });
    setStateKeys(newStateKeys);
    setSliderValue(value as number);
    return;
  };

  const getReferenceLineValue = () => {
    const key: number = mapFromSliderValueToId.get(sliderValue);
    return stateKeys[key].referenceLineValue;
  };

  const handleViewDemo = () => {
    resetApp();
    getApiRequestThatSetsModelState(
      urls.demoUserState,
      sessionToken,
      setResults,
      setAllUserInputs
    );
    setUserMode(UserMode.Demo);
    navigate("/" + pageIds.dashboard);
  };

  return (
    <MediumWidthContainer pxForMediumScreen={0}>
      <Typography
        variant="h5"
        sx={{
          fontWeight: "bold",
          pb: "0.5rem",
        }}
      >
        Opul - Be financially confident. Achieve financial freedom.
      </Typography>
      <Box sx={{ pb: 2 }}>
        <Typography variant="h6" display="inline">
          Here to help you make{" "}
        </Typography>
        <Typography
          variant="h6"
          display="inline"
          color="primary"
          sx={{ fontWeight: "bold" }}
        >
          better financial decisions
        </Typography>{" "}
        <Typography variant="h6" display="inline">
          as you progress towards your{" "}
        </Typography>
        <Tooltip title={financialFreedomDefinitionText} arrow>
          <Typography
            variant="h6"
            display="inline"
            sx={{ fontWeight: "bold", color: opulColors.opulLightGreen }}
          >
            financial freedom
          </Typography>
        </Tooltip>{" "}
        <Typography variant="h6" display="inline">
          goals. Opul provides an easy way to track your finances, with
          modelling tools to answer questions such as:
          <Box sx={{ fontStyle: "italic" }}>
            <ul>
              <li key="1">
                Do I want to spend less or spend more? (demo below)
              </li>
              <li key="2">When can I reach Financial Freedom? </li>
              <li key="3">Can I afford to move to a 4 day week?</li>
            </ul>
          </Box>
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper
            elevation={0}
            sx={{
              bgcolor: "#f5f5f5",
              height: "300px",
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <RechartsChartMultipleGeneric
              inputs={{
                data: testData.data,
                seriesMetaData: stateKeys,
                xAxisName: testData.xAxisName,
                yAxisName: testData.yAxisName,
                referenceLines: [
                  {
                    text: "Financial freedom",
                    xAxisValue: getReferenceLineValue(),
                    color: colorOfHighlightedLine,
                  },
                ],
                xAxisTickFormatter: numberFormatter,
                yAxisTickFormatter: () => "",
                isAnimationActive: false,
                tooltipFormatter: tooltipNumberFormatterStringToString,
              }}
            />
            <Box sx={{ pl: 2, pr: 2 }}>
              <Slider
                defaultValue={defaultSliderValue}
                // valueLabelDisplay="auto"
                step={10}
                marks
                min={10}
                max={90}
                onChange={onSliderChange}
                // sx={{ ml: 4, mr: 10 }}
              />
              <Grid container sx={{ justifyContent: "space-between" }}>
                <Typography display="inline" sx={{ textAlign: "left" }}>
                  Spend less...
                </Typography>
                <Typography display="inline" sx={{ textAlign: "right" }}>
                  ... or spend more?
                </Typography>
              </Grid>
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <br />
      <Box display="flex" justifyContent="center">
        <Button
          color="inherit"
          variant="contained"
          onClick={() => handleViewDemo()}
          size="large"
          sx={{
            textTransform: "none",
            backgroundColor: opulColors.opulDarkBlue,
            color: "common.white",
            // height: "56px",
            fontSize: 20,
            py: 2,
            px: 7,
            // borderRadius: 0,
          }}
        >
          {/* SPIKE refactor to remove the nesting here */}
          <Typography variant="h6">View full demo</Typography>
        </Button>
        {/* <Button
          color="inherit"
          variant="contained"
          onClick={() => handleEnterSandbox()}
          size="large"
          sx={{
            textTransform: "none",
            backgroundColor: colors.opulDarkGreen,
            color: "common.white",
            // height: "56px",
            fontSize: 20,
            py: 2,
            px: 7,
            // borderRadius: 0,
          }}
        >
          <Typography variant="h6">Enter sandbox</Typography>
        </Button> */}
      </Box>
    </MediumWidthContainer>
  );
};
