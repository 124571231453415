import { Box, Button, Slider } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { UrlContext } from "../../../Common/Context/UrlContext";
import { opulColors, scenarioIds } from "../../../Common/Constants/Constants";
import { BoldColouredTypography } from "../../Generic/BoldColouredTypography";
import { postApiRequest } from "../../../Common/Apis/genericApiFunctions";
import { UserTokenContext } from "../../../Common/Context/UserTokenContext";
import { RechartsGenericMultipleInputs } from "../../../Types/recharts";
import { StringKeyNumberValue } from "../../../Types/myPrimitives";
import { colorOfHighlightedLine } from "./ProjectionModelling";
import { UserModeContext } from "../../../Common/Context/UserModeContext";
import { SliderResults } from "../../../Types/results";

type Props = {
  currentLegacy: number | null;
  setProjectionsState: React.Dispatch<
    React.SetStateAction<RechartsGenericMultipleInputs>
  >;
  setProjectionPageState: (sliderResults: SliderResults) => void;

  projections: RechartsGenericMultipleInputs;
};

//This component on load needs to request the slider values and results.
//This component needs to be able to send off values from inputs & chart results.
//Unoptimised - this can be a recalculate of all the slider results to be plotted straight away
export const LegacyInputs: React.FC<Props> = ({
  currentLegacy,
  setProjectionsState,
  setProjectionPageState,
  projections,
}) => {
  //Context and state
  const urls = useContext(UrlContext);
  const token = useContext(UserTokenContext);
  const userMode = useContext(UserModeContext);

  const [sliderMap, setSliderMap] = useState<Map<number, string>>(
    new Map<number, string>()
  );
  const initialMarks = [
    {
      value: 0,
      label: "",
    },
    { value: 50, label: currentLegacy },
    { value: 100, label: "" },
  ];
  const [sliderMarks, setSliderMarks] = useState(initialMarks);

  //Functions
  useEffect(() => {
    legacyScenarioApiRequest();
  }, []);

  const setMapAndMarks = (sliderValuesById: StringKeyNumberValue[]) => {
    const mapFromSliderValueToId = new Map<number, string>();
    const newSliderMarks: { value: number; label: string }[] = [];
    sliderValuesById.forEach((element) => {
      mapFromSliderValueToId.set(element.value, element.key);
      newSliderMarks.push({
        value: element.value,
        label: element.key,
      });
    });
    setSliderMap(mapFromSliderValueToId);
    setSliderMarks(newSliderMarks);
  };

  const legacyScenarioApiRequest = async () => {
    const apiResponseData: SliderResults = await postApiRequest(
      urls.legacyScenarioAnalysis,
      token,
      "",
      userMode
    );
    setProjectionPageState(apiResponseData);
    setMapAndMarks(apiResponseData.sliderValuesById);
  };

  const onSliderChange = (
    event: Event,
    value: number | number[],
    activeThumb: number
  ) => {
    const newSliderId = sliderMap.get(value as number);

    //return new RechartsSeriesObjectWithUpdatedColors
    const newStateKeys = projections.seriesMetaData.map((k, i) => {
      if (k.key === newSliderId) {
        return { ...k, resultsColor: colorOfHighlightedLine, strokeWidth: 3 }; // Edit the property of the matching item
      } else if (k.key === scenarioIds.base) {
        return { ...k, resultsColor: opulColors.baseCaseColor, strokeWidth: 3 };
      } else if (k.key === scenarioIds.alternate + "1") {
        return { ...k, resultsColor: opulColors.opulCyan, strokeWidth: 3 };
      } else if (k.key === scenarioIds.alternate + "2") {
        return { ...k, resultsColor: opulColors.opulPurple, strokeWidth: 3 };
      } else {
        return { ...k, resultsColor: opulColors.opulLightGrey, strokeWidth: 2 };
      }
    });

    //setStateWithUpdatedColors
    setProjectionsState((projections) => ({
      ...projections,
      seriesMetaData: newStateKeys,
    }));
  };

  //JSX
  return (
    <Box
    // sx={{
    //   border: "1px solid grey",
    // }}
    >
      <BoldColouredTypography
        variant="body1"
        text={`Current legacy: ${currentLegacy}`}
        color={opulColors.baseCaseColor}
      />

      <Box sx={{ mx: 3 }}>
        <Slider
          defaultValue={50}
          valueLabelDisplay="auto"
          step={null}
          marks={sliderMarks}
          min={sliderMarks[0].value}
          max={sliderMarks[sliderMarks.length - 1].value}
          onChange={onSliderChange}
          // sx={{ ml: 4, mr: 10 }}
        />
        <Box display="flex" justifyContent="center">
          <Button
            // type="submit"
            variant="contained"
            size="large"
            onClick={legacyScenarioApiRequest}
            sx={{
              textTransform: "none",
              backgroundColor: opulColors.opulDarkBlue,
              color: "common.white",
              py: 1,
              // px: 0,
              fontSize: 16,
            }}
          >
            Run modelling
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
