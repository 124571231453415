import {
  SandboxGeneralInputs,
  getSandboxAgeRequest,
} from "../../../../Types/inputs";
import { ProjectionScenariosRequest } from "../../../../Types/requests";

export const examplePersonSetup = {
  name: "Emily",
  age: 25,
  expenditure: 20000,
  expenditureScenario: 15000,
  returnScenario: 5,
  earnings: 20000,
  savings: 40000,
};

export const examplePersonGeneralInputs: SandboxGeneralInputs = {
  personalDetail: {
    inheritanceToLeave: 40000,
    returnAboveInflationAssumption: 0,
    statePensionAge: null,
    statePensionAmount: null,
  },
  sandboxEarnings: examplePersonSetup.earnings,
  sandboxExpenditure: examplePersonSetup.expenditure,
  sandboxNetWorth: examplePersonSetup.savings,
  sandboxAge: getSandboxAgeRequest(examplePersonSetup.age),
};

export const borrowingScenario = {
  yearsUntil: 3,
  yearsLength: 25,
  amount: examplePersonSetup.earnings * 5.5,
  borrowingRate: 4,
};

export const ScenariosRequest: ProjectionScenariosRequest = {
  generalInputs: examplePersonGeneralInputs,
  expenditureScenario: 15000,
  realReturnScenario: 5,
  borrowingScenario: null,
};
