import React from "react";
import { Form, Formik, Field, FormikProps } from "formik";
import * as Yup from "yup";
import { Box, Button, Grid, Typography } from "@mui/material";
import { opulColors } from "../../../../Common/Constants/Constants";
import { FormTextField } from "../../../Inputs/FormTextField";
import { FieldWrappedDatePicker } from "../../../Inputs/FieldWrappedDatePicker";

let today = new Date();
today.setHours(0, 0, 0, 0);
let minDate = new Date(1924, 0, 1);
minDate.setHours(0, 0, 0, 0);

const validationSchema = Yup.object({
  dateOfBirth: Yup.date()
    .required("Required")
    .typeError("Must be a date")
    .min(minDate, "Can't be before 1924")
    .max(today, "Can't be after today"),
  annualIncome: Yup.number().typeError("Must be a number").required("Required"),
  annualExpenditure: Yup.number()
    .typeError("Must be a number")
    .required("Required"),
  netWorth: Yup.number().typeError("Must be a number").required("Required"),
});

export interface WhatIsYourFFAgeFormValues {
  dateOfBirth: Date | null;
  annualIncome: string;
  annualExpenditure: string;
  netWorth: string;
}

type Props = {
  submitForm: (dataObject: WhatIsYourFFAgeFormValues) => Promise<void>;
};

export const WhatIsYourFFAgeForm: React.FC<Props> = ({ submitForm }) => {
  const initialFormikState: WhatIsYourFFAgeFormValues = {
    dateOfBirth: null,
    annualIncome: "",
    annualExpenditure: "",
    netWorth: "",
  };

  const handleSubmit = async (
    values: WhatIsYourFFAgeFormValues,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    setSubmitting(true);
    await submitForm(values);
    setSubmitting(false);
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={initialFormikState}
      onSubmit={(values, { setSubmitting }) =>
        handleSubmit(values, { setSubmitting })
      }
    >
      {({
        values,
        handleChange,
        handleBlur,
        errors,
        touched,
      }: FormikProps<WhatIsYourFFAgeFormValues>) => (
        <>
          <Typography>
            Enter a few basic details and find out! This mini tool is uses a
            simple model to give a quick estimate.
          </Typography>
          <br />
          <Form>
            <Grid container spacing={2} alignItems="start" maxWidth="xs">
              <Grid item xs={6}>
                <FieldWrappedDatePicker
                  fieldName="dateOfBirth"
                  label="Date of birth"
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="annualIncome"
                  label="Annual income"
                  component={FormTextField}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="annualExpenditure"
                  label="Annual expenditure"
                  component={FormTextField}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  name="netWorth"
                  label="Net worth"
                  component={FormTextField}
                  fullWidth
                />
              </Grid>
            </Grid>
            <br />
            <Box display="flex" justifyContent="center">
              <Button
                type="submit"
                variant="contained"
                size="large"
                sx={{
                  textTransform: "none",
                  backgroundColor: opulColors.opulDarkBlue,
                  color: "common.white",
                  py: 1,
                  px: 7,
                  fontSize: 20,
                }}
              >
                Calculate
              </Button>
            </Box>
          </Form>
          {/* <div>Values</div>
          <pre>{JSON.stringify(values, null, 2)}</pre>
          <div>Errors</div>
          <pre>{JSON.stringify(errors, null, 2)}</pre>
          <div>Touched</div>
          <pre>{JSON.stringify(touched, null, 2)}</pre> */}
        </>
      )}
    </Formik>
  );
};
