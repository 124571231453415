import Grid from "@mui/material/Unstable_Grid2/Grid2";
import React, { useState } from "react";
import { MySlider } from "../../../../Generic/MySlider";
import {
  ConditionalTableData as ConditionalTableSetup,
  TableWithConditionalRow,
} from "./TableWithConditionalRow";
import { StringKeyNumberValue } from "../../../../../Types/myPrimitives";
import {
  ConditionalChartData as ConditionalProjectionChartSetup,
  ProjectionsChartWithConditionalLine,
} from "./ProjectionsChartWithConditionalLine";

export type ChartSliderTableData = {
  conditionalProjectionChartSetup: ConditionalProjectionChartSetup;
  conditionalTableSetup: ConditionalTableSetup;
  sliderValuesById: StringKeyNumberValue[];
};

type ChartSliderTableFormatting = {
  initialSliderId: string;
  valueLabelFormat: (value: number, index: number) => string;
};

export type ChartSliderTableProps = ChartSliderTableData &
  ChartSliderTableFormatting;

export const ChartSliderTable: React.FC<ChartSliderTableProps> = ({
  initialSliderId,
  sliderValuesById,
  conditionalTableSetup,
  conditionalProjectionChartSetup,
  valueLabelFormat,
}) => {
  const [sliderId, setSliderId] = useState(initialSliderId);
  const onSliderChange = (newId: string) => {
    setSliderId(newId);
  };
  return (
    <Grid container spacing={2}>
      <Grid xs={12}>
        <ProjectionsChartWithConditionalLine
          {...conditionalProjectionChartSetup}
          conditionalLineId={sliderId}
        />
      </Grid>
      <Grid xs={12} sx={{ px: 3 }}>
        <MySlider
          initialValue={
            sliderValuesById.find((item) => item.key === initialSliderId)!.value
          }
          sliderValuesById={sliderValuesById}
          onSliderChange={onSliderChange}
          valueLabelFormat={valueLabelFormat}
        />
      </Grid>
      <Grid xs={12}>
        <TableWithConditionalRow
          {...conditionalTableSetup}
          conditionalRowId={sliderId}
        />
      </Grid>
    </Grid>
  );
};
