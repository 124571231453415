import { Grid, Box, Button } from "@mui/material";
import { Formik, FieldArray, Form } from "formik";
import React from "react";
import {
  GetTodayNoHours,
  opulColors,
} from "../../../../Common/Constants/Constants";
import { AccountDefinition, AssetInputs } from "../../../../Types/assetTypes";
import * as yup from "yup";
import { PortfolionCalibrationRequest } from "../../../../Types/requests";
import { TypographyAndTextField } from "../../TypographyAndTextField";
import { FieldWrappedDatePicker } from "../../FieldWrappedDatePicker";

type Props = {
  assetInputs: AssetInputs;
  portfolioCalibrationRequest: (dto: PortfolionCalibrationRequest) => void;
};

export const AddCalibration: React.FC<Props> = ({
  assetInputs,
  portfolioCalibrationRequest,
}) => {
  const now = GetTodayNoHours();

  const validationSchema = yup.object({
    date: yup
      .date()
      .max(now, "Can't be in the future")
      .required("Required")
      .typeError("Invalid date"),
    accounts: yup.array().of(
      yup.object({
        value: yup.number().nullable().typeError("Must be a number"),
      })
    ),
    assets: yup.array().of(
      yup.object({
        value: yup.number().nullable().typeError("Must be a number"),
      })
    ),
  });

  const getInitialAssets = (accountDefinitions: AccountDefinition[]) => {
    let output: { id: number; value: number | null }[] = [];
    accountDefinitions.forEach(
      (account) =>
        account.assetDefinitions &&
        account.assetDefinitions.forEach((asset) =>
          output.push({ id: asset.nameAndId.id, value: null })
        )
    );
    return output;
  };

  type formikState = {
    accounts: {
      id: number;
      value: number | null;
    }[];
    assets: {
      id: number;
      value: number | null;
    }[];
    date: Date;
  };

  const initialFormikState: formikState = {
    accounts: assetInputs.accountDefinitions.map((account, index) => ({
      id: account.accountInfo.id,
      value: null,
    })),
    assets: getInitialAssets(assetInputs.accountDefinitions),
    date: now,
  };

  function createAccountAndAssetJSX(accountDefinitions: AccountDefinition[]) {
    let assetCount = 0;

    return accountDefinitions.map((account, accountIndex) => {
      return (
        <Box
          pb={2}
          mb={2}
          // sx={{ borderBottom: 1, borderColor: "grey.500" }}
          key={account.accountInfo.id}
        >
          <TypographyAndTextField
            name={account.accountInfo.name}
            fieldName={`accounts.${accountIndex}.value`}
            label="Value"
            bold={true}
          />
          {account.assetDefinitions?.map((asset, assetIndex) => {
            let assetJsx = (
              <div key={assetIndex}>
                <TypographyAndTextField
                  name={" - " + `${asset.nameAndId.name}`}
                  fieldName={`assets.${assetCount}.value`}
                  label="Value"
                />
              </div>
            );
            assetCount++;
            return assetJsx;
          })}
        </Box>
      );
    });
  }

  const filterFieldsWithValues = (
    input: { id: number; value: number | null }[]
  ) => {
    let output: { id: number; value: number }[] = [];
    input.forEach((x) => {
      if (x.value) {
        output.push({ id: x.id, value: x.value });
      }
    });
    return output;
  };

  const sendOutput = (formikState: formikState) => {
    const accounts = filterFieldsWithValues(formikState.accounts);
    const assets = filterFieldsWithValues(formikState.assets);

    const output = {
      accounts: accounts,
      assets: assets,
      date: formikState.date,
    };

    portfolioCalibrationRequest(output);
  };

  return (
    <Box sx={{ m: 2 }}>
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={initialFormikState}
        onSubmit={(data, { setSubmitting }) => {
          setSubmitting(true);
          sendOutput(data);
          setSubmitting(false);
        }}
      >
        {({ values, errors, touched, setFieldValue }) => (
          <Form>
            <Grid container justifyContent="flex-end">
              <FieldWrappedDatePicker
                label="Date of calibration"
                fieldName="date"
              />
            </Grid>
            <Grid container spacing={1} sx={{ pb: 1 }}>
              <Grid item xs={12}>
                <FieldArray name="assets">
                  {() => (
                    <div>
                      {createAccountAndAssetJSX(assetInputs.accountDefinitions)}
                    </div>
                  )}
                </FieldArray>
              </Grid>
            </Grid>
            <Box display="flex" justifyContent="right">
              <Button
                type="submit"
                variant="contained"
                size="large"
                sx={{
                  textTransform: "none",
                  backgroundColor: opulColors.opulDarkBlue,
                  color: "common.white",
                  // py: 1,
                  // px: 7,
                  // fontSize: 20,
                }}
              >
                Save portfolio calibration
              </Button>
            </Box>
            {/* <div>Values</div>
            <pre>{JSON.stringify(values, null, 2)}</pre>
            <div>Errors</div>
            <pre>{JSON.stringify(errors, null, 2)}</pre>
            <div>Touched</div>
            <pre>{JSON.stringify(touched, null, 2)}</pre> */}
          </Form>
        )}
      </Formik>
    </Box>
  );
};
