import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import { InteractiveIntroductionToFFArticleText } from "./InteractiveIntroductionToFFArticleText";

type Props = {};

export const InteractiveIntroductionToFF: React.FC<Props> = () => {
  return (
    <Grid container>
      <Grid xs={12}>
        <InteractiveIntroductionToFFArticleText />
      </Grid>
      {/* <Grid xs={3}>
        <GeneralInputsVerticalParameterTable />
      </Grid> */}
    </Grid>
  );
};
