import { ExpenditureDefn } from "../../../Components/Inputs/Expenditure/ExpenditureDefinitionForm";
import { ReadAllExpenditureDefinitionsResponseDto } from "../../../Types/apiResponses";
import { ExpenditureDefinitionUrls } from "../../Constants/Urls";
import { UserMode } from "../../Context/UserModeContext";
import { postApiRequest, getApiRequest } from "../genericApiFunctions";

export const getExpenditureDefinitionRequests = (
  urls: ExpenditureDefinitionUrls,
  sessionToken: string,
  userMode: UserMode
) => {
  return {
    create: async (expenditureDefn: ExpenditureDefn) => {
      const url = urls.create;
      const success: boolean = await postApiRequest(
        url,
        sessionToken,
        expenditureDefn,
        userMode
      );
      return success;
    },

    readAll: async () => {
      const url = urls.readAll;
      const data: ReadAllExpenditureDefinitionsResponseDto =
        await getApiRequest(url, sessionToken, userMode);
      return data;
    },

    update: async (expenditureDefnWithId: ExpenditureDefn & { id: number }) => {
      const url = urls.update;
      const success: boolean = await postApiRequest(
        url,
        sessionToken,
        expenditureDefnWithId,
        userMode
      );
      return success;
    },

    updateCurrent: async (id: number) => {
      const url = urls.updateCurrent;
      const success: boolean = await postApiRequest(
        url,
        sessionToken,
        id,
        userMode
      );
      return success;
    },

    delete: async (id: number) => {
      const url = urls.delete;
      const success: boolean = await postApiRequest(
        url,
        sessionToken,
        id,
        userMode
      );
      return success;
    },
  };
};
