import {
  TableRow,
  TableCell,
  Box,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { AnimatedText } from "../../../../Generic/AnimatedText";

import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { KeyValuePair } from "../../../../../Types/myPrimitives";
import { TableCaseSetup } from "./ProjectionsWithCaseInputs";

function getFfAgeDifference(rowStateValue: TableCaseSetup): string {
  if (rowStateValue.ffAgeDifferenceFromBase === null) return "";
  return "(" + rowStateValue.ffAgeDifferenceFromBase.toString() + ")";
}

export type ConditionalTableData = {
  unconditionalData: KeyValuePair<string, TableCaseSetup>[];
  conditionalData: KeyValuePair<string, TableCaseSetup>[];
};

type Props = ConditionalTableData & {
  conditionalRowId: string;
};

export const TableWithConditionalRow: React.FC<Props> = ({
  unconditionalData,
  conditionalData,
  conditionalRowId,
}) => {
  const [animate, setAnimate] = useState({
    earnings: false,
    expenditure: false,
    return: false,
  });
  const prevIdRef = useRef(conditionalRowId);

  useEffect(() => {
    // If the ID has changed and the previous ID is not undefined
    if (conditionalRowId !== prevIdRef.current) {
      // Trigger animation logic
      let toAnimate = { earnings: false, expenditure: false, return: false };

      const prevConditionalRowSetup = conditionalData.find(
        (element) => element.key == prevIdRef.current
      )!.value;

      const conditionalRowSetup: TableCaseSetup = conditionalData.find(
        (element) => element.key == conditionalRowId
      )!.value;

      if (prevConditionalRowSetup.earnings !== conditionalRowSetup.earnings) {
        toAnimate.earnings = true;
      }
      if (
        prevConditionalRowSetup.annualExpenditure !==
        conditionalRowSetup.annualExpenditure
      ) {
        toAnimate.expenditure = true;
      }
      if (
        prevConditionalRowSetup.annualRealDiscreteReturn !==
        conditionalRowSetup.annualRealDiscreteReturn
      ) {
        toAnimate.return = true;
      }

      setAnimate(toAnimate);

      // Reset the animate flag after the animation duration
      const timeoutId = setTimeout(
        () =>
          setAnimate({ earnings: false, expenditure: false, return: false }),
        400
      );

      // Then update the previous ID with the new one
      prevIdRef.current = conditionalRowId;

      // Clean up the timeout if the component unmounts or id updates again before the timeout completes
      return () => clearTimeout(timeoutId);
    }
  }, [conditionalRowId]);

  const getConditionalTableRowData = () => {
    const conditionalRowSetup:
      | KeyValuePair<string, TableCaseSetup>
      | undefined = conditionalData.find(
      (element) => element.key == conditionalRowId
    );

    if (conditionalRowSetup === undefined)
      throw new Error("Cannot find corresponding elements.");

    return { conditionalRowSetup };
  };

  const getConditionalTableRow = () => {
    const rowSetup = getConditionalTableRowData();
    const rowSetupData = rowSetup.conditionalRowSetup.value;
    return (
      <TableRow
        key={rowSetup.conditionalRowSetup.key}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
        }}
      >
        <TableCell component="th" scope="row">
          <Typography color={rowSetupData.color} sx={{ fontWeight: "bold" }}>
            {rowSetupData.name}
          </Typography>
        </TableCell>
        <TableCell align="right">{rowSetupData.earnings}</TableCell>
        <TableCell align="right">
          <AnimatedText
            animate={animate.expenditure}
            text={rowSetupData.annualExpenditure.toString()}
            justifyContent="flex-end"
          />
        </TableCell>
        <TableCell align="right">
          <AnimatedText
            animate={animate.return}
            text={rowSetupData.annualRealDiscreteReturn}
            justifyContent="flex-end"
          />
        </TableCell>
        <TableCell align="right">
          <AnimatedText
            text={rowSetupData.ffAge?.toString()}
            justifyContent="flex-end"
          />
        </TableCell>
        <TableCell align="left">
          <AnimatedText
            text={getFfAgeDifference(rowSetupData)}
            justifyContent="flex-start"
          />
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Grid container xs={12}>
      <Box
        sx={{
          // border: "1px solid grey",
          height: "100%",
          overflow: "auto",
        }}
      >
        <TableContainer component={Paper} elevation={0}>
          <Table sx={{ minWidth: 650 }} size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>Scenario</TableCell>
                <TableCell align="right" sx={{ fontWeight: "bold" }}>
                  Earnings
                </TableCell>
                <TableCell align="right" sx={{ fontWeight: "bold" }}>
                  Expenditure
                </TableCell>
                <TableCell align="right" sx={{ fontWeight: "bold" }}>
                  Investment return (% pa)
                </TableCell>
                <TableCell align="right" sx={{ fontWeight: "bold" }}>
                  Financial freedom age
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Difference</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {unconditionalData.map((rowState) => {
                const rowStateValue = rowState.value;
                return (
                  <TableRow
                    key={rowState.key}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      <Typography
                        color={rowStateValue.color}
                        sx={{ fontWeight: "bold" }}
                      >
                        {rowStateValue.name}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      {rowStateValue.earnings}
                    </TableCell>
                    <TableCell align="right">
                      {rowStateValue.annualExpenditure}
                    </TableCell>
                    <TableCell align="right">
                      {rowStateValue.annualRealDiscreteReturn}
                    </TableCell>
                    <TableCell align="right">{rowStateValue.ffAge}</TableCell>
                    <TableCell>
                      {rowStateValue.ffAgeDifferenceFromBase &&
                        "(" + rowStateValue.ffAgeDifferenceFromBase + ")"}
                    </TableCell>
                  </TableRow>
                );
              })}
              {getConditionalTableRow()}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Grid>
  );
};
