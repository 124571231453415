import { Grid, Typography } from "@mui/material";
import React from "react";
import { ErrorMessage, Field } from "formik";
import { FormTextField } from "./FormTextField";

type Props = {
  fieldName1: string;
  label1: string;
  fieldName2: string;
  label2: string;
};

export const TwoTextFields: React.FC<Props> = ({
  fieldName1,
  label1,
  fieldName2,
  label2,
}) => {
  return (
    <Grid container direction="row" alignItems="center">
      <Grid item xs={6}>
        <Field
          name={fieldName1}
          label={label1}
          size="small" //consider if xs device
          component={FormTextField}
          margin="dense"
          fullWidth
          // sx={{ maxWidth: 100 }}
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          name={fieldName2}
          label={label2}
          size="small" //consider if xs device
          component={FormTextField}
          margin="dense"
          fullWidth
          // sx={{ maxWidth: 100 }}
        />
      </Grid>
    </Grid>
  );
};
