import React from "react";
import { useApis } from "../../../Common/Context/ApiContext";
import {
  ExpenditureDefinitionForm,
  ExpenditureDefn,
} from "../Expenditure/ExpenditureDefinitionForm";

type Props = {
  initialValues: ExpenditureDefn;
  triggerRerender: () => void;
  id: number;
  setExpanded: React.Dispatch<React.SetStateAction<string | false>>;
};

export const UpdateExpenditureDefinitionForm: React.FC<Props> = ({
  initialValues,
  triggerRerender,
  id,
  setExpanded,
}) => {
  const updateExpenditureDefinitionRequest =
    useApis().expenditureDefinitionApis.update;
  const updateCurrentExpenditureDefinitionRequest =
    useApis().expenditureDefinitionApis.updateCurrent;
  const deleteExpenditureDefinitionRequest =
    useApis().expenditureDefinitionApis.delete;

  const handleUpdateExpenditureDefinitionFormSubmit = async (
    expenditureDefinition: ExpenditureDefn
  ) => {
    await updateExpenditureDefinitionRequest({
      ...expenditureDefinition,
      id: id,
    });
    triggerRerender();
    setExpanded(false);
  };

  const handleUpdateCurrentExpenditureDefinitionClick = async () => {
    await updateCurrentExpenditureDefinitionRequest(id);
    triggerRerender();
  };

  const handleDeleteClick = async () => {
    await deleteExpenditureDefinitionRequest(id);
    triggerRerender();
    setExpanded(false);
  };

  return (
    <ExpenditureDefinitionForm
      initialValues={initialValues}
      handleFormSubmit={handleUpdateExpenditureDefinitionFormSubmit}
      extraButtons={{
        handleDeleteClick: handleDeleteClick,
        handleMakeDefaultClick: handleUpdateCurrentExpenditureDefinitionClick,
      }}
    />
  );
};
