import React, { useState } from "react";

import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import {
  Box,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from "@mui/material";
import { MyTitle } from "../../Generic/Title";
import { ProjectionModellingInputs } from "./ProjectionModellingInputs";
import { RechartsChartMultipleGeneric } from "../Charts/RechartsChartMultipleGeneric";
import { opulColors } from "../../../Common/Constants/Constants";
import {
  FinancialFreedomAgeResults,
  ProjectionResults,
  Projections,
} from "../../../Types/results";
import { PersistentUserGeneralInputs } from "../../../Types/inputs";
import { tooltipNumberFormatter } from "../../../Common/formattingFunctions";

export const colorOfHighlightedLine = opulColors.opulDarkBlue;

type Props = {
  projections: Projections;
  generalInputs: PersistentUserGeneralInputs;
};

export const ProjectionModelling: React.FC<Props> = ({
  projections,
  generalInputs,
}) => {
  const [projectionsState, setProjectionsState] = useState(
    projections.baseCaseProjection.netWorthByMonthEnd
  );
  const [ageTableState, setAgeTableState] =
    useState<FinancialFreedomAgeResults>({
      baseCaseAge: null,
      scenarioAgeComparisons: [],
    });

  const setProjectionPageState = (projectionResults: ProjectionResults) => {
    setAgeTableState(projectionResults.ages);
    setProjectionsState(projectionResults.resultsById);
  };

  const theme = useTheme();
  return (
    <Container
      maxWidth="xl"
      disableGutters={true}
      sx={{
        // py: 1,
        paddingLeft: 1, // for xs screens
        paddingRight: 1, // for xs screens
        [`@media (min-width:${theme.breakpoints.values.sm}px)`]: {
          paddingLeft: 2, // for sm screens and up
          paddingRight: 2, // for sm screens and up
        },
        [`@media (min-width:${theme.breakpoints.values.md}px)`]: {
          paddingLeft: 3, // for md screens and up
          paddingRight: 3, // for md screens and up
        },
      }}
    >
      <Grid container spacing={1}>
        <Grid xs={8.5}>
          <Paper
            elevation={0}
            sx={{
              // border: "1px solid grey",
              // bgcolor: "#f5f5f5",
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
              minHeight: "300px",
              height: "calc(100vh - 330px)",
            }}
          >
            <Box
              sx={{
                pt: 1.5,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <MyTitle>Net worth projection</MyTitle>
            </Box>
            {projectionsState != null && (
              <RechartsChartMultipleGeneric
                inputs={{
                  ...projectionsState,
                  xAxisTickFormatter: tooltipNumberFormatter,
                }}
              />
            )}
          </Paper>
        </Grid>
        <Grid xs={3.5}>
          <Paper
            elevation={0}
            sx={{
              // border: "1px solid grey",
              // bgcolor: "#ebf0f0",
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
              minHeight: "300px",
              height: "calc(100vh - 330px)",
            }}
          >
            {/* <Box
              sx={{
                pt: 1.5,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <MyTitle>Financial freedom ages</MyTitle>
            </Box> */}

            <TableContainer component={Paper} elevation={0}>
              <Table
                sx={
                  {
                    // minWidth: 250
                  }
                }
                size="small"
              >
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }}>Scenario</TableCell>

                    <TableCell align="right" sx={{ fontWeight: "bold" }}>
                      Financial freedom age
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      Difference
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    key={1}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Current
                    </TableCell>

                    <TableCell align="right">
                      {ageTableState.baseCaseAge}
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                  {ageTableState.scenarioAgeComparisons.map((x, index) => (
                    <TableRow
                      key={index + 1}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {x.id}
                      </TableCell>

                      <TableCell align="right">
                        {x.ageAndDifference?.age}
                      </TableCell>
                      <TableCell>
                        {"(" + x.ageAndDifference?.difference + ")"}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
        {/* <Grid xs={3}>
          <Typography
            sx={{
              border: "1px solid grey",
              // height: 150,
            }}
          >
            Show life expectancies <br /> Show history
            <br /> Show projection
          </Typography>
        </Grid> */}
        <Grid xs={12} sx={{ height: 250 }}>
          <ProjectionModellingInputs
            generalInputs={generalInputs}
            projectionsState={projectionsState}
            setProjectionPageState={setProjectionPageState}
            setProjectionsState={setProjectionsState}
          />
        </Grid>
      </Grid>
    </Container>
  );
};
