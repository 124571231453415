import { Container } from "@mui/material";
import React from "react";

type Props = {};

export const Test: React.FC<Props> = () => {
  const date = new Date();

  return (
    <Container sx={{ pt: 1 }}>
      <div>{date.toString()}</div>
      <br />
    </Container>
  );
};
