import React, { useContext, useState } from "react";
import { UrlContext } from "../../../Common/Context/UrlContext";
import { HttpGetComponentWrapperWithUseEffect } from "../../Generic/ComponentWrappers/HttpGetComponentWrapperWithUseEffect";
import { CareerDefinitions } from "./CareerDefinitions";
import { ReadAllCareerDefinitionsResponseDto } from "../../../Types/apiResponses";

type Props = {};

export const CareerDefinitionsFetchWrapper: React.FC<Props> = () => {
  const urls = useContext(UrlContext);
  const [rerenderKey, setRerenderKey] = useState(0);

  const triggerRerender = () => {
    setRerenderKey((prevKey) => prevKey + 1);
  };

  return (
    <HttpGetComponentWrapperWithUseEffect
      url={urls.careerDefinitionUrls.readAll}
      renderItem={(result: ReadAllCareerDefinitionsResponseDto) => (
        <CareerDefinitions
          careerDefinitions={result}
          triggerRerender={triggerRerender}
        />
      )}
      rerenderTrigger={rerenderKey}
    />
  );
};
