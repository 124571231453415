import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { blogs } from "./Blogs";
import { MediumWidthContainer } from "../Generic/MediumWidthContainer";

type Props = {};

export const BlogContainer: React.FC<Props> = () => {
  return (
    <MediumWidthContainer pxForMediumScreen={10}>
      <h1>Opul blog</h1>
      <Typography>A blog about financial freedom.</Typography>
      {blogs.map((blog) => (
        <h2 key={blog.id}>
          <Link to={`/blog/${blog.id}`}>{blog.title}</Link>
        </h2>
      ))}
    </MediumWidthContainer>
  );
};
