import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import {
  Container,
  Paper,
  Box,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
} from "@mui/material";
import theme from "../../Theme";
import { RechartsChartMultipleGeneric } from "../Toolkit/Charts/RechartsChartMultipleGeneric";
import {
  RechartsGenericMultipleInputs,
  RechartsSeriesMetaData,
} from "../../Types/recharts";
import { MyTitle } from "../Generic/Title";
import { KeyValuePair } from "../../Types/myPrimitives";
import { tooltipNumberFormatter } from "../../Common/formattingFunctions";

type Props = {
  pitchDataInput: PitchData;
};

export type PitchData = {
  allDataButOnlyBaseCaseShowing: RechartsGenericMultipleInputs;
  allSeriesMetaData: RechartsSeriesMetaData[];
  caseSetups: KeyValuePair<string, PitchCaseSetup>[];
};

export type PitchCaseSetup = {
  name: string;
  color: string;
  earnings: number;
  annualExpenditure: number;
  netWorth: number;
  legacy: number;
  annualRealDiscreteReturn: string;
  ffAge: number | null;
  ffAgeDifferenceFromBase: number | null;
};

type TableCaseSetup = {
  name: string;
  key: string;
  visibilityControlledValues: null | {
    earnings: number;
    annualExpenditure: number;
    annualRealDiscreteReturn: string;
    ffAge: number | null;
    ffAgeDifferenceFromBase: number | null;
  };
};

export const Pitch: React.FC<Props> = ({ pitchDataInput }) => {
  function ConvertToTableCaseSetupsInitial(
    pitchSetup: KeyValuePair<string, PitchCaseSetup>[]
  ) {
    const output = pitchSetup.map((setup) => {
      return ConvertToTableCaseSetupInitial(setup);
    });
    return output;
  }

  function ConvertToTableCaseSetupInitial(
    pitchSetup: KeyValuePair<string, PitchCaseSetup>
  ) {
    const tableSetup: TableCaseSetup = {
      key: pitchSetup.key,
      name: pitchSetup.value.name,
      visibilityControlledValues: null,
    };
    return tableSetup;
  }

  const [projectionsState, setProjectionsState] =
    useState<RechartsGenericMultipleInputs>(
      pitchDataInput.allDataButOnlyBaseCaseShowing
    );
  const [tableState, setTableState] = useState<TableCaseSetup[]>(
    ConvertToTableCaseSetupsInitial(pitchDataInput.caseSetups)
  );

  function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const id = event.target.name;
    const checked = event.target.checked;
    onCheckBoxChange(id, checked);
  }

  const onCheckBoxChange = (id: string, checked: boolean) => {
    const newSeriesMetaData = calculateNewSeriesMetaData(id, checked);
    setProjectionsStateWithNewSeriesMetaData(newSeriesMetaData);

    const newTableState = calculateNewTableState(id, checked);
    setTableState(newTableState);
  };

  function calculateNewSeriesMetaData(id: string, checked: boolean) {
    let newSeriesMetaData = [...projectionsState.seriesMetaData];
    if (checked) {
      newSeriesMetaData.push({
        key: id,
        name: id,
        resultsColor: pitchDataInput.caseSetups.find((x) => x.key === id)!.value
          .color,
      });
    } else {
      newSeriesMetaData = newSeriesMetaData.filter((item) => item.key !== id);
    }
    return newSeriesMetaData;
  }

  function calculateNewTableState(id: string, checked: boolean) {
    let newRowState = tableState.find((x) => x.key === id)!;
    if (checked) {
      newRowState.visibilityControlledValues =
        GetVisibilityControlledValues(id);
    } else {
      newRowState.visibilityControlledValues = null;
    }
    const newTableState = tableState.map((item) =>
      item.key === id ? newRowState : item
    );
    return newTableState;
  }

  function GetVisibilityControlledValues(id: string) {
    const inputRowState = pitchDataInput.caseSetups.find(
      (x) => x.key === id
    )!.value;
    return {
      earnings: inputRowState.earnings,
      annualExpenditure: inputRowState.annualExpenditure,
      annualRealDiscreteReturn: inputRowState.annualRealDiscreteReturn,
      ffAge: inputRowState.ffAge,
      ffAgeDifferenceFromBase: inputRowState.ffAgeDifferenceFromBase,
    };
  }

  function setProjectionsStateWithNewSeriesMetaData(
    newSeriesMetaData: RechartsSeriesMetaData[]
  ) {
    setProjectionsState((projections) => ({
      ...projections,
      seriesMetaData: newSeriesMetaData,
    }));
  }

  return (
    <Container
      maxWidth="xl"
      disableGutters={true}
      sx={{
        // pt: 1.5,
        paddingLeft: 1, // for xs screens
        paddingRight: 1, // for xs screens
        [`@media (min-width:${theme.breakpoints.values.sm}px)`]: {
          paddingLeft: 2, // for sm screens and up
          paddingRight: 2, // for sm screens and up
        },
        [`@media (min-width:${theme.breakpoints.values.md}px)`]: {
          paddingLeft: 3, // for md screens and up
          paddingRight: 3, // for md screens and up
        },
      }}
    >
      <Grid container spacing={2}>
        <Grid xs={12}>
          <Paper
            elevation={0}
            sx={{
              // border: "1px solid grey",
              // bgcolor: "#ebf0f0",
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
              minHeight: "300px",
              height: "calc(100vh - 280px)",
            }}
          >
            <Box
              sx={{
                pt: 1.5,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <MyTitle>Net worth projection</MyTitle>
            </Box>
            <RechartsChartMultipleGeneric
              inputs={{
                ...projectionsState,
                xAxisTickFormatter: tooltipNumberFormatter,
              }}
            />
          </Paper>
        </Grid>
        <Grid xs={12} sx={{ height: 190 }}>
          <Box
            sx={{
              // border: "1px solid grey",
              height: "100%",
              overflow: "auto",
            }}
          >
            <TableContainer component={Paper} elevation={0}>
              <Table sx={{ minWidth: 650 }} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell sx={{ fontWeight: "bold" }}>Scenario</TableCell>
                    <TableCell align="right" sx={{ fontWeight: "bold" }}>
                      Earnings
                    </TableCell>
                    <TableCell align="right" sx={{ fontWeight: "bold" }}>
                      Expenditure
                    </TableCell>
                    <TableCell align="right" sx={{ fontWeight: "bold" }}>
                      Investment return (% pa)
                    </TableCell>
                    <TableCell align="right" sx={{ fontWeight: "bold" }}>
                      Financial freedom age
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      Difference
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableState.map((rowState) => (
                    <TableRow
                      key={rowState.name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox onChange={handleChange} name={rowState.key} />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {rowState.name}
                      </TableCell>
                      <TableCell align="right">
                        {rowState.visibilityControlledValues?.earnings}
                      </TableCell>
                      <TableCell align="right">
                        {rowState.visibilityControlledValues?.annualExpenditure}
                      </TableCell>
                      <TableCell align="right">
                        {
                          rowState.visibilityControlledValues
                            ?.annualRealDiscreteReturn
                        }
                      </TableCell>
                      <TableCell align="right">
                        {rowState.visibilityControlledValues?.ffAge}
                      </TableCell>
                      <TableCell>
                        {rowState.visibilityControlledValues
                          ?.ffAgeDifferenceFromBase &&
                          "(" +
                            rowState.visibilityControlledValues
                              ?.ffAgeDifferenceFromBase +
                            ")"}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};
