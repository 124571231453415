import { useEffect, useState } from "react";

type Props<T> = {
  dataRetrieval: () => Promise<T>;
  renderItem: (item: T) => JSX.Element;
  rerenderTrigger?: number;
};

export const ComponentWrapperWithUseEffect = <T,>(
  props: Props<T>
): JSX.Element => {
  const [data, setData] = useState<T | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await props.dataRetrieval();
      setData(response);
    };
    fetchData();
  }, [props.rerenderTrigger]);

  return <>{data && props.renderItem(data)}</>;
};
