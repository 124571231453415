import React, { useContext } from "react";
import { ChartSliderTable, ChartSliderTableData } from "./ChartSliderTable";
import { ApiContext } from "../../../../../Common/Context/ApiContext";
import { ComponentWrapperWithUseEffect } from "../../../../Generic/ComponentWrappers/ComponentWrapperWithUseEffect";
import { RechartsGenericMultipleInputs } from "../../../../../Types/recharts";
import { ProjectionScenariosRequest } from "../../../../../Types/requests";

type Props = {
  projectionScenariosRequest: ProjectionScenariosRequest;
  initialSliderId: string;
  updateResponse?: (
    chartInputs: RechartsGenericMultipleInputs
  ) => RechartsGenericMultipleInputs;
  valueLabelFormat: (value: number, index: number) => string;
};

export const ProjectionsFromCaseInputsSlider: React.FC<Props> = ({
  initialSliderId,
  projectionScenariosRequest,
  updateResponse,
  valueLabelFormat,
}) => {
  const apis = useContext(ApiContext);
  const api = apis.sandboxProjectionApis.sliderScenarios;
  const apiClosure = () => api(projectionScenariosRequest);

  return (
    <ComponentWrapperWithUseEffect
      dataRetrieval={apiClosure}
      renderItem={(result: ChartSliderTableData) => {
        if (updateResponse) {
          result.conditionalProjectionChartSetup.initialChartSetup =
            updateResponse(
              result.conditionalProjectionChartSetup.initialChartSetup
            );
        }
        return (
          <ChartSliderTable
            valueLabelFormat={valueLabelFormat}
            {...result}
            initialSliderId={initialSliderId}
          />
        );
      }}
    />
  );
};
