import React, { Dispatch, SetStateAction } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { PortfolioInputs } from "./Portfolio/PortfolioInputs";
import { PortfolionCalibrationRequest } from "../../Types/requests";
import { a11yProps, TabPanel } from "../Generic/TabPanel";
import { GeneralInputsWrapper } from "./GeneralInputs/GeneralInputsWrapper";
import { BaseCaseModelResults } from "../../Types/results";
import { AllUserInputs } from "../../Types/inputs";
import { IAccountDefinitionApis } from "../../Common/Context/ApiContext";
import { CareerDefinitionsFetchWrapper } from "./Career/CareerDefinitionsFetchWrapper";
import { ExpenditureDefinitionsFetchWrapper } from "./Expenditure/ExpenditureDefinitionsFetchWrapper";

type Props = {
  allUserInputs: AllUserInputs | null;
  accountDefinitionApis: IAccountDefinitionApis;
  portfolioCalibrationRequest: (dto: PortfolionCalibrationRequest) => void;
  setResults: Dispatch<SetStateAction<BaseCaseModelResults | null>>;
  setAllUserInputs: Dispatch<SetStateAction<AllUserInputs | null>>;
};

export const Inputs: React.FC<Props> = ({
  allUserInputs,
  accountDefinitionApis,
  portfolioCalibrationRequest,
  setResults,
  setAllUserInputs,
}) => {
  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
      }}
    >
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <Tabs
          centered
          value={tabValue}
          onChange={handleTabChange}
          aria-label="basic tabs example"
        >
          <Tab label="General" {...a11yProps(0)} />
          <Tab label="Portfolio" {...a11yProps(1)} />
          <Tab label="Career" {...a11yProps(2)} />
          <Tab label="Expenditure" {...a11yProps(3)} />
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={0}>
        <GeneralInputsWrapper
          setAllUserInputs={setAllUserInputs}
          setResults={setResults}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <PortfolioInputs
          assetInputs={allUserInputs?.assetInputs}
          accountDefinitionApis={accountDefinitionApis}
          portfolioCalibrationRequest={portfolioCalibrationRequest}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <CareerDefinitionsFetchWrapper />
      </TabPanel>
      <TabPanel value={tabValue} index={3}>
        <ExpenditureDefinitionsFetchWrapper />
      </TabPanel>
    </Box>
  );
};
