const azureBaseUrl = "https://opul8.azurewebsites.net/api/";
const localBaseUrl = "http://localhost:7148/api/";

export enum baseApiLocation {
  local,
  azure,
}

export type CareerDefinitionUrls = {
  create: string;
  readAll: string;
  update: string;
  updateCurrent: string;
  delete: string;
};

export type ExpenditureDefinitionUrls = {
  create: string;
  readAll: string;
  update: string;
  updateCurrent: string;
  delete: string;
};

export type AccountDefinitionUrls = {
  create: string;
  update: string;
  delete: string;
};

export type ProjectionUrls = {
  career: string;
};

export type SandboxProjectionUrls = {
  blank: string;
  scenarios: string;
  sliderScenarios: string;
};

export type Urls = {
  careerDefinitionUrls: CareerDefinitionUrls;
  expenditureDefinitionUrls: ExpenditureDefinitionUrls;
  accountDefinitionUrls: AccountDefinitionUrls;
  projectionUrls: ProjectionUrls;
  sandboxProjectionUrls: SandboxProjectionUrls;
  deletePortfolioCalibration: string;
  demoUserState: string;
  expenditureModelling: string;
  investmentReturnShockAnalysis: string;
  legacyScenarioAnalysis: string;
  login: string;
  oneOffScenarioAnalysis: string;
  pitchData: string;
  processPortfolioCalibration: string;
  signUpLogin: string;
  spinUpDatabaseFunction: string;
  updateInputsAndCalculate: string;
  localHostLoginUrl: string;
};

export function getURLs(localHostMode: boolean) {
  let apiLocation: baseApiLocation = localHostMode
    ? baseApiLocation.local
    : baseApiLocation.azure;

  let baseUrl: string;
  switch (apiLocation) {
    case baseApiLocation.azure:
      baseUrl = azureBaseUrl;
      break;
    case baseApiLocation.local:
      baseUrl = localBaseUrl;
      break;
    default:
      throw new Error("unknown api location.");
  }

  const careerDefinitionFullUrls: CareerDefinitionUrls = {
    readAll: baseUrl + careerDefinitionUrls.readAll,
    create: baseUrl + careerDefinitionUrls.create,
    update: baseUrl + careerDefinitionUrls.update,
    updateCurrent: baseUrl + careerDefinitionUrls.updateCurrent,
    delete: baseUrl + careerDefinitionUrls.delete,
  };

  const expenditureDefinitionFullUrls: ExpenditureDefinitionUrls = {
    readAll: baseUrl + expenditureDefinitionUrls.readAll,
    create: baseUrl + expenditureDefinitionUrls.create,
    update: baseUrl + expenditureDefinitionUrls.update,
    updateCurrent: baseUrl + expenditureDefinitionUrls.updateCurrent,
    delete: baseUrl + expenditureDefinitionUrls.delete,
  };

  const accountDefinitionFullUrls: AccountDefinitionUrls = {
    create: baseUrl + accountDefinitionUrls.create,
    update: baseUrl + accountDefinitionUrls.update,
    delete: baseUrl + accountDefinitionUrls.delete,
  };
  const projectionFullUrls: ProjectionUrls = {
    career: baseUrl + careerModellingUrl,
  };
  const sandboxProjectionFullUrls: SandboxProjectionUrls = {
    blank: baseUrl + getBlankProjectionChartDataUrl,
    scenarios: baseUrl + calculateProjectionScenariosUrl,
    sliderScenarios: baseUrl + calculateProjectionSliderScenariosUrl,
  };

  let demoUserState = baseUrl + demoUserStateFunctionUrl;

  //todo test if this is needed
  if (apiLocation === baseApiLocation.azure) {
    demoUserState += demoUserStateCode;
  }

  const urls: Urls = {
    careerDefinitionUrls: careerDefinitionFullUrls,
    expenditureDefinitionUrls: expenditureDefinitionFullUrls,
    accountDefinitionUrls: accountDefinitionFullUrls,
    projectionUrls: projectionFullUrls,
    sandboxProjectionUrls: sandboxProjectionFullUrls,
    deletePortfolioCalibration: baseUrl + deletePortfolioCalibrationUrl,
    demoUserState: demoUserState,
    expenditureModelling: baseUrl + expenditureModellingUrl,
    investmentReturnShockAnalysis: baseUrl + investmentReturnShockAnalysisUrl,
    legacyScenarioAnalysis: baseUrl + legacyScenarioAnalysisUrl,
    login: baseUrl + loginUrl,
    oneOffScenarioAnalysis: baseUrl + oneOffScenarioAnalysisUrl,
    pitchData: baseUrl + pitchDataUrl,
    processPortfolioCalibration: baseUrl + processPortfolioCalibrationUrl,
    signUpLogin: baseUrl + signUpLoginUrl,
    spinUpDatabaseFunction: baseUrl + spinUpDatabaseFunctionUrl,
    updateInputsAndCalculate: baseUrl + updateInputsAndCalculateUrl,
    localHostLoginUrl: localHostLoginUrl,
  };
  return urls;
}

const careerDefinitionUrls = {
  create:
    "CreateCareerDefinition?code=opzLslUHDvFRf0tbgCG6Q_6eOTOWT_SEy47-Q4i_0uz0AzFusYVOqg==",
  readAll:
    "ReadAllCareerDefinitions?code=QfqRC6PYmnhS-XgckThpjAqEWhH06J1oKJvwT3QaUdYcAzFuh8e7IA==",
  update:
    "UpdateCareerDefinition?code=3jxL6DDpFHJomuRIoHVgzXHcEXIGYXkC0VeFisnllJ78AzFu8vaDbg==",
  updateCurrent:
    "UpdateCurrentCareerDefinition?code=48SRM3xKk0K4S1Y9alEC6pyweP8OYb_QHqmwbfJxryaOAzFu8U9Adg==",
  delete:
    "DeleteCareerDefinition?code=wgRztMtgXCwqZiASrcFmUOkTF_xYA3yp2E85dKRBpys7AzFuMHyMcQ==",
};

const expenditureDefinitionUrls = {
  create:
    "CreateExpenditureDefinition?code=5Ku72vNsYXqEog3N46yo7E8y5HpDosOVNtDKreT7HzTWAzFurtg9kg==",
  readAll:
    "ReadAllExpenditureDefinitions?code=vxBzE3RQgXyaUqpO-Xgs-8ubDFMs7_0ftfHWdbaM-QjCAzFuC_PuGA==",
  update:
    "UpdateExpenditureDefinition?code=sb_jwYUXhtILDlBb8gDpl7n-MY08rS96kHp3KJi6zL1tAzFuJGwWSQ==",
  updateCurrent:
    "UpdateCurrentExpenditureDefinition?code=rPXfDj8uwEKwfBVrTArgEWphBcGfZC7zgPlibC2k2bg5AzFuN93pZw==",
  delete:
    "DeleteExpenditureDefinition?code=sbtA9LoVEFuQTPyh5iFVq_aF2TsYIrIYIAzwjIjPZVZtAzFuPjlK0g==",
};

const accountDefinitionUrls = {
  create:
    "NewAccountRequest?code=UY3krwwxI2-VBNXqaIdEzr8vMt_6vKZCwlzGnHYg482WAzFuUrkqYA==",
  update:
    "UpdateAccountDefinition?code=tgZGG5SuHn5_C_Va3fUSHMcjK6BLKniGiQioXZzVxzcYAzFuerY2Bg==",
  delete:
    "DeleteAccountRequest?code=KefM27fKeU8Ft4xzPRwuLRf3Qui8x-gQ0ibADN3Rqhs3AzFuJYfMuA==",
};

const calculateProjectionScenariosUrl =
  "CalculateProjectionScenarios?code=-cLG9hyvpUD05So-T4xl84H_hNIGqm02hXq7k-IwBY26AzFu6CFSTA==";
const calculateProjectionSliderScenariosUrl =
  "CalculateProjectionSliderScenarios?code=YgQlvQGz4ruJXSPessNkceLc1-jbmFt0mOEWYBoJouyAAzFuFLLrRw==";
const careerModellingUrl =
  "CareerModelling?code=ti_OVlVhUtj5BtfMrrxsj97YqKnEpVd9BRhAqI1YjSgAAzFuVL44mg==";
const deletePortfolioCalibrationUrl =
  "DeletePortfolioCalibrationFn?code=yEl0TIoc2hIrhdAdi8DqbqBKas1kI_8pg6u8Q7pSVHbRAzFu2VU1hg==";
const demoUserStateFunctionUrl = "DemoUserStateFunction";
const demoUserStateCode =
  "?code=USscRxcT24G-Y3hvjRCHlUfGWk_gJgzpwrVzh33eU_j0AzFuwNPMbw==";
const expenditureModellingUrl =
  "ExpenditureModelling?code=P5ilT6uRz2t2vROwON_mEBwW3YG25LUb2gfvqGGABPCyAzFuFWsoZw==";
const getBlankProjectionChartDataUrl =
  "GetBlankProjectionChartData?code=pgpvpazhTfjwcqIhvXi67wlW8t20oYeC3sQw4GUmkaTUAzFumh9ang==";
const investmentReturnShockAnalysisUrl =
  "InvestmentReturnShockAnalysis?code=gA0nnoEr4kq4zezZyjA5OIjOPEhgdZfkorA2SmxZG-GKAzFuSP54Ww==";
const legacyScenarioAnalysisUrl =
  "LegacyScenarioAnalysis?code=-pHjcWByCZ7eApvjk5_KNVAYWHQRcvQDrb23qrYbbWL7AzFuxTuFEw==";
const loginUrl =
  "Login?code=_xAk1m3EuG1rDWxcttzak96I-vqe-7b57Prmwv3u51L-AzFuNdtAnA==";
const oneOffScenarioAnalysisUrl =
  "OneOffScenarioAnalysis?code=NjOEKBGY9GrAKAxCp60ZdIFItU2nSqWhW3IWqPBsxXWtAzFua-ZITg==";
const pitchDataUrl =
  "PitchData?code=f2hqa_NOcZX0BvgVRCJ8Z8L4pL5naZW1RW-uOncG1HnDAzFu18f6eg==";
const processPortfolioCalibrationUrl =
  "ProcessPortfolioCalibrationFn?code=t5SdMOx4gAztYRP9TKIbgLOgcW3FyTmm3R8Kt_AfbOFAAzFu2xkmVw==";
const signUpLoginUrl =
  "SignUpLogin?code=kiUICh-3spv882n1DWLVrY8jrSbn2dyaUppKBNhMWsgvAzFu6b9ZVg==";
const spinUpDatabaseFunctionUrl =
  "SpinUpDatabaseFunction?code=W7PCFJ0xuWJdThll0esujK96pQ60_3CE0GmPbC-k5zh3AzFu7k09Sw==";
const updateInputsAndCalculateUrl =
  "UpdateInputsAndCalculate?code=oWuTJfLVQ2uS0e7uD9BwIVi4z2ObYjYXFuJVAx2Ya1wtAzFuaBk_HA==";

const localHostLoginUrl = localBaseUrl + "LocalHostLogin";
