import { Box } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import { Header } from "./Components/Header/Header";
import { UserMode } from "./Common/Context/UserModeContext";

type Props = {
  setSessionToken: React.Dispatch<React.SetStateAction<string>>;
  testLogin: () => void;
  login: (token: string) => void;
  userMode: UserMode;
  resetApp(): void;
  localHostMode: boolean;
};

export const Root: React.FC<Props> = ({
  setSessionToken,
  testLogin,
  login,
  userMode,
  resetApp,
  localHostMode,
}) => {
  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <Header
        setSessionToken={setSessionToken}
        testLogin={testLogin}
        login={login}
        userMode={userMode}
        resetApp={resetApp}
        localHostMode={localHostMode}
      />
      <Box
        sx={{
          overflow: "auto",
          height: "100%",
          width: "100%",
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
};
