import React from "react";
import {
  Line,
  XAxis,
  Label,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  LineChart,
  ReferenceLine,
  Legend,
} from "recharts";
import {
  RechartsGenericMultipleInputs,
  RechartsReferenceLineParameters,
} from "../../../Types/recharts";

type Props = {
  inputs: RechartsGenericMultipleInputs;
};

export const RechartsChartMultipleGeneric: React.FC<Props> = ({ inputs }) => {
  const {
    showLabelsOnReferneceLines = true,
    showTooltip = false,
    strokeWidth = 3,
  } = inputs;

  const getDomain = (
    firstDataSeriesXAxisValue: number,
    firstReferenceLineXAxisValue: number | undefined
  ) => {
    if (firstReferenceLineXAxisValue == undefined)
      return [firstDataSeriesXAxisValue];
    let min = Math.min(firstReferenceLineXAxisValue, firstDataSeriesXAxisValue);
    return [min];
  };

  const getReferenceLineText = (
    referenceLineParameteres: RechartsReferenceLineParameters
  ) => {
    if (showLabelsOnReferneceLines) {
      return referenceLineParameteres.text;
    } else return "";
  };

  return (
    <ResponsiveContainer width="100%" height="100%" debounce={300}>
      <LineChart
        data={inputs.data}
        margin={{
          top: 0,
          right: 30,
          left: 30,
          bottom: 30,
        }}
      >
        {showTooltip && (
          <Tooltip
            labelFormatter={inputs.tooltipLabelFormatter}
            formatter={inputs.tooltipFormatter}
          />
        )}

        {inputs.seriesMetaData.map((stream, index) => (
          <Line
            isAnimationActive={inputs.isAnimationActive}
            name={stream.name}
            type="monotone"
            dataKey={stream.key}
            dot={false}
            stroke={stream.resultsColor}
            strokeWidth={stream.strokeWidth || strokeWidth}
          />
        ))}
        {inputs.referenceLines &&
          inputs.referenceLines.map((referenceLine, index) => (
            <>
              <ReferenceLine
                x={referenceLine.xAxisValue}
                label={{
                  value: getReferenceLineText(referenceLine),
                  position: "insideTop",
                }}
                stroke={referenceLine.color}
              />
              {/* For legend: */}
              <Line
                name={referenceLine.text}
                dataKey="null123"
                stroke={referenceLine.color}
              />
            </>
          ))}
        {inputs.showLegend && <Legend verticalAlign="bottom" align="center" />}
        <XAxis
          dataKey="xAxisValue"
          type="number"
          domain={getDomain(
            inputs.data[0].xAxisValue,
            inputs.referenceLines?.[0].xAxisValue
          )}
          tickFormatter={inputs.xAxisTickFormatter}
          ticks={inputs.xAxisTicks}
        >
          <Label
            value={inputs.xAxisName}
            offset={-10}
            position="insideBottom"
          />
        </XAxis>
        <YAxis
          tickFormatter={inputs.yAxisTickFormatter}
          domain={inputs.yAxisDomain}
        >
          <Label
            value={inputs.yAxisName}
            dy={35}
            dx={-30}
            // offset={-25}
            position="insideTopLeft"
          />
        </YAxis>
      </LineChart>
    </ResponsiveContainer>
  );
};
