import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import React from "react";
import { GeneralInputsComponentSignUp } from "./GeneralInputsComponentSignUp";
import * as Yup from "yup";
import { Formik } from "formik";
import { signUpFormikState } from "./SignUpTypes";
import { CredentialResponse, GoogleLogin } from "@react-oauth/google";
import { CreateAbstractUTCDate } from "../../Common/functions";

type Props = {
  signUpOpen: boolean;
  handleClose: () => void;
  signUp: (authenticationToken: string, formValues: any) => void;
};

let today = new Date();
today.setHours(0, 0, 0, 0);
let minDate = new Date(1924, 0, 1);
minDate.setHours(0, 0, 0, 0);

const validationSchema = Yup.object().shape({
  dateOfBirth: Yup.date()
    .required("Required")
    .typeError("Invalid date")
    .max(today, "Can't be in the future")
    .min(minDate, "Can't be before 1924"),
  expenditure: Yup.number()
    .positive("Must be positive")
    .required("Required")
    .typeError("Must be a number")
    .nullable(true),
  netEarnings: Yup.number()
    .required("Required")
    .min(0, "Must be positive")
    .typeError("Must be a number"),
});

export const SignUp: React.FC<Props> = ({
  signUpOpen,
  handleClose,
  signUp,
}) => {
  const initialFormikState: signUpFormikState = {
    dateOfBirth: null,
    expenditure: undefined,
    netEarnings: undefined,
  };

  const [formSubmitted, setFormSubmitted] = React.useState(false);
  const [formValues, setFormValues] = React.useState(initialFormikState);

  function responseGoogle(response: CredentialResponse): void {
    const data = { id_token: response.credential };
    const dataJson = JSON.stringify(data);
    const url = "https://opul8.azurewebsites.net/.auth/login/google";
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: dataJson,
    })
      .then((response) => response.json())
      .then((data) => data.authenticationToken)
      .then((authenticationToken: string) => {
        const abstractDate = CreateAbstractUTCDate(formValues.dateOfBirth!);
        let newFormValues = {
          ...formValues,
          dateOfBirth: abstractDate.toISOString(),
        };

        signUp(authenticationToken, newFormValues);
        handleClose();
      });
  }

  return (
    <Dialog open={signUpOpen} onClose={handleClose}>
      {/* <DialogTitle>Join for free</DialogTitle> */}
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={initialFormikState}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          setFormValues(values);
          setFormSubmitted(true);
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          setFieldValue,
          touched,
          setFieldTouched,
          submitForm,
          isValid,
        }) => (
          <>
            {!formSubmitted && (
              <>
                <DialogContent>
                  <DialogContentText>
                    We need the following core information to get you up and
                    running. Further detail can be provided later on in the
                    model inputs to improve the modelling.
                  </DialogContentText>
                  <GeneralInputsComponentSignUp />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button onClick={() => submitForm()}>Next</Button>
                </DialogActions>
              </>
            )}
            {formSubmitted && (
              <>
                <DialogContent>
                  <DialogContentText>
                    The final step is to link to your google account.
                  </DialogContentText>
                  <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <GoogleLogin
                      onSuccess={responseGoogle}
                      onError={() => {}}
                    />
                  </DialogActions>
                </DialogContent>
              </>
            )}
          </>
        )}
      </Formik>
    </Dialog>
  );
};
