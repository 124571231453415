import { Typography } from "@mui/material";
import React, { ReactNode } from "react";

type Props = {
  title: string;
  content: ReactNode;
};

export const Article: React.FC<Props> = ({ title, content }) => {
  return (
    <article>
      <Typography
        variant="h4"
        sx={{
          fontFamily: "Young Serif",
          fontStyle: "italic",
        }}
        gutterBottom
      >
        {title}
      </Typography>
      {content}
    </article>
  );
};
