import { Grid, Typography } from "@mui/material";
import React from "react";
import { Field } from "formik";
import { FormTextField } from "./FormTextField";

type Props = {
  name: string;
  fieldName: string;
  label: string;
  bold?: boolean;
};

export const TypographyAndTextField: React.FC<Props> = ({
  name,
  fieldName,
  label,
  bold = false,
}) => {
  return (
    <Grid container direction="row" alignItems="center">
      <Grid item xs={6}>
        {bold ? (
          <Typography display="inline" sx={{ mr: 1, fontWeight: "bold" }}>
            {name}
          </Typography>
        ) : (
          <Typography display="inline" sx={{ mr: 1 }}>
            {name}
          </Typography>
        )}
      </Grid>
      <Grid item xs={6}>
        <Field
          name={fieldName}
          label={label}
          size="small" //consider if xs device
          component={FormTextField}
          margin="dense"
          fullWidth
          // sx={{ maxWidth: 100 }}
        />
      </Grid>
    </Grid>
  );
};
