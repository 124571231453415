import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { opulColors } from "../../../../Common/Constants/Constants";
import { MyTitle } from "../../../Generic/Title";
import { AccountDefinition } from "../../../../Types/assetTypes";
import { AccountDefinitions } from "./AccountDefinitions";
import { MediumWidthContainer } from "../../../Generic/MediumWidthContainer";
import { IAccountDefinitionApis } from "../../../../Common/Context/ApiContext";

type Props = {
  accountDefinitions?: AccountDefinition[];
  accountDefinitionApis: IAccountDefinitionApis;
};

export const ManageAccounts: React.FC<Props> = ({
  accountDefinitions,
  accountDefinitionApis,
}) => {
  return (
    <MediumWidthContainer pxForMediumScreen={0}>
      <MyTitle>Portfolio setup</MyTitle>
      <Typography>
        Your portfolio setup is a breakdown of your investments (and borrowings)
        into accounts. Once you have setup an account, you can add values and
        keep them up to date in the "Values" tab. This allows you to track your
        account and asset class breakdown over time.
        <br />
        <br />
        Examples of accounts would be: accounts with a bank (current, savings,
        credit card), general investment account, tax-sheltered investment
        account, pension, house.
        <br />
        <br />
        If you only want to track your total net-worth - you could setup one
        account called "Total net-worth" with a single investment called
        "Everything".
        <br />
        <br />
        <MyTitle>Accounts</MyTitle>
        <br />
      </Typography>
      <AccountDefinitions
        accountDefinitions={accountDefinitions}
        accountDefinitionRequest={accountDefinitionApis.update}
        deleteAccountRequest={accountDefinitionApis.delete}
      />
      <br />
      <Grid item xs={6}>
        <Box display="flex" justifyContent="left">
          <Button
            type="submit"
            variant="contained"
            size="large"
            onClick={() => accountDefinitionApis.create()}
            sx={{
              textTransform: "none",
              backgroundColor: opulColors.opulDarkGreen,
              color: "common.white",
            }}
          >
            Add account
          </Button>
        </Box>
      </Grid>
    </MediumWidthContainer>
  );
};
