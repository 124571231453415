import * as React from "react";
import Typography from "@mui/material/Typography";

interface TitleProps {
  children?: React.ReactNode;
}

export const MyTitle: React.FC<TitleProps> = (props: TitleProps) => {
  return (
    <Typography
      variant="h6"
      sx={{
        fontWeight: "bold",
        // pb: "0.5rem",
      }}
      // gutterBottom
      // color="primary"
    >
      {props.children}
    </Typography>
  );
};
