import { createContext } from "react";

export enum UserMode {
  None = "None",
  Demo = "Demo",
  Persistent = "Persistent",
  Sandbox = "Sandbox",
}

export const UserModeContext = createContext<UserMode>(UserMode.None);

export const GetInitialUserMode = (localHostMode: boolean) => {
  let fromLocalStorage = localStorage.getItem("sessionTokenKey");
  if (fromLocalStorage && !localHostMode) {
    return UserMode.Persistent;
  }
  return UserMode.None;
};
