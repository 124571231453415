import React, { useContext } from "react";
import { ProjectionModelling } from "./ProjectionModelling";
import { GeneralInputsContext } from "../../../Common/Context/GeneralInputsContext";
import { Projections } from "../../../Types/results";
type Props = {
  projections: Projections | null | undefined;
};

export const ProjectionModellingNullable: React.FC<Props> = ({
  projections,
}) => {
  const generalInputs = useContext(GeneralInputsContext);

  return (
    <>
      {projections && generalInputs ? (
        <ProjectionModelling
          projections={projections}
          generalInputs={generalInputs}
        />
      ) : (
        <div>No projection results</div>
      )}
    </>
  );
};
