import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React from "react";
import { RechartsPieDataStructure } from "../../../Types/recharts";
import { MyPieChart } from "./MyPieChart";

type Props = {
  assetClassValues: RechartsPieDataStructure;
  accountValues: RechartsPieDataStructure;
};

export const PortfolioPieChart: React.FC<Props> = ({
  assetClassValues,
  accountValues,
}) => {
  const [grouping, setGrouping] = React.useState("account");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGrouping((event.target as HTMLInputElement).value);
  };

  const getPieValues = () => {
    if (grouping == "assetClass") return assetClassValues;
    else return accountValues;
  };

  return (
    <Grid container sx={{ height: "100%" }}>
      <Grid item xs={12}>
        <MyPieChart dataToDisplay={getPieValues()} />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          //   alignItems: "center",
          //   alignContent: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <FormControl>
          <FormLabel id="portfolio-radio-buttons-group-label">
            Group by
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="portfolio-radio-buttons-group-label"
            defaultValue="account"
            name="radio-buttons-group"
            onChange={handleChange}
          >
            <FormControlLabel
              value="assetClass"
              control={<Radio size="small" />}
              label={<Typography sx={{ fontSize: 14 }}>Asset class</Typography>}
            />
            <FormControlLabel
              value="account"
              control={<Radio size="small" />}
              label={<Typography sx={{ fontSize: 14 }}>Account</Typography>}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
};
