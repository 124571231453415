import BlogData from "./BlogData";
import { WhatIsYourFFAge } from "./BlogPieces/1_WhatIsYourFFAge/WhatIsYourFFAge";
import { InteractiveIntroductionToFF } from "./BlogPieces/2_InteractiveIntroductionToFF/InteractiveIntroductionToFF";

export const blogs: BlogData[] = [
  {
    id: 1,
    title: "What is my financial freedom age?",
    content: <WhatIsYourFFAge />,
  },
  {
    id: 2,
    title: "An interactive introduction to financial freedom",
    content: <InteractiveIntroductionToFF />,
  },
];
