import { useField } from "formik";
import React from "react";
import { DatePicker } from "@mui/x-date-pickers";

type Props = {
  fieldName: string;
  label: string;
};

export const FieldWrappedDatePicker: React.FC<Props> = ({
  fieldName,
  label,
}) => {
  const [field, meta, helpers] = useField(fieldName);

  return (
    <DatePicker
      label={label}
      value={field.value as Date}
      onChange={(val) => {
        helpers.setValue(val);
      }}
      format="dd/MM/yyyy"
      slotProps={{
        textField: {
          error: !!meta.error,
          helperText: meta.error,
          onBlur: () => helpers.setTouched(true),
          fullWidth: true,
        },
      }}
    />
  );
};
