import { Slider, SliderMarkLabel, Typography } from "@mui/material";
import React from "react";
import { StringKeyNumberValue } from "../../Types/myPrimitives";

const getMap = (sliderValuesById: StringKeyNumberValue[]) => {
  const mapFromSliderValueToId = new Map<number, string>();
  sliderValuesById.forEach((element) => {
    mapFromSliderValueToId.set(element.value, element.key);
  });
  return mapFromSliderValueToId;
};

const getMarks = (
  sliderValuesById: StringKeyNumberValue[],
  valueLabelFormat: (value: number, index: number) => string
) => {
  const sliderMarks: { value: number; label: string }[] = [];
  sliderValuesById.forEach((element, index) => {
    sliderMarks.push({
      value: element.value,
      label: valueLabelFormat(element.value, index),
    });
  });
  return sliderMarks;
};

type Props = {
  initialValue: number;
  sliderValuesById: StringKeyNumberValue[];
  onSliderChange: (newId: string) => void;
  valueLabelFormat: (value: number, index: number) => string;
};

export const MySlider: React.FC<Props> = ({
  initialValue,
  sliderValuesById,
  onSliderChange,
  valueLabelFormat,
}) => {
  const sliderMap = getMap(sliderValuesById);
  const sliderMarks = getMarks(sliderValuesById, valueLabelFormat);

  const onSliderChangeLocal = (
    event: Event,
    value: number | number[],
    activeThumb: number
  ) => {
    const newSliderId = sliderMap.get(value as number);
    if (newSliderId === undefined) throw new Error("New slider ID undefined.");
    onSliderChange(newSliderId);
  };

  return (
    <Slider
      defaultValue={initialValue}
      valueLabelDisplay="auto"
      step={null}
      marks={sliderMarks}
      min={sliderMarks[0].value}
      max={sliderMarks[sliderMarks.length - 1].value}
      onChange={onSliderChangeLocal}
      valueLabelFormat={(value: number, index: number) => {
        return <Typography>{valueLabelFormat(value, index)}</Typography>;
      }}
      // sx={{ ml: 4, mr: 10 }}
    />
  );
};
