import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from "@mui/material";
import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AccountDefinition } from "../../../../Types/assetTypes";
import { ManageAccount } from "./ManageAccount";
import { UpdateAccountDefinitionRequest } from "../../../../Types/requests";

type Props = {
  accountDefinitions?: AccountDefinition[];
  accountDefinitionRequest: (dto: UpdateAccountDefinitionRequest) => void;
  deleteAccountRequest: (accountId: number) => void;
};

export const AccountDefinitions: React.FC<Props> = ({
  accountDefinitions,
  accountDefinitionRequest,
  deleteAccountRequest,
}) => {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <>
      {accountDefinitions?.map((defn, index) => {
        return (
          <Accordion
            key={`panel${index}`} // Added unique key prop her
            expanded={expanded === `panel${index + 1}`}
            onChange={handleChange(`panel${index + 1}`)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography sx={{ width: "33%", flexShrink: 0 }}>
                {defn.accountInfo.name}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ManageAccount
                accountDefinition={defn}
                accountDefinitionRequest={accountDefinitionRequest}
                deleteAccountRequest={deleteAccountRequest}
              />
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  );
};
