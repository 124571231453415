import { FinancialFreedomCaseResults } from "../../Types/results";
import { DateOnly } from "../../Types/myPrimitives";

export const initialReturnAssumption = 4;
export const initialSalaryGrowthAssumption = 3;
export const initialSalaryGrowthYearsAssumption = 15;
export const initialStatePensionAgeAssumption = 65;
export const debounceTime = 650;
export const debugMode = true;
// export const debounce = (func, wait) => {
//   let timeout;
//   return function (...args) {
//     const context = this;
//     if (timeout) clearTimeout(timeout);
//     timeout = setTimeout(() => {
//       timeout = null;
//       func.apply(context, args);
//     }, wait);
//   };
// };

//Colors
export const opulColors = {
  primaryBlue: "#2a8dad",
  secondaryBlue: "#22b7ce",
  backgroundGrey: "#f5f5f5",
  baseCaseColor: "#ff9aa2",
  opulLightGreen: "#75c2a6",
  opulDarkGreen: "#1a7451",
  opulLightBlue: "#bcc6ec",
  opulCyan: "#22b7ce",
  opulDarkBlue: "#053f75",
  opulLightGrey: "#d6d6d6",
  opulOrange: "#ff8b3d",
  opulPurple: "#966fd6",
  deleteRed: "#a80a0a",
  deleteRedHover: "#910707",
};
export const colorArrray: string[] = [
  opulColors.baseCaseColor,
  opulColors.opulLightGreen,
  opulColors.opulDarkGreen,
  opulColors.opulLightBlue,
  opulColors.opulCyan,
  opulColors.opulDarkBlue,
  opulColors.opulLightGrey,
  opulColors.opulOrange,
  opulColors.opulPurple,
];

//PageIds
export const pageIds = {
  dashboard: "dashboard",
  blog: "blog",
  home: "home",
  inputs: "inputs",
  learn: "learn",
  pitch: "pitch",
  projections: "projections",
  test: "test",
};

//ResultIds
export const scenarioIds = {
  base: "BaseCase",
  alternate: "Alternate",
  lowerMonthlySpend: "LowerMonthlySpend",
  lowerDailySpend: "LowerDailySpend",
  careerBreak: "CareerBreak",
  fourDayWeek: "FourDayWeek",
};

export function GetProjectionsSubset(
  projections: {
    [key: string]: FinancialFreedomCaseResults;
  },
  subsetKeys: string[]
) {
  const output: { [key: string]: FinancialFreedomCaseResults } = {};

  for (const [key, value] of Object.entries(projections)) {
    if (subsetKeys.includes(key)) {
      output[key] = value;
    }
  }

  return output;
}

export const GetJsDateFromDateOnly = (dateOnly: DateOnly) => {
  return new Date(dateOnly.year, dateOnly.month - 1, dateOnly.day);
};

export const GetTodayNoHours = () => {
  let now = new Date();
  now.setHours(0, 0, 0, 0);
  return now;
};

export const GetTomorrowNoHours = () => {
  let output: Date = GetTodayNoHours();
  output.setDate(output.getDate() + 1);
  return output;
};
