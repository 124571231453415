import * as React from "react";
import { Box } from "@mui/material";

interface QuotationProps {
  children: React.ReactNode;
}

export const Quotation: React.FC<QuotationProps> = ({ children }) => {
  return (
    <Box
      sx={{
        borderLeft: 4,
        paddingLeft: 2,
        pr: 2,
        color: "text.secondary",
        fontStyle: "italic",
        my: 2,
        py: 1,
        backgroundColor: "background.paper",
      }}
    >
      {children}
    </Box>
  );
};
