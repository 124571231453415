import React, { useState } from "react";
import { Button, Grid, Box, IconButton } from "@mui/material";
import { Form, Formik, FieldArray, Field } from "formik";
import * as yup from "yup";
import { opulColors } from "../../../../Common/Constants/Constants";
import { TwoTextFields } from "../../TwoTextFields";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import { UpdateAccountDefinitionRequest } from "../../../../Types/requests";
import { FormTextField } from "../../FormTextField";
import { AccountDefinition } from "../../../../Types/assetTypes";

const validationSchema = yup.object({
  accountName: yup.string().required("Required"),
  assetDefinitions: yup.array().of(
    yup.object({
      name: yup.string().required("Required"),
      account: yup.string().nullable(),
      assetClass: yup.string().nullable(),
    })
  ),
});

type Props = {
  accountDefinition: AccountDefinition;
  accountDefinitionRequest: (dto: UpdateAccountDefinitionRequest) => void;
  deleteAccountRequest: (accountId: number) => void;
};

export const ManageAccount: React.FC<Props> = ({
  accountDefinition,
  accountDefinitionRequest,
  deleteAccountRequest,
}) => {
  const initialFormikState = {
    accountName: accountDefinition.accountInfo.name,
    assetDefinitions: accountDefinition.assetDefinitions,
  };

  const [assetIdsToDelete, setAssetIdsToDelete] = useState(new Array<number>());

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={initialFormikState}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        const req = {
          ...values,
          assetIdsToDelete,
          accountId: accountDefinition.accountInfo.id,
        };
        accountDefinitionRequest(req);
        setSubmitting(false);
      }}
    >
      {({ values, errors, touched, setFieldValue }) => (
        <Form>
          <Grid container spacing={0.5} sx={{ pb: 1 }}>
            <Grid item xs={11}>
              <Field
                name="accountName"
                label="Account name"
                component={FormTextField}
                margin="dense"
              />
            </Grid>
            <Grid
              item
              xs={1}
              sx={{
                alignItems: "center",
                display: "flex",
              }}
            >
              <IconButton
                aria-label="delete"
                onClick={() => {
                  deleteAccountRequest(accountDefinition.accountInfo.id);
                }}
                sx={{ color: opulColors.baseCaseColor }}
              >
                <IndeterminateCheckBoxIcon />
              </IconButton>
            </Grid>
          </Grid>
          <FieldArray name="assetDefinitions">
            {({ remove, push }) => (
              <div>
                <Grid container spacing={1} sx={{ pb: 1 }}>
                  <Grid item xs={12}>
                    {values.assetDefinitions?.map((asset, index) => {
                      return (
                        <div key={index}>
                          <Grid container spacing={0.5} sx={{ pb: 1 }}>
                            <Grid item xs={11}>
                              <TwoTextFields
                                fieldName1={`assetDefinitions.${index}.name`}
                                label1={"Name"}
                                fieldName2={`assetDefinitions.${index}.assetClass`}
                                label2="Asset class"
                              />
                            </Grid>
                            <Grid
                              item
                              xs={1}
                              sx={{
                                alignItems: "center",
                                display: "flex",
                              }}
                            >
                              <IconButton
                                aria-label="delete"
                                onClick={() => {
                                  remove(index);
                                  if (asset.nameAndId.id) {
                                    setAssetIdsToDelete((prevState) =>
                                      prevState.concat(asset.nameAndId.id)
                                    );
                                  }
                                }}
                                sx={{ color: opulColors.baseCaseColor }}
                              >
                                <IndeterminateCheckBoxIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </div>
                      );
                    })}
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={6}>
                    <Box display="flex" justifyContent="left">
                      <Button
                        type="submit"
                        variant="contained"
                        size="large"
                        onClick={() => push("")}
                        sx={{
                          textTransform: "none",
                          backgroundColor: opulColors.opulDarkGreen,
                          color: "common.white",
                        }}
                      >
                        Add asset
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box display="flex" justifyContent="right">
                      <Button
                        type="submit"
                        variant="contained"
                        size="large"
                        sx={{
                          textTransform: "none",
                          backgroundColor: opulColors.opulDarkBlue,
                          color: "common.white",
                        }}
                      >
                        Save account setup
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </div>
            )}
          </FieldArray>
          {/* <div>Values</div>
          <pre>{JSON.stringify(values, null, 2)}</pre>
          <div>Errors</div>
          <pre>{JSON.stringify(errors, null, 2)}</pre>
          <div>Touched</div>
          <pre>{JSON.stringify(touched, null, 2)}</pre> */}
        </Form>
      )}
    </Formik>
  );
};
