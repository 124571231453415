import { useContext } from "react";
import { getApiRequest } from "../../../Common/Apis/genericApiFunctions";
import { UserModeContext } from "../../../Common/Context/UserModeContext";
import { UserTokenContext } from "../../../Common/Context/UserTokenContext";
import { ComponentWrapperWithUseEffect } from "./ComponentWrapperWithUseEffect";

type Props<T> = {
  url: string;
  renderItem: (item: T) => JSX.Element;
  rerenderTrigger?: number;
};

export const HttpGetComponentWrapperWithUseEffect = <T,>(
  props: Props<T>
): JSX.Element => {
  const token = useContext(UserTokenContext);
  const userMode = useContext(UserModeContext);

  const apiRequest = async () => {
    const apiResponseData: T = await getApiRequest(props.url, token, userMode);
    return apiResponseData;
  };

  return (
    <ComponentWrapperWithUseEffect
      dataRetrieval={apiRequest}
      renderItem={props.renderItem}
      rerenderTrigger={props.rerenderTrigger}
    />
  );
};
