import { Typography } from "@mui/material";
import React from "react";
import { opulColors } from "../../../Common/Constants/Constants";
import { SavingsRateDefinition } from "../../../Types/inputs";

type Props = {};

export const ExpenseComparator: React.FC<Props> = () => {
  return (
    <div>
      <br />

      <Typography display="inline">Your daily savings rate is </Typography>
      <Typography
        variant="h6"
        display="inline"
        color="primary"
        sx={{ fontWeight: "bold" }}
      >
        coming soon
      </Typography>
      <br />
      <br />
      <Typography display="inline">Your daily earnings rate is </Typography>
      <Typography
        variant="h6"
        display="inline"
        color={opulColors.opulDarkBlue}
        sx={{ fontWeight: "bold" }}
      >
        coming soon
      </Typography>
      <br />
      <br />
      {/* <Typography sx={{ fontStyle: "italic" }} display="inline">
        These numbers can help to make quick on-the-go decisions about the
        effect of a single expense on your savings goals. For instance, a
        purchase of {Math.floor((dailySavings / 2) * 10) / 10} (above your
        budget) will wipe out half of your daily savings.
      </Typography> */}
    </div>
  );
};
