import React, { useContext, useState } from "react";
import { UrlContext } from "../../../Common/Context/UrlContext";
import { HttpGetComponentWrapperWithUseEffect } from "../../Generic/ComponentWrappers/HttpGetComponentWrapperWithUseEffect";
import { ExpenditureDefinitions } from "../Expenditure/ExpenditureDefinitions";
import { ReadAllExpenditureDefinitionsResponseDto } from "../../../Types/apiResponses";

type Props = {};

export const ExpenditureDefinitionsFetchWrapper: React.FC<Props> = () => {
  const urls = useContext(UrlContext);
  const [rerenderKey, setRerenderKey] = useState(0);

  const triggerRerender = () => {
    setRerenderKey((prevKey) => prevKey + 1);
  };

  return (
    <HttpGetComponentWrapperWithUseEffect
      url={urls.expenditureDefinitionUrls.readAll}
      renderItem={(result: ReadAllExpenditureDefinitionsResponseDto) => (
        <ExpenditureDefinitions
          expenditureDefinitions={result}
          triggerRerender={triggerRerender}
        />
      )}
      rerenderTrigger={rerenderKey}
    />
  );
};
