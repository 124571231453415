import { CareerDefn } from "../../../Components/Inputs/Career/CareerDefinitionForm";
import { ReadAllCareerDefinitionsResponseDto } from "../../../Types/apiResponses";
import { CareerDefinitionUrls } from "../../Constants/Urls";
import { UserMode } from "../../Context/UserModeContext";
import { postApiRequest, getApiRequest } from "../genericApiFunctions";

export const getCareerDefinitionRequests = (
  urls: CareerDefinitionUrls,
  sessionToken: string,
  userMode: UserMode
) => {
  return {
    create: async (careerDefn: CareerDefn) => {
      const url = urls.create;
      const success: boolean = await postApiRequest(
        url,
        sessionToken,
        careerDefn,
        userMode
      );
      return success;
    },

    readAll: async () => {
      const url = urls.readAll;
      const data: ReadAllCareerDefinitionsResponseDto = await getApiRequest(
        url,
        sessionToken,
        userMode
      );
      return data;
    },

    update: async (careerDefnWithId: CareerDefn & { id: number }) => {
      const url = urls.update;
      const success: boolean = await postApiRequest(
        url,
        sessionToken,
        careerDefnWithId,
        userMode
      );
      return success;
    },

    updateCurrent: async (id: number) => {
      const url = urls.updateCurrent;
      const success: boolean = await postApiRequest(
        url,
        sessionToken,
        id,
        userMode
      );
      return success;
    },

    delete: async (id: number) => {
      const url = urls.delete;
      const success: boolean = await postApiRequest(
        url,
        sessionToken,
        id,
        userMode
      );
      return success;
    },
  };
};
