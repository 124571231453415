import React from "react";

import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import {
  BackendStackedAreaData,
  RechartsStackedAreaDataApiSeconds,
  StackedAreaChartData,
} from "../../../Types/recharts";
import { NumberKeyValuePair } from "../../../Types/myPrimitives";
import { colorArrray } from "../../../Common/Constants/Constants";
import {
  tooltipNumberFormatterStringToString,
  formatUnixTickAsMMMyyyy,
  tooltipNumberFormatter,
  formatUnixTickAsdoMMMyyyy,
} from "../../../Common/formattingFunctions";

type Props = {
  setup: StackedAreaChartData;
};

export const RechartsUnixSecondsStackedArea: React.FC<Props> = ({ setup }) => {
  const convertNumberKeyValuePairsToProperties = (
    backendDataPoint: NumberKeyValuePair[]
  ) => {
    let output: { [key: number]: number } = backendDataPoint.reduce(
      (a, v) => ({ ...a, [v.key]: v.value }),
      {}
    );
    return output;
  };

  const convertBackendToRechartsApi = (inputs: BackendStackedAreaData) => {
    let convertedPairs = convertNumberKeyValuePairsToProperties(inputs.values);
    let output: RechartsStackedAreaDataApiSeconds = {
      unixSeconds: inputs.unixSeconds,
      ...convertedPairs,
    };
    return output;
  };

  const convertBackendArrayToRechartsApi = (
    inputs: BackendStackedAreaData[]
  ) => {
    let output: RechartsStackedAreaDataApiSeconds[] = new Array();
    inputs.forEach((element) => {
      let converted = convertBackendToRechartsApi(element);
      output.push(converted);
    });
    return output;
  };

  const chartData = convertBackendArrayToRechartsApi(setup.data);

  const tooltipFormatter = (value: string, name: string) => {
    let formattedValue: string = tooltipNumberFormatterStringToString(value);

    let nameAsInt: number = parseInt(name);
    const account = setup.names.find((obj) => obj.id === nameAsInt);
    const formattedName = account?.name ?? "";

    let output: [string, string] = [formattedValue, formattedName];
    return output;
  };

  return (
    <ResponsiveContainer debounce={300} width="100%" height="100%">
      <AreaChart
        width={500}
        height={400}
        data={chartData}
        margin={{
          top: 0,
          right: 30,
          left: 15,
          bottom: 0,
        }}
      >
        <XAxis dataKey="unixSeconds" tickFormatter={formatUnixTickAsMMMyyyy} />
        <YAxis tickFormatter={tooltipNumberFormatter} />
        <Tooltip
          labelFormatter={formatUnixTickAsdoMMMyyyy}
          formatter={tooltipFormatter}
        />
        {setup.names.map((element, index) => {
          let colorIndex = index % colorArrray.length;
          let color = colorArrray[colorIndex];

          return (
            <Area
              type="monotone"
              dataKey={element.id}
              stackId={1}
              stroke={color}
              fill={color}
              key={index}
            />
          );
        })}
      </AreaChart>
    </ResponsiveContainer>
  );
};
