import { Box, Typography } from "@mui/material";
import React from "react";
import { opulColors } from "../../../Common/Constants/Constants";

type Props = {
  titleText: string;
};

export const BlogSubTitle: React.FC<Props> = ({ titleText }) => {
  return (
    <Box display="flex" alignItems="center" sx={{ pb: 1 }}>
      <Typography
        variant="h5"
        sx={{
          display: "inline",
          pr: 1,
          color: opulColors.opulDarkBlue,
          fontWeight: 500,
        }}
      >
        {titleText}
      </Typography>
      {/* <Box
        sx={{
          flexGrow: 1,
          borderBottom: "1px solid",
          borderColor: "divider",
          height: "50%",
        }}
      /> */}
    </Box>
  );
};
