import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  RechartsGenericMultipleInputs,
  RechartsSeriesMetaData,
} from "../../../Types/recharts";
import { ProjectionResults } from "../../../Types/results";
import { useApis } from "../../../Common/Context/ApiContext";
import { BoldColouredTypography } from "../../Generic/BoldColouredTypography";

type Props = {
  setProjectionsState: React.Dispatch<
    React.SetStateAction<RechartsGenericMultipleInputs>
  >;
  setProjectionPageState: (projectionResults: ProjectionResults) => void;
  projections: RechartsGenericMultipleInputs;
};

export const CareerInputs: React.FC<Props> = ({ setProjectionPageState }) => {
  useEffect(() => {
    careerApiRequest();
  }, []);
  const [seriesMetaData, setSeriesMetaData] = useState<
    RechartsSeriesMetaData[]
  >([]);
  const apis = useApis();

  const careerApiRequest = async () => {
    const apiResponse: RechartsGenericMultipleInputs =
      await apis.projectionApis.career();

    setSeriesMetaData(apiResponse.seriesMetaData);
    setProjectionPageState({
      resultsById: apiResponse,
      ages: { baseCaseAge: null, scenarioAgeComparisons: [] },
    });
  };

  //JSX
  return (
    <Box
    // sx={{
    //   border: "1px solid grey",
    // }}
    >
      {seriesMetaData.map((item) => (
        <>
          <BoldColouredTypography
            variant={"body1"}
            text={item.name}
            color={item.resultsColor}
          />
          <br />
        </>
      ))}
      <br />
      <Typography>
        Head to Inputs-{">"}Career to add new career paths for comparison.
      </Typography>
    </Box>
  );
};
