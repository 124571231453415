import React from "react";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from "recharts";
import { colorArrray } from "../../../Common/Constants/Constants";
import { RechartsPieDataStructure } from "../../../Types/recharts";
import { tooltipNumberFormatterStringToString } from "../../../Common/formattingFunctions";

type Props = {
  dataToDisplay: RechartsPieDataStructure;
};

export const MyPieChart: React.FC<Props> = ({ dataToDisplay }) => {
  const pieChartTooltipFormatter = (value: string, name: string) => {
    let formattedValue: string = tooltipNumberFormatterStringToString(value);

    let nameAsInt: number = parseInt(name);
    let formattedName: string = dataToDisplay[nameAsInt].key;

    let output: [string, string] = [formattedValue, formattedName];
    return output;
  };

  return (
    <ResponsiveContainer debounce={300} width="100%" height="100%">
      <PieChart>
        <Pie
          data={dataToDisplay}
          dataKey="value"
          // cx="50%"
          // cy="50%"
          outerRadius={110}
          // fill="#8884d8"
          label={({
            cx,
            cy,
            midAngle,
            innerRadius,
            outerRadius,
            value,
            index,
          }) => {
            // console.log("handling label?");
            const RADIAN = Math.PI / 180;
            // eslint-disable-next-line
            const radius = 25 + innerRadius + (outerRadius - innerRadius);
            // eslint-disable-next-line
            const x = cx + radius * Math.cos(-midAngle * RADIAN);
            // eslint-disable-next-line
            const y = cy + radius * Math.sin(-midAngle * RADIAN);

            return (
              <text
                x={x}
                y={y}
                // fill="#8884d8"
                textAnchor={x > cx ? "start" : "end"}
                dominantBaseline="central"
              >
                {dataToDisplay[index].key}
                {/* {value} */}
              </text>
            );
          }}
        >
          {dataToDisplay.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={colorArrray[index % colorArrray.length]}
            />
          ))}
        </Pie>
        <Tooltip formatter={pieChartTooltipFormatter} />
      </PieChart>
    </ResponsiveContainer>
  );
};
