import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import {
  Box,
  Button,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@mui/material/";
import { opulColors, pageIds } from "../../Common/Constants/Constants";
import { HeaderButton } from "./HeaderButton";
import { GoogleLogin } from "@react-oauth/google";
import { UserMode } from "../../Common/Context/UserModeContext";
import { ButtonSignUp } from "../Generic/ButtonSignUp";
import MenuIcon from "@mui/icons-material/Menu";

interface IHeaderBarProps {
  setSessionToken: React.Dispatch<React.SetStateAction<string>>;
  testLogin: () => void;
  login: (token: string) => void;
  userMode: UserMode;
  resetApp(): void;
  localHostMode: boolean;
}

export const Header: React.FC<IHeaderBarProps> = ({
  setSessionToken,
  testLogin,
  login,
  userMode,
  resetApp,
  localHostMode,
}) => {
  const responseGoogle = (response: any) => {
    const data = { id_token: response.credential };
    const dataJson = JSON.stringify(data);
    const url = "https://opul8.azurewebsites.net/.auth/login/google";
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: dataJson,
    })
      .then((response) => response.json())
      .then((data) => data.authenticationToken)
      .then((authenticationToken: string) => {
        setSessionToken(authenticationToken);
        localStorage.setItem("sessionTokenKey", authenticationToken);
        return authenticationToken;
      })
      .then((authenticationToken: string) => login(authenticationToken));
  };

  function doLogout() {
    resetApp();
    localStorage.removeItem("sessionTokenKey");
  }

  let loginButton;
  if (userMode === UserMode.Persistent) {
    loginButton = (
      <Button
        onClick={doLogout}
        sx={{
          textTransform: "none",
          backgroundColor: "common.white",
          // color: "common.white",
          py: 1,
          px: 7,
        }}
      >
        Logout
      </Button>
    );
  } else {
    loginButton = <GoogleLogin onSuccess={responseGoogle} onError={() => {}} />;
  }

  const theme = useTheme();
  const collapseMenuToDrawer = useMediaQuery(theme.breakpoints.down("md"));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const toggleDrawer = (open: boolean) => (event: any) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };
  const renderMobileMenu = (
    <Drawer
      anchor="left"
      open={drawerOpen}
      onClose={toggleDrawer(false)}
      sx={{
        zIndex: (theme) => theme.zIndex.appBar + 1,
        "& .MuiDrawer-paper": { backgroundColor: opulColors.primaryBlue },
      }}
    >
      <Box
        sx={{ width: 250 }}
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        <List>
          <ListItem>
            <ListItemText primary="Home" />
          </ListItem>
          <ListItem>
            <HeaderButton pageId={pageIds.home} text="Home" />
          </ListItem>

          {userMode !== UserMode.None && (
            <>
              <ListItem>
                <HeaderButton pageId={pageIds.dashboard} text="Dashboard" />
              </ListItem>
              <ListItem>
                <HeaderButton pageId={pageIds.projections} text="Projections" />
              </ListItem>
              <ListItem>
                <HeaderButton pageId={pageIds.inputs} text="Inputs" />
              </ListItem>
            </>
          )}
          <ListItem>
            <HeaderButton pageId={pageIds.blog} text="Blog" />
          </ListItem>
        </List>
      </Box>
    </Drawer>
  );

  const renderDesktopMenu = (
    <>
      <HeaderButton pageId={pageIds.home} text="Home" />
      {userMode !== UserMode.None && (
        <>
          <HeaderButton pageId={pageIds.dashboard} text="Dashboard" />
          <HeaderButton pageId={pageIds.projections} text="Projections" />
          <HeaderButton pageId={pageIds.inputs} text="Inputs" />
        </>
      )}
      <HeaderButton pageId={pageIds.blog} text="Blog" />
    </>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        elevation={1}
      >
        <Toolbar>
          {collapseMenuToDrawer && (
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
          )}
          {collapseMenuToDrawer ? renderMobileMenu : renderDesktopMenu}
          <Box sx={{ flexGrow: 1 }} />
          {userMode !== UserMode.Persistent && <ButtonSignUp />}
          &nbsp;
          {loginButton}
          {localHostMode && (
            <Button color="inherit" onClick={testLogin}>
              Login test
            </Button>
          )}
        </Toolbar>
      </AppBar>
      {/*Extra Toolbar needed here somehow for alignment */}
      <Toolbar />
    </Box>
  );
};
