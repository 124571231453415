import {
  Container,
  Paper,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import React from "react";
import { MyTitle } from "../../../../Generic/Title";
import { RechartsChartMultipleGeneric } from "../../../../Toolkit/Charts/RechartsChartMultipleGeneric";
import { RechartsGenericMultipleInputs } from "../../../../../Types/recharts";
import {
  KeyValuePair,
  YearsMonthsDays,
} from "../../../../../Types/myPrimitives";
import { tooltipNumberFormatter } from "../../../../../Common/formattingFunctions";

export type TableCaseSetup = {
  color: string;
  name: string;
  earnings: number;
  annualExpenditure: number;
  annualRealDiscreteReturn: string;
  ffAge: number | null;
  ffAgeDifferenceFromBase: number | null;
  ffDate: UserFinancialFreedomDate | null;
};

//enhance a lot of other useful properties could be added
export type UserFinancialFreedomDate = {
  age: DatePeriod;
};

export type DatePeriod = {
  yearCount: YearCount;
  yearsMonthsDays: YearsMonthsDays;
};

export type YearCount = {
  years: number;
  yearCountRoundDown: number;
};

export type ProjectionsWithCaseInputsProps = {
  projections: RechartsGenericMultipleInputs;
  tableData: KeyValuePair<string, TableCaseSetup>[];
};

export const ProjectionsWithCaseInputs: React.FC<
  ProjectionsWithCaseInputsProps
> = ({ projections, tableData }) => {
  return (
    <Container maxWidth="xl" disableGutters>
      <Grid container spacing={2}>
        <Grid xs={12}>
          <Paper
            elevation={0}
            sx={{
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
              // minHeight: "300px",
              height: "450px",
            }}
          >
            <Box
              sx={{
                pt: 1.5,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <MyTitle>Net worth projection</MyTitle>
            </Box>
            <RechartsChartMultipleGeneric
              inputs={{
                ...projections,
                yAxisTickFormatter: tooltipNumberFormatter,
              }}
            />
          </Paper>
        </Grid>
        <Grid xs={12}>
          <Box
            sx={{
              // border: "1px solid grey",
              height: "100%",
              overflow: "auto",
            }}
          >
            <TableContainer component={Paper} elevation={0}>
              <Table sx={{ minWidth: 650 }} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }}>Scenario</TableCell>
                    <TableCell align="right" sx={{ fontWeight: "bold" }}>
                      Earnings
                    </TableCell>
                    <TableCell align="right" sx={{ fontWeight: "bold" }}>
                      Expenditure
                    </TableCell>
                    <TableCell align="right" sx={{ fontWeight: "bold" }}>
                      Investment return (% pa)
                    </TableCell>
                    <TableCell align="right" sx={{ fontWeight: "bold" }}>
                      Financial freedom age
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      Difference
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData.map((rowState) => {
                    const rowStateValue = rowState.value;
                    return (
                      <TableRow
                        key={rowState.key}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <Typography
                            color={rowStateValue.color}
                            sx={{ fontWeight: "bold" }}
                          >
                            {rowStateValue.name}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          {rowStateValue.earnings}
                        </TableCell>
                        <TableCell align="right">
                          {rowStateValue.annualExpenditure}
                        </TableCell>
                        <TableCell align="right">
                          {rowStateValue.annualRealDiscreteReturn}
                        </TableCell>
                        <TableCell align="right">
                          {rowStateValue.ffAge}
                        </TableCell>
                        <TableCell>
                          {rowStateValue.ffAgeDifferenceFromBase &&
                            "(" + rowStateValue.ffAgeDifferenceFromBase + ")"}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};
