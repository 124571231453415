import React from "react";
import { useApis } from "../../../Common/Context/ApiContext";
import {
  ExpenditureDefinitionForm,
  ExpenditureDefn,
} from "../Expenditure/ExpenditureDefinitionForm";

type Props = {
  triggerRerender: () => void;
  setIsCreateVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

export const CreateExpenditureDefinitionForm: React.FC<Props> = ({
  triggerRerender,
  setIsCreateVisible,
}) => {
  const saveExpenditureDefinitionRequest =
    useApis().expenditureDefinitionApis.create;
  const handleCreateExpenditureDefinitionFormSubmit = async (
    expenditureDefinition: ExpenditureDefn
  ) => {
    await saveExpenditureDefinitionRequest(expenditureDefinition);
    triggerRerender();
    setIsCreateVisible(false);
  };

  return (
    <ExpenditureDefinitionForm
      handleFormSubmit={handleCreateExpenditureDefinitionFormSubmit}
    />
  );
};
