import { Grid, TextFieldProps } from "@mui/material";
import { FieldProps } from "formik";
import React from "react";
import { BoldColouredTypography } from "../Generic/BoldColouredTypography";
import { FormTextField } from "../Inputs/FormTextField";

type Props = {
  myColor: string;
  text: string;
};

export const TextFormInput: React.FC<FieldProps & TextFieldProps & Props> = (
  props
) => {
  const { myColor, text, ...rest } = props;
  return (
    <Grid container item xs={12} md={12} lg={12} xl={12}>
      <Grid item xs={6} sx={{ mt: 2.5 }}>
        <BoldColouredTypography variant="body1" text={text} color={myColor} />
      </Grid>
      <Grid item xs={6}>
        <FormTextField {...rest} sx={{ px: 1 }} size="small" />
      </Grid>
    </Grid>
  );
};
