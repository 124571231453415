import React from "react";
import { Form, Formik, Field, FormikProps, FieldArray, getIn } from "formik";
import * as Yup from "yup";
import { FormTextField } from "../FormTextField";
import { Box, Button, TextField, Typography } from "@mui/material";

import {
  GetTodayNoHours,
  opulColors,
} from "../../../Common/Constants/Constants";

const now = GetTodayNoHours();

const validationSchema = Yup.object({
  name: Yup.string().required("Required"),
  expenditure: Yup.array()
    .of(
      Yup.object().shape({
        date: Yup.date()
          .required("Date is required.")
          .typeError("Invalid date"),
        value: Yup.number()
          .required("Value is required.")
          .typeError("Value must be a number"),
      })
    )
    .required("Add at least one date")
    .min(1, "Add at least one date")
    .test(
      "first-date-max",
      `First date can't be in the future`,
      (expenditure) => {
        if (!expenditure || expenditure.length === 0) return true; // Pass validation if no dates
        const firstDate = expenditure[0];
        return firstDate.date! <= now;
      }
    )
    .test(
      "are-dates-in-order",
      "Dates must be unique and in chronological order",
      (expenditure) => {
        if (!expenditure) return false;

        for (let i = 0; i < expenditure.length - 1; i++) {
          if (expenditure === undefined) {
            return false;
          }
          const ithDate = expenditure[i].date;
          const nextDate = expenditure[i + 1].date;
          if (ithDate && nextDate && ithDate >= nextDate) {
            return false;
          }
        }

        return true;
      }
    ),
  oneOffCosts: Yup.array()
    .of(
      Yup.object().shape({
        date: Yup.date()
          .required("Date is required.")
          .typeError("Invalid date"),
        value: Yup.number()
          .required("Value is required.")
          .typeError("Value must be a number"),
      })
    )
    .test(
      "are-dates-in-order",
      "Dates must be unique and in chronological order",
      (costs) => {
        if (!costs) return false;

        for (let i = 0; i < costs.length - 1; i++) {
          if (costs === undefined) {
            return false;
          }
          const ithDate = costs[i].date;
          const nextDate = costs[i + 1].date;
          if (ithDate && nextDate && ithDate >= nextDate) {
            return false;
          }
        }

        return true;
      }
    ),
});

export interface ExpenditureDefn {
  name: string;
  expenditure: { date: string; value: number }[];
  oneOffCosts: { date: string; value: number }[];
}

const defaultInitialValues: ExpenditureDefn = {
  name: "New expenditure definition",
  expenditure: [{ date: "", value: 0 }],
  oneOffCosts: [],
};

type Props = {
  handleFormSubmit: (expenditureDefinition: ExpenditureDefn) => void;
  initialValues?: ExpenditureDefn;
  extraButtons?: {
    handleDeleteClick: () => void;
    handleMakeDefaultClick: () => void;
  };
};

export const ExpenditureDefinitionForm: React.FC<Props> = ({
  handleFormSubmit,
  initialValues = defaultInitialValues,
  extraButtons,
}) => {
  const handleSubmit = (
    values: ExpenditureDefn,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    setSubmitting(true);
    handleFormSubmit(values);
    setSubmitting(false);
  };

  return (
    <>
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) =>
          handleSubmit(values, { setSubmitting })
        }
      >
        {({
          values,
          handleChange,
          handleBlur,
          errors,
          touched,
        }: FormikProps<ExpenditureDefn>) => (
          <Form>
            <Field
              name="name"
              label="Name"
              component={FormTextField}
              margin="dense"
            />
            <br />
            <br />

            <Typography variant="h6">Expenditure by date</Typography>
            <FieldArray
              name="expenditure"
              render={(arrayHelpers) => (
                <div>
                  {values.expenditure.map((pair, index) => (
                    <div key={index}>
                      <Field
                        as={TextField}
                        name={`expenditure.${index}.date`}
                        type="date"
                        size="small"
                        margin="dense"
                        value={pair.date}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        sx={{ mt: 1 }}
                        error={Boolean(
                          getIn(errors, `expenditure[${index}].date`) &&
                            getIn(touched, `expenditure[${index}].date`)
                        )}
                        helperText={
                          getIn(touched, `expenditure[${index}].date`) &&
                          getIn(errors, `expenditure[${index}].date`)
                            ? getIn(errors, `expenditure[${index}].date`)
                            : ""
                        }
                      />
                      <Field
                        as={TextField}
                        name={`expenditure.${index}.value`}
                        type="number"
                        label="Value"
                        size="small"
                        margin="dense"
                        value={pair.value}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        sx={{ mt: 1, ml: 2 }}
                        error={Boolean(
                          getIn(errors, `expenditure[${index}].value`) &&
                            getIn(touched, `expenditure[${index}].value`)
                        )}
                        helperText={
                          getIn(touched, `expenditure[${index}].value`) &&
                          getIn(errors, `expenditure[${index}].value`)
                            ? getIn(errors, `expenditure[${index}].value`)
                            : ""
                        }
                      />
                      <Button
                        type="button"
                        onClick={() => arrayHelpers.remove(index)}
                        sx={{ m: 1 }}
                      >
                        Remove
                      </Button>
                    </div>
                  ))}
                  <Button
                    type="button"
                    onClick={() => arrayHelpers.push({ date: "", value: 0 })}
                    sx={{ m: 1 }}
                  >
                    Add expenditure date
                  </Button>
                </div>
              )}
            />

            {errors.expenditure && !Array.isArray(errors.expenditure) && (
              <Typography
                sx={{ mb: 2, color: (theme) => theme.palette.error.main }}
              >
                {errors.expenditure}
              </Typography>
            )}

            <br />
            <Typography variant="h6">One-off costs by date</Typography>
            <FieldArray
              name="oneOffCosts"
              render={(arrayHelpers) => (
                <div>
                  {values.oneOffCosts.map((pair, index) => (
                    <div key={index}>
                      <Field
                        as={TextField}
                        name={`costs.${index}.date`}
                        type="date"
                        size="small"
                        margin="dense"
                        // label="Date"
                        value={pair.date}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        sx={{ mt: 1 }}
                        error={Boolean(
                          getIn(errors, `oneoffcosts[${index}].date`) &&
                            getIn(touched, `oneoffcosts[${index}].date`)
                        )}
                        helperText={
                          getIn(touched, `oneoffcosts[${index}].date`) &&
                          getIn(errors, `oneoffcosts[${index}].date`)
                            ? getIn(errors, `oneoffcosts[${index}].date`)
                            : ""
                        }
                      />
                      <Field
                        as={TextField}
                        name={`oneoffcosts.${index}.value`}
                        type="number"
                        label="Value"
                        size="small"
                        margin="dense"
                        value={pair.value}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        sx={{ mt: 1, ml: 2 }}
                        error={Boolean(
                          getIn(errors, `oneoffcosts[${index}].value`) &&
                            getIn(touched, `oneoffcosts[${index}].value`)
                        )}
                        helperText={
                          getIn(touched, `oneoffcosts[${index}].value`) &&
                          getIn(errors, `oneoffcosts[${index}].value`)
                            ? getIn(errors, `oneoffcosts[${index}].value`)
                            : ""
                        }
                      />
                      <Button
                        type="button"
                        onClick={() => arrayHelpers.remove(index)}
                        sx={{ m: 1 }}
                      >
                        Remove
                      </Button>
                    </div>
                  ))}
                  <Button
                    type="button"
                    onClick={() => arrayHelpers.push({ date: "", value: 0 })}
                    sx={{ m: 1 }}
                  >
                    Add cost
                  </Button>
                </div>
              )}
            />

            {errors.oneOffCosts && !Array.isArray(errors.oneOffCosts) && (
              <Typography
                sx={{ mb: 2, color: (theme) => theme.palette.error.main }}
              >
                {errors.oneOffCosts}
              </Typography>
            )}

            <Box display="flex" justifyContent="space-between">
              <Button
                type="submit"
                variant="contained"
                size="large"
                sx={{
                  textTransform: "none",
                  backgroundColor: opulColors.opulDarkBlue,
                  color: "common.white",
                  py: 1,
                  px: 7,
                  fontSize: 20,
                }}
              >
                Save
              </Button>
              {extraButtons && (
                <Button
                  variant="contained"
                  size="large"
                  sx={{
                    textTransform: "none",
                    backgroundColor: opulColors.opulDarkGreen,
                    // "&:hover": {
                    //   backgroundColor: opulColors.deleteRedHover,
                    // },
                    color: "common.white",
                    py: 1,
                    px: 7,
                    fontSize: 20,
                  }}
                  onClick={extraButtons.handleMakeDefaultClick}
                >
                  Set as current
                </Button>
              )}
              {extraButtons && (
                <Button
                  variant="contained"
                  size="large"
                  sx={{
                    textTransform: "none",
                    backgroundColor: opulColors.deleteRed,
                    "&:hover": {
                      backgroundColor: opulColors.deleteRedHover,
                    },
                    color: "common.white",
                    py: 1,
                    px: 7,
                    fontSize: 20,
                  }}
                  onClick={extraButtons.handleDeleteClick}
                >
                  Delete definition
                </Button>
              )}
            </Box>
            {/* <div>Values</div>
            <pre>{JSON.stringify(values, null, 2)}</pre>
            <div>Errors</div>
            <pre>{JSON.stringify(errors, null, 2)}</pre>
            <div>Touched</div>
            <pre>{JSON.stringify(touched, null, 2)}</pre> */}
          </Form>
        )}
      </Formik>
    </>
  );
};
