import { Box, Slider, Button, Grid } from "@mui/material";
import { Formik, Form, Field } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { opulColors, scenarioIds } from "../../../Common/Constants/Constants";
import * as Yup from "yup";
import { TextFormInput } from "../TextFormInput";
import { postApiRequest } from "../../../Common/Apis/genericApiFunctions";
import { RechartsGenericMultipleInputs } from "../../../Types/recharts";
import { StringKeyNumberValue } from "../../../Types/myPrimitives";
import { colorOfHighlightedLine } from "./ProjectionModelling";
import { UrlContext } from "../../../Common/Context/UrlContext";
import { UserTokenContext } from "../../../Common/Context/UserTokenContext";
import { UserModeContext } from "../../../Common/Context/UserModeContext";
import { SliderResults } from "../../../Types/results";

type Props = {
  setProjectionPageState: (sliderResults: SliderResults) => void;
  setProjectionsState: React.Dispatch<
    React.SetStateAction<RechartsGenericMultipleInputs>
  >;
  projections: RechartsGenericMultipleInputs;
};

const validationSchema = Yup.object({
  alternateExpenditure1: Yup.number().nullable(true),
  alternateExpenditure2: Yup.number().nullable(true),
  lowerSpendDaily: Yup.number().nullable(true),
  lowerSpendMonthl: Yup.number().nullable(true),
});

const initialFormikState: formikShape = {
  alternateExpenditure1: null,
  alternateExpenditure2: null,
  lowerSpendDaily: null,
  lowerSpendMonthly: null,
};

type formikShape = {
  alternateExpenditure1: number | null;
  alternateExpenditure2: number | null;
  lowerSpendDaily: number | null;
  lowerSpendMonthly: number | null;
};

//This component on load needs to request the slider values and results.
//This component needs to be able to send off values from inputs & chart results.
//Unoptimised - this can be a recalculate of all the slider results to be plotted straight away
export const ExpenditureInputs: React.FC<Props> = ({
  setProjectionPageState,
  setProjectionsState,
  projections,
}) => {
  //Context and state
  const urls = useContext(UrlContext);
  const token = useContext(UserTokenContext);
  const userMode = useContext(UserModeContext);
  const [sliderMap, setSliderMap] = useState<Map<number, string>>(
    new Map<number, string>()
  );
  const initialMarks = [
    {
      value: 0,
      label: "",
    },
    { value: 50, label: "" },
    { value: 100, label: "" },
  ];
  const [sliderMarks, setSliderMarks] = useState(initialMarks);

  //Functions
  const setMapAndMarks = (sliderValuesById: StringKeyNumberValue[]) => {
    const mapFromSliderValueToId = new Map<number, string>();
    const newSliderMarks: { value: number; label: string }[] = [];
    sliderValuesById.forEach((element) => {
      mapFromSliderValueToId.set(element.value, element.key);
      newSliderMarks.push({
        value: element.value,
        label: element.value.toString(),
      });
    });
    setSliderMap(mapFromSliderValueToId);
    setSliderMarks(newSliderMarks);
  };

  const expenditureApiRequest = async (formValues: formikShape) => {
    const apiResponseData: SliderResults = await postApiRequest(
      urls.expenditureModelling,
      token,
      formValues,
      userMode
    );
    setProjectionPageState(apiResponseData);
    setMapAndMarks(apiResponseData.sliderValuesById);
  };

  useEffect(() => {
    expenditureApiRequest(initialFormikState);
  }, []);

  const onSliderChange = (
    event: Event,
    value: number | number[],
    activeThumb: number
  ) => {
    const newSliderId = sliderMap.get(value as number);

    //return new RechartsSeriesObjectWithUpdatedColors
    const newSeriesMetaData = projections.seriesMetaData.map((k, i) => {
      if (k.key === newSliderId) {
        return { ...k, resultsColor: colorOfHighlightedLine, strokeWidth: 3 }; // Edit the property of the matching item
      } else if (k.key === scenarioIds.base) {
        return { ...k, resultsColor: opulColors.baseCaseColor, strokeWidth: 3 };
      } else if (k.key === scenarioIds.alternate + "1") {
        return { ...k, resultsColor: opulColors.opulCyan, strokeWidth: 3 };
      } else if (k.key === scenarioIds.alternate + "2") {
        return { ...k, resultsColor: opulColors.opulPurple, strokeWidth: 3 };
      } else if (k.key === scenarioIds.lowerDailySpend) {
        return { ...k, resultsColor: opulColors.opulDarkGreen, strokeWidth: 3 };
      } else if (k.key === scenarioIds.lowerMonthlySpend) {
        return {
          ...k,
          resultsColor: opulColors.opulLightGreen,
          strokeWidth: 3,
        };
      } else {
        return { ...k, resultsColor: opulColors.opulLightGrey, strokeWidth: 2 };
      }
    });

    //setStateWithUpdatedColors
    setProjectionsState((projections) => ({
      ...projections,
      seriesMetaData: newSeriesMetaData,
    }));
  };

  //JSX
  return (
    <Box
    // sx={{
    //   border: "1px solid grey",
    // }}
    >
      <Box sx={{ mx: 3 }}>
        <Slider
          defaultValue={50}
          valueLabelDisplay="auto"
          step={null}
          marks={sliderMarks}
          min={sliderMarks[0].value}
          max={sliderMarks[sliderMarks.length - 1].value}
          onChange={onSliderChange}
          // sx={{ ml: 4, mr: 10 }}
        />

        <Grid container spacing={2}>
          <Formik
            enableReinitialize
            validationSchema={validationSchema}
            initialValues={initialFormikState}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              expenditureApiRequest(values);
              setSubmitting(false);
            }}
          >
            {({ values, errors, setFieldValue, touched }) => (
              <Box sx={{ py: 1, my: 1 }}>
                <Form>
                  <Grid container spacing={0} alignItems="start">
                    <Grid item xs={3}>
                      <Field
                        name="alternateExpenditure1"
                        label="Amount"
                        component={TextFormInput}
                        fullWidth
                        margin="dense"
                        myColor={opulColors.opulCyan}
                        text={"Alternate expenditure 1"}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Field
                        name="alternateExpenditure2"
                        label="Amount"
                        component={TextFormInput}
                        fullWidth
                        margin="dense"
                        myColor={opulColors.opulPurple}
                        text={"Alternate expenditure 2"}
                      />
                    </Grid>

                    <Grid item xs={3}>
                      <Field
                        name="lowerSpendDaily"
                        label="Amount"
                        component={TextFormInput}
                        fullWidth
                        margin="dense"
                        myColor={opulColors.opulDarkGreen}
                        text={"Daily saving"}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Field
                        name="lowerSpendMonthly"
                        label="Amount"
                        component={TextFormInput}
                        fullWidth
                        margin="dense"
                        myColor={opulColors.opulLightGreen}
                        text={"Monthly saving"}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Box display="flex" justifyContent="center">
                        <Button
                          type="submit"
                          variant="contained"
                          size="large"
                          sx={{
                            textTransform: "none",
                            backgroundColor: opulColors.opulDarkBlue,
                            color: "common.white",
                            py: 1,
                            // px: 0,
                            fontSize: 16,
                          }}
                        >
                          Run modelling
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Form>
                {/* <pre>{JSON.stringify(values, null, 2)}</pre>
                <pre>{JSON.stringify(errors, null, 2)}</pre>
                <pre>{JSON.stringify(touched, null, 2)}</pre> */}
              </Box>
            )}
          </Formik>
        </Grid>
      </Box>
    </Box>
  );
};
