import { Tooltip, Typography } from "@mui/material";
import React from "react";

type Props = {
  tooltipText: string;
  text: string;
};

export const MyTooltip: React.FC<Props> = ({ tooltipText, text }) => {
  return (
    <Tooltip title={tooltipText}>
      <Typography
        style={{
          textDecoration: "underline",
          textDecorationColor: "rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
          display: "inline",
        }}
      >
        {text}
      </Typography>
    </Tooltip>
  );
};
