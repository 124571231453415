import { Grid, Tooltip } from "@mui/material";
import { Field } from "formik";
import React from "react";
import { FormTextField } from "./FormTextField";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

type Props = {
  fieldName: string;
  fieldLabel: string;
  tooltipText: string;
};

export const MyFieldWithTooltip: React.FC<Props> = ({
  tooltipText,
  fieldName,
  fieldLabel,
}) => {
  return (
    <>
      <Grid item xs={11}>
        <Field
          name={fieldName}
          label={fieldLabel}
          component={FormTextField}
          margin="dense"
          fullWidth
          size="small"
        />
      </Grid>
      <Grid
        container
        item
        xs={1}
        sx={{ alignItems: "center", justifyContent: "center" }}
      >
        <Tooltip title={tooltipText}>
          <InfoOutlinedIcon />
        </Tooltip>
      </Grid>
    </>
  );
};
