import React from "react";
import { Urls } from "../Constants/Urls";
import { UserMode, UserModeContext } from "./UserModeContext";
import { UrlContext } from "./UrlContext";
import { UserTokenContext } from "./UserTokenContext";
import { GeneralInputsContext } from "./GeneralInputsContext";
import { PersistentUserGeneralInputs } from "../../Types/inputs";
import { AuthContext } from "./AuthContext";
import { BaseCaseModelResultsContext } from "./BaseCaseModelResultsContext";
import { BaseCaseModelResults } from "../../Types/results";
import { ApiContext, IApiContext } from "./ApiContext";

type Props = {
  children: React.ReactNode;
  urls: Urls;
  sessionToken: string;
  userMode: UserMode;
  generalInputs: PersistentUserGeneralInputs | undefined;
  userSignUp: (authenticationToken: string, formValues: any) => void;
  baseCaseModelResults: BaseCaseModelResults | null;
  apis: IApiContext;
};

export const AppContext: React.FC<Props> = ({
  children,
  urls,
  sessionToken,
  userMode,
  generalInputs,
  userSignUp,
  baseCaseModelResults,
  apis,
}) => {
  return (
    <UrlContext.Provider value={urls}>
      <UserTokenContext.Provider value={sessionToken}>
        <UserModeContext.Provider value={userMode}>
          <GeneralInputsContext.Provider value={generalInputs}>
            <BaseCaseModelResultsContext.Provider value={baseCaseModelResults}>
              <AuthContext.Provider value={{ userSignUp }}>
                <ApiContext.Provider value={apis}>
                  {children}
                </ApiContext.Provider>
              </AuthContext.Provider>
            </BaseCaseModelResultsContext.Provider>
          </GeneralInputsContext.Provider>
        </UserModeContext.Provider>
      </UserTokenContext.Provider>
    </UrlContext.Provider>
  );
};
