import React, { useContext, useEffect, useState } from "react";
import { getApiRequest } from "../../Common/Apis/genericApiFunctions";
import { UrlContext } from "../../Common/Context/UrlContext";
import { Pitch, PitchData } from "./Pitch";

type Props = {};

export const PitchContainer: React.FC<Props> = () => {
  const urls = useContext(UrlContext);
  const [pitchData, setPitchData] = useState<PitchData | null>(null);

  const pitchApiRequest = async () => {
    const apiResponseData: PitchData = await getApiRequest(urls.pitchData, "");
    setPitchData(apiResponseData);
  };

  useEffect(() => {
    pitchApiRequest();
  }, []);

  return <>{pitchData && <Pitch pitchDataInput={pitchData}></Pitch>}</>;
};
