import React from "react";
import { examplePersonGeneralInputs } from "../ExamplePersonGeneralInputs";
import { ProjectionsFromCaseInputsSlider } from "./ProjectionsFromCaseInputsSlider";
import { RechartsGenericMultipleInputs } from "../../../../../Types/recharts";

type Props = {};

export const WithSavingProjectionSlider: React.FC<Props> = () => {
  return (
    <ProjectionsFromCaseInputsSlider
      initialSliderId={"Expenditure0"}
      projectionScenariosRequest={{
        generalInputs: examplePersonGeneralInputs,
        expenditureScenario: null,
        realReturnScenario: null,
        borrowingScenario: null,
        sliderRequest: "Expenditure",
      }}
      updateResponse={(response: RechartsGenericMultipleInputs) => {
        return { ...response, yAxisDomain: [0, 350000] };
      }}
      valueLabelFormat={function (value: number, index: number): string {
        return value.toString();
      }}
    />
  );
};
