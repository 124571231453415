import { Box, Tabs, Tab } from "@mui/material";
import React from "react";
import { a11yProps, TabPanel } from "../../Generic/TabPanel";
import { ExpenditureInputs } from "./ExpenditureInputs";
import { RechartsGenericMultipleInputs } from "../../../Types/recharts";
import { CareerInputs } from "./CareerInputs";
import { InvestmentReturnInputs } from "./InvestmentReturnInputs";
import { LegacyInputs } from "./LegacyInputs";
import { OneOffInputs } from "./OneOffInputs";
import { PersistentUserGeneralInputs } from "../../../Types/inputs";
import { ProjectionResults } from "../../../Types/results";

type Props = {
  generalInputs: PersistentUserGeneralInputs;
  setProjectionsState: React.Dispatch<
    React.SetStateAction<RechartsGenericMultipleInputs>
  >;
  projectionsState: RechartsGenericMultipleInputs;
  setProjectionPageState: (sliderResults: ProjectionResults) => void;
};

export const ProjectionModellingInputs: React.FC<Props> = ({
  generalInputs,
  setProjectionPageState,
  setProjectionsState,
  projectionsState,
}) => {
  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Box
      sx={{
        // border: "1px solid grey",
        bgcolor: "white",
        height: "100%",
        overflow: "auto",
      }}
    >
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          justifyContent: "center",
        }}
      >
        <Tabs centered value={tabValue} onChange={handleTabChange}>
          <Tab label="Expenditure" {...a11yProps(0)} />
          <Tab label="Career" {...a11yProps(1)} />
          <Tab label="Investment returns" {...a11yProps(2)} />
          <Tab label="Legacy" {...a11yProps(3)} />
          <Tab label="One-off" {...a11yProps(4)} />
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={0}>
        <ExpenditureInputs
          setProjectionPageState={setProjectionPageState}
          setProjectionsState={setProjectionsState}
          projections={projectionsState}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <CareerInputs
          setProjectionsState={setProjectionsState}
          setProjectionPageState={setProjectionPageState}
          projections={projectionsState}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <InvestmentReturnInputs
          // add context of "base case" to app?
          currentAnnualInvestmentReturn={
            generalInputs.personalDetail.returnAboveInflationAssumption
          }
          setProjectionsState={setProjectionsState}
          setProjectionPageState={setProjectionPageState}
          projections={projectionsState}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={3}>
        <LegacyInputs
          currentLegacy={generalInputs.personalDetail.inheritanceToLeave}
          setProjectionPageState={setProjectionPageState}
          setProjectionsState={setProjectionsState}
          projections={projectionsState}
        />
      </TabPanel>
      <TabPanel value={tabValue} index={4}>
        <OneOffInputs
          setProjectionsState={setProjectionsState}
          setProjectionPageState={setProjectionPageState}
          projections={projectionsState}
        />
      </TabPanel>
    </Box>
  );
};
