import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React from "react";

export enum CurrencySymbol {
  Gbp = "£",
  Dollar = "$",
  Euro = "€",
  Yen = "¥",
  Bitcoin = "₿",
  Franc = "₣",
  Rupee = "₹",
  Default = "",
}

type Props = {
  currencySymbol: CurrencySymbol;
  handleCurrencyChange: (event: SelectChangeEvent) => void;
};

export const CurrencySelector: React.FC<Props> = ({
  currencySymbol,
  handleCurrencyChange,
}) => {
  return (
    <FormControl
      size="small"
      sx={{
        // "& .MuiInputBase-root": { fontSize: "0.8rem" },
        // "& .MuiInputLabel-root": { fontSize: "0.8rem" },
        "& .MuiSelect-select": { px: "8px", py: "4px" },
      }}
    >
      <Select
        value={currencySymbol}
        // label="Currency"
        onChange={handleCurrencyChange}
        size="small"
      >
        {Object.values(CurrencySymbol).map((x) => {
          if (x === CurrencySymbol.Default) {
            return null;
          }
          return <MenuItem value={x}>{x.toString()}</MenuItem>;
        })}
        <MenuItem value={CurrencySymbol.Default}>{"None"}</MenuItem>
      </Select>
    </FormControl>
  );
};
