import { useEffect, useState } from "react";
import "./App.css";
import { Homepage } from "./Components/Homepage/Homepage";
import { pageIds } from "./Common/Constants/Constants";
import { Inputs } from "./Components/Inputs/Inputs";
import {
  postApiRequestThatSetsModelState,
  handleApiError,
  handleErrors,
} from "./Common/Apis/genericApiFunctions";
import { PortfolionCalibrationRequest } from "./Types/requests";
import { Dashboard } from "./Components/Toolkit/Dashboard/Dashboard";
import { Test } from "./Components/Test/Test";
import { getURLs } from "./Common/Constants/Urls";
import { ProjectionModellingNullable } from "./Components/Toolkit/ProjectionModelling/ProjectionModellingNullable";
import { PitchContainer } from "./Components/Pitch/PitchContainer";
import { GetInitialUserMode, UserMode } from "./Common/Context/UserModeContext";
import { AppContext } from "./Common/Context/AppContext";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import { Root } from "./Root";
import { BlogContainer } from "./Components/Blog/BlogContainer";
import BlogDetail from "./Components/Blog/BlogDetail";
import { BaseCaseModelResults } from "./Types/results";
import { AllUserInputs } from "./Types/inputs";
import { IApiContext } from "./Common/Context/ApiContext";
import { getApiRequests } from "./Common/Apis/ConsumeApiFunctions/apiRequests";

const localHostMode: boolean =
  process.env.REACT_APP_LOCALHOST_APIS_MODE === "true";
const urls = getURLs(localHostMode);

export default function App() {
  const [userMode, setUserMode] = useState(GetInitialUserMode(localHostMode));
  const [sessionToken, setSessionToken] = useState("");
  const [allUserInputs, setAllUserInputs] = useState<AllUserInputs | null>(
    null
  );
  const [baseResults, setBaseResults] = useState<BaseCaseModelResults | null>(
    null
  );

  useEffect(() => {
    let fromLocalStorage = localStorage.getItem("sessionTokenKey");
    if (fromLocalStorage && !localHostMode) {
      setSessionToken(fromLocalStorage);
      loginExistingUser(fromLocalStorage);
    }
  }, [localHostMode]);

  function resetApp() {
    setUserMode(UserMode.None);
    setSessionToken("");
    setAllUserInputs(null);
    setBaseResults(null);
  }

  const testLogin = () => {
    const url = urls.localHostLoginUrl;
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
    })
      .then(handleErrors)
      .then((response) => response.json())
      .then((data) => {
        handleUserLoginResponse(data);
      })
      .catch((error) => {
        handleApiError(error, setBaseResults);
      });
  };

  const userSignUp = (authenticationToken: string, formValues: any) => {
    setSessionToken(authenticationToken);
    localStorage.setItem("sessionTokenKey", authenticationToken);

    setUserMode(UserMode.Persistent);
    let url = urls.signUpLogin;
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        "X-ZUMO-AUTH": authenticationToken,
      },
      body: JSON.stringify(formValues),
    })
      .then(handleErrors)
      .then((response) => response.json())
      .then((data) => {
        handleUserLoginResponse(data);
      })
      .catch((error) => {
        handleApiError(error, setBaseResults);
      });
  };

  const parseAndSetAllUserInputs = (data: any) => {
    let inputs: AllUserInputs = data;
    inputs.generalInputs.dateOfBirth = new Date(data.generalInputs.dateOfBirth);
    setAllUserInputs(inputs);
  };

  const handleUserLoginResponse = (data: any) => {
    setBaseResults(data.results);
    parseAndSetAllUserInputs(data.allUserInputs);
    setUserMode(UserMode.Persistent);
  };

  const loginExistingUser = (authenticationToken: string) => {
    setUserMode(UserMode.Persistent);
    let url = urls.login;
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        "X-ZUMO-AUTH": authenticationToken,
      },
    })
      .then(handleErrors)
      .then((response) => response.json())
      .then((data) => {
        handleUserLoginResponse(data);
      })
      .catch((error) => {
        handleApiError(error, setBaseResults);
      });
  };

  const portfolioCalibrationRequest = (dto: PortfolionCalibrationRequest) => {
    const url = urls.processPortfolioCalibration;
    postApiRequestThatSetsModelState(
      dto,
      url,
      sessionToken,
      setBaseResults,
      parseAndSetAllUserInputs
    );
  };

  const apis: IApiContext = getApiRequests(
    urls,
    sessionToken,
    userMode,
    setBaseResults,
    parseAndSetAllUserInputs
  );

  //to limit cold starts
  useEffect(() => {
    const url = urls.spinUpDatabaseFunction;
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
    });
  }, []);

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <Root
          setSessionToken={setSessionToken}
          testLogin={testLogin}
          login={loginExistingUser}
          userMode={userMode}
          resetApp={resetApp}
          localHostMode={localHostMode}
        />
      ),
      children: [
        {
          path: "/",
          element: <Navigate to={"/" + pageIds.home} replace={true} />,
        },
        {
          path: pageIds.home,
          element: (
            <Homepage
              resetApp={resetApp}
              setResults={setBaseResults}
              setAllUserInputs={parseAndSetAllUserInputs}
              setUserMode={setUserMode}
            />
          ),
        },
        {
          path: pageIds.dashboard,
          element: <Dashboard results={baseResults} />,
        },
        {
          path: pageIds.projections,
          element: (
            <ProjectionModellingNullable
              projections={baseResults?.projections}
            />
          ),
        },
        {
          path: pageIds.inputs,
          element: (
            <Inputs
              allUserInputs={allUserInputs}
              accountDefinitionApis={apis.accountDefinitionApis}
              portfolioCalibrationRequest={portfolioCalibrationRequest}
              setResults={setBaseResults}
              setAllUserInputs={parseAndSetAllUserInputs}
            />
          ),
        },
        {
          path: pageIds.blog,
          element: <BlogContainer />,
        },
        {
          path: pageIds.blog + "/:id",
          element: <BlogDetail />,
        },
        {
          path: pageIds.blog + "/draft/:id",
          element: <BlogDetail />,
        },
        // {
        //   path: pageIds.learn,
        //   element: <Learn />,
        // },
        {
          path: pageIds.test,
          element: <Test />,
        },
        {
          path: pageIds.pitch,
          element: <PitchContainer />,
        },
      ],
    },
  ]);

  return (
    <div className="App">
      <AppContext
        urls={urls}
        sessionToken={sessionToken}
        userMode={userMode}
        generalInputs={allUserInputs?.generalInputs}
        userSignUp={userSignUp}
        baseCaseModelResults={baseResults}
        apis={apis}
      >
        <RouterProvider router={router} />
      </AppContext>
    </div>
  );
}
