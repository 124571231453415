import BlogData from "./BlogData";
import { WhatIsYourFFAge } from "./BlogPieces/1_WhatIsYourFFAge/WhatIsYourFFAge";
import { InteractiveIntroductionToFF } from "./BlogPieces/2_InteractiveIntroductionToFF/InteractiveIntroductionToFF";

export const draftBlogs: BlogData[] = [
  {
    id: 2,
    title: "An interactive introduction to financial freedom",
    content: <InteractiveIntroductionToFF />,
  },
  {
    id: 3,
    title: "A simple but powerful way to invest",
    content: "Content for blog 2...",
  },
];
