import { Paper, Box } from "@mui/material";
import React from "react";
import { MyTitle } from "../../../../Generic/Title";
import { RechartsChartMultipleGeneric } from "../../../../Toolkit/Charts/RechartsChartMultipleGeneric";
import {
  RechartsGenericMultipleInputs,
  RechartsSeriesMetaData,
} from "../../../../../Types/recharts";
import { tooltipNumberFormatter } from "../../../../../Common/formattingFunctions";

export type ConditionalChartData = {
  initialChartSetup: RechartsGenericMultipleInputs;
  conditionalSeriesMetaData: RechartsSeriesMetaData[];
};

type Props = ConditionalChartData & {
  conditionalLineId: string;
};

export const ProjectionsChartWithConditionalLine: React.FC<Props> = ({
  initialChartSetup,
  conditionalSeriesMetaData,
  conditionalLineId,
}) => {
  const getProjections = () => {
    // Create a deep copy of seriesMetaData to avoid mutation
    let initialSeriesMetaData = initialChartSetup.seriesMetaData.map(
      (metaData) => ({
        ...metaData,
      })
    );

    // Find the conditional metadata
    const additionalSeriesMetaData = conditionalSeriesMetaData.find(
      (element) => element.key === conditionalLineId
    );

    // If found, append it to the series metadata array
    if (additionalSeriesMetaData) {
      initialSeriesMetaData = [
        ...initialSeriesMetaData,
        additionalSeriesMetaData,
      ];
    }

    // Return a new chart setup object with the updated seriesMetaData
    return {
      ...initialChartSetup,
      seriesMetaData: initialSeriesMetaData,
    };
  };

  return (
    <Paper
      elevation={0}
      sx={{
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        // minHeight: "300px",
        height: "400px",
      }}
    >
      <Box
        sx={{
          pt: 1.5,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <MyTitle>Net worth projection</MyTitle>
      </Box>
      <RechartsChartMultipleGeneric
        inputs={{
          ...getProjections(),
          yAxisTickFormatter: tooltipNumberFormatter,
        }}
      />
    </Paper>
  );
};
