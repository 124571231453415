import { Typography } from "@mui/material";
import React from "react";

type Props = {
  children: React.ReactNode;
  color?: string;
};

export const InlineBold: React.FC<Props> = ({ children, color }) => {
  return (
    <Typography
      component="span"
      sx={{ fontWeight: "bold", color: color, display: "inline" }}
    >
      {children}
    </Typography>
  );
};
