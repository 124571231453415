import React from "react";
import { Button, Typography } from "@mui/material";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { pageIds } from "../../Common/Constants/Constants";

type Props = { pageId: string; text: string };

export const HeaderButton: React.FC<Props> = ({ pageId, text }) => {
  const location = useLocation();
  let isCurrentRoute = location.pathname.startsWith("/" + pageId);

  //todo create optional prop "secondaryRoute" and pass through rather than this hack
  if (isCurrentRoute === false) {
    if (pageId === pageIds.home) {
      isCurrentRoute = location.pathname === "/";
    }
  }

  return (
    <Button
      // variant="outlined"
      component={RouterLink}
      to={"/" + pageId}
      sx={{
        color: "white",
        textTransform: "none",
        height: "56px",
        fontSize: 20,
        px: 2,
        borderRadius: 0,
        textDecoration: isCurrentRoute ? "underline" : "none",
      }}
    >
      <Typography variant="h6">{text}</Typography>
    </Button>
  );
};
