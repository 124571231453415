import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from "@mui/material";
import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ReadAllCareerDefinitionsResponseDto } from "../../../Types/apiResponses";
import { MediumWidthContainer } from "../../Generic/MediumWidthContainer";
import { MyButton } from "../../Header/MyButton";
import { CreateCareerDefinitionForm } from "./CreateCareerDefinitionForm";
import { UpdateCareerDefinitionForm } from "./UpdateCareerDefinitionForm";

const createNewPanelKey = "panelNew";

type Props = {
  careerDefinitions: ReadAllCareerDefinitionsResponseDto;
  triggerRerender: () => void;
};

export const CareerDefinitions: React.FC<Props> = ({
  careerDefinitions,
  triggerRerender,
}) => {
  const [isCreateVisible, setIsCreateVisible] = React.useState<boolean>(false);
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  function handleCreateNew(): void {
    setIsCreateVisible(true);
    setExpanded(createNewPanelKey);
  }

  return (
    <MediumWidthContainer pxForMediumScreen={0}>
      <Typography>
        Use the earnings and costs by date to define different career paths for
        comparison. Costs are for anything relating to this specific career, for
        instance spending money on education, buying equipment or renting a
        workspace. Do not include your other lifestyle costs here.
      </Typography>
      <br />
      {careerDefinitions?.map((defn, index) => {
        return (
          <Accordion
            key={`panel${index}`}
            expanded={expanded === `panel${index + 1}`}
            onChange={handleAccordionChange(`panel${index + 1}`)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography sx={{ width: "33%", flexShrink: 0 }}>
                {defn.value.name}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <UpdateCareerDefinitionForm
                initialValues={defn.value}
                triggerRerender={triggerRerender}
                id={defn.key}
                setExpanded={setExpanded}
              />
            </AccordionDetails>
          </Accordion>
        );
      })}
      {isCreateVisible && (
        <Accordion
          key={createNewPanelKey}
          expanded={expanded === createNewPanelKey}
          onChange={handleAccordionChange(createNewPanelKey)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography sx={{ width: "33%", flexShrink: 0 }}>
              Create new career definition
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CreateCareerDefinitionForm
              triggerRerender={triggerRerender}
              setIsCreateVisible={setIsCreateVisible}
            />
          </AccordionDetails>
        </Accordion>
      )}
      <br />
      <MyButton buttonText={"Create new"} onClick={handleCreateNew} />
    </MediumWidthContainer>
  );
};
