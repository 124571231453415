import { createTheme } from "@mui/material/styles";
import { opulColors } from "./Common/Constants/Constants";

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: { main: opulColors.primaryBlue },
    secondary: { main: opulColors.secondaryBlue },
    background: { default: opulColors.backgroundGrey },
  },
  typography: {
    fontFamily: "Raleway",
    h6: {
      lineHeight: 1.4,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "*::-webkit-scrollbar": {
          width: "0.55em",
        },
        "*::-webkit-scrollbar-thumb": {
          backgroundColor: "rgba(0,0,0,.25)",
          borderRadius: "20px",
        },
        "*::-webkit-scrollbar-thumb:hover": {
          backgroundColor: "rgba(0,0,0,.35)",
        },
      },
    },
  },
});

export default theme;
