import React, { useContext } from "react";
import { Box, Button, Paper, Slider, Typography } from "@mui/material";
import { RechartsChartMultipleGeneric } from "../../../Toolkit/Charts/RechartsChartMultipleGeneric";
import { ProjectionsWithCaseInputsProps } from "../2_InteractiveIntroductionToFF/ProjectionCharts/ProjectionsWithCaseInputs";
import {
  opulColors,
  pageIds,
  scenarioIds,
} from "../../../../Common/Constants/Constants";
import { RechartsReferenceLineParameters } from "../../../../Types/recharts";
import { MyTitle } from "../../../Generic/Title";
import { ButtonSignUp } from "../../../Generic/ButtonSignUp";
import {
  UserMode,
  UserModeContext,
} from "../../../../Common/Context/UserModeContext";
import { useNavigate } from "react-router-dom";

type Props = {
  results: ProjectionsWithCaseInputsProps;
};

export const WhatIsYourFFAgeResults: React.FC<Props> = ({ results }) => {
  const navigate = useNavigate();

  const userMode = useContext(UserModeContext);

  const baseCaseFfDatePeriod = results.tableData.find(
    (pair) => pair.key === scenarioIds.base
  )?.value.ffDate?.age;

  const baseCaseAge = baseCaseFfDatePeriod?.yearCount.years;
  const baseCaseYears = baseCaseFfDatePeriod?.yearsMonthsDays.years;
  const baseCaseMonths = baseCaseFfDatePeriod?.yearsMonthsDays.months;

  let refLine: RechartsReferenceLineParameters | null = null;
  if (baseCaseAge) {
    refLine = {
      text: "Financial freedom",
      xAxisValue: baseCaseAge,
      color: opulColors.baseCaseColor,
    };
    results.projections.referenceLines = [refLine];
  }

  return (
    <>
      {/* <Typography>
        Todo: 
        <br /> - get working for already financially free  


        <br /> - sign up on blog post to automatically use data already input
        <br /> - sign up to re-direct to dashboard
        <br /> - undo hack to add reference line to results after API
        <br /> - career and expenditures to have useful router links
        <br /> - career and expenditure projections to operate the same -> be able to plot complex projections but also simple flat comparions / sliders around a selected profile?
      </Typography> */}

      <Typography>Your financial freedom age is:</Typography>
      <Box display="flex" alignItems="center">
        <Typography variant="h1" color={opulColors.opulDarkBlue}>
          {baseCaseYears}
        </Typography>
        <Typography variant="h2">
          &nbsp;{baseCaseYears === 1 ? "year" : "years"}&nbsp;
        </Typography>
        <Typography variant="h1" color={opulColors.opulDarkBlue}>
          {baseCaseMonths}
        </Typography>
        <Typography variant="h2">
          &nbsp;{baseCaseMonths === 1 ? "month" : "months"}
        </Typography>
      </Box>
      <Typography>
        See your financial freedom journey visualised here:
      </Typography>
      <br />

      <Paper
        elevation={0}
        sx={{
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          bgcolor: "#f5f5f5",
          height: "300px",
        }}
      >
        <RechartsChartMultipleGeneric inputs={results.projections} />
      </Paper>
      <br />
      <MyTitle>Sign up</MyTitle>
      <Typography>
        Sign up for more advanced features such as career path comparisons,
        effect of one-off expenses, different investment returns and leaving
        behind an inheritance.
      </Typography>
      <br />
      {userMode !== UserMode.Persistent && <ButtonSignUp />}
      {userMode === UserMode.Persistent && (
        <>
          {/* <Button
            color="inherit"
            variant="contained"
            onClick={() => navigate("/" + pageIds.dashboard)}
            size="large"
            sx={{
              textTransform: "none",
              backgroundColor: opulColors.opulDarkBlue,
              color: "common.white",
              // height: "56px",
              fontSize: 16,
              py: 1,
              px: 5,
              // borderRadius: 0,
            }}
          >
            <Typography variant="h6">Go to dashboard</Typography>
          </Button>
          &nbsp; &nbsp; &nbsp; */}
          <Button
            color="inherit"
            variant="contained"
            onClick={() => navigate("/" + pageIds.projections)}
            size="large"
            sx={{
              textTransform: "none",
              backgroundColor: opulColors.opulDarkGreen,
              color: "common.white",
              // height: "56px",
              fontSize: 16,
              py: 1,
              px: 5,
              // borderRadius: 0,
            }}
          >
            {/* SPIKE refactor to remove the nesting here */}
            <Typography variant="h6">Go to Projections</Typography>
          </Button>
        </>
      )}

      <br />
      <br />
      <MyTitle>Model notes</MyTitle>
      <Typography>
        This mini tool aims to be as easy to use as possible, and as a result is
        also simplistic. It assumes a constant investment return of 5% per year
        above inflation. It doesn't account for state pension, earnings &
        expenditure changing over time, variability of returns and home
        ownership among other things. More accurate and complex modelling is
        available for free on the projections page after signing up.
        <br />
        <br />
        Inflation is taken into account by an assumption that earnings and
        spending are increasing in line with inflation, and that investment
        returns are above inflation.
      </Typography>
    </>
  );
};
