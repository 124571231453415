import { Dispatch, SetStateAction } from "react";
import { UserMode } from "../Context/UserModeContext";
import { BaseCaseModelResults } from "../../Types/results";
import { AllUserInputs } from "../../Types/inputs";

export function handleErrors(response: Response) {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

export const handleApiError = (
  error: any,
  setResults: Dispatch<SetStateAction<any>>
) => {
  setResults(null);
};

export const apiRequestThatSetsResults = (
  dto: any,
  url: string,
  sessionToken: string,
  setResults: Dispatch<SetStateAction<BaseCaseModelResults | null>>
) => {
  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      "X-ZUMO-AUTH": sessionToken,
    },
    body: JSON.stringify(dto),
  })
    .then(handleErrors)
    .then((response) => response.json())
    .then((data) => {
      setResults(data);
    })
    .catch((error) => {
      handleApiError(error, setResults);
    });
};

export const postApiRequestThatSetsModelState = (
  dto: any,
  url: string,
  token: string,
  setResults: Dispatch<SetStateAction<BaseCaseModelResults | null>>,
  setAllUserInputs: Dispatch<SetStateAction<AllUserInputs | null>>
) => {
  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      "X-ZUMO-AUTH": token,
    },
    body: JSON.stringify(dto),
  })
    .then(handleErrors)
    .then((response) => response.json())
    .then((data) => {
      setResults(data.results);
      setAllUserInputs(data.allUserInputs);
    })
    .catch((error) => {
      handleApiError(error, setResults);
    });
};

export const getApiRequestThatSetsModelState = (
  url: string,
  token: string,
  setResults: Dispatch<SetStateAction<BaseCaseModelResults | null>>,
  setAllUserInputs: Dispatch<SetStateAction<AllUserInputs | null>>
) => {
  fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      "X-ZUMO-AUTH": token,
    },
  })
    .then(handleErrors)
    .then((response) => response.json())
    .then((data) => {
      setResults(data.results);
      setAllUserInputs(data.allUserInputs);
    })
    .catch((error) => {
      handleApiError(error, setResults);
    });
};

export const getApiRequest = async (
  url: string,
  token: string,
  userMode: UserMode = UserMode.None
) => {
  const data = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      "X-ZUMO-AUTH": token,
      "X-User-Mode": userMode.toString(),
    },
  })
    .then(handleErrors)
    .then((response) => response.json());
  return data;
};

export const postApiRequest = async (
  url: string,
  token: string,
  body: any,
  userMode: UserMode = UserMode.None
) => {
  const data = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
      "X-ZUMO-AUTH": token,
      "X-User-Mode": userMode.toString(),
    },
    body: JSON.stringify(body),
  })
    .then(handleErrors)
    .then((response) => response.json());
  return data;
};
