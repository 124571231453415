export type Dictionary<TValue> = {
  [key: string]: TValue;
};

export type NumberKeyValuePair = KeyValuePair<number, number>;

export type StringKeyNumberValue = KeyValuePair<string, number>;

export type KeyValuePair<TKey, TValue> = {
  key: TKey;
  value: TValue;
};

export type Result<T> = {
  isSuccess: boolean;
  data: T | null;
  error: ErrorDetails | null;
};

export type ErrorDetails = {
  message: string;
};

export interface INameAndId {
  id: number;
  name: string;
}

export type DateOnly = { year: number; month: number; day: number };

export function equalsDateOnly(date1: DateOnly, date2: DateOnly): boolean {
  return (
    date1.year === date2.year &&
    date1.month === date2.month &&
    date1.day === date2.day
  );
}

export type DailyNumber = { startDate: DateOnly; data: number[] };

export type YearsMonthsDays = {
  years: number;
  months: number;
  days: number;
};
