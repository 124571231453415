import { ChartSliderTableData } from "../../../Components/Blog/BlogPieces/2_InteractiveIntroductionToFF/ProjectionCharts/ChartSliderTable";
import { ProjectionsWithCaseInputsProps } from "../../../Components/Blog/BlogPieces/2_InteractiveIntroductionToFF/ProjectionCharts/ProjectionsWithCaseInputs";
import { ProjectionScenariosRequest } from "../../../Types/requests";
import { SandboxProjectionUrls } from "../../Constants/Urls";
import { ISandboxProjectionApis } from "../../Context/ApiContext";
import { postApiRequest } from "../genericApiFunctions";

export const getSandboxProjectionRequests = (
  urls: SandboxProjectionUrls
): ISandboxProjectionApis => {
  return {
    blank: async (request: ProjectionScenariosRequest) => {
      const url = urls.blank;
      const response = await postApiRequest(url, "", request);
      return response;
    },
    scenarios: async (request: ProjectionScenariosRequest) => {
      const url = urls.scenarios;
      const response: ProjectionsWithCaseInputsProps = await postApiRequest(
        url,
        "",
        request
      );
      return response;
    },
    sliderScenarios: async (request: ProjectionScenariosRequest) => {
      const url = urls.sliderScenarios;
      const response: ChartSliderTableData = await postApiRequest(
        url,
        "",
        request
      );
      return response;
    },
  };
};
