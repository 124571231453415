import React from "react";
import { useApis } from "../../../Common/Context/ApiContext";
import { CareerDefinitionForm, CareerDefn } from "./CareerDefinitionForm";

type Props = {
  triggerRerender: () => void;
  setIsCreateVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

export const CreateCareerDefinitionForm: React.FC<Props> = ({
  triggerRerender,
  setIsCreateVisible,
}) => {
  const saveCareerDefinitionRequest = useApis().careerDefinitionApis.create;
  const handleCreateCareerDefinitionFormSubmit = async (
    careerDefinition: CareerDefn
  ) => {
    await saveCareerDefinitionRequest(careerDefinition);
    triggerRerender();
    setIsCreateVisible(false);
  };

  return (
    <CareerDefinitionForm
      handleFormSubmit={handleCreateCareerDefinitionFormSubmit}
    />
  );
};
