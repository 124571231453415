import { Box, Link, SelectChangeEvent, Typography } from "@mui/material";
import React from "react";
import {
  CurrencySelector,
  CurrencySymbol,
} from "../../../Generic/CurrencySelector";
import { Quotation } from "../../Quotation";
import { InlineItalic } from "../../../Generic/InlineItalic";
import { examplePersonSetup } from "./ExamplePersonGeneralInputs";
import { StandardProjectionWithCaseInput } from "./ProjectionCharts/StandardProjectionWithCaseInput";
import { BlogSubTitle } from "../BlogSubTitle";
import { MyTooltip } from "../../../Generic/MyTooltip";
import { WithSavingProjectionSlider } from "./ProjectionCharts/WithSavingProjectionSlider";
import { WithInvestingProjectionSlider } from "./ProjectionCharts/WithInvestingProjectionSlider";
import { tooltipNumberFormatter } from "../../../../Common/formattingFunctions";
import { InlineBold } from "../../../Generic/InlineBold";
import { ButtonSignUp } from "../../../Generic/ButtonSignUp";

type Props = {};

export const InteractiveIntroductionToFFArticleText: React.FC<Props> = () => {
  const [currencySymbol, setCurrencySymbol] = React.useState<CurrencySymbol>(
    CurrencySymbol.Default
  );
  const [examplePersonName, setExamplePersonName] = React.useState<string>(
    examplePersonSetup.name
  );
  const currencyString = currencySymbol.toString();
  const handleCurrencyChange = (event: SelectChangeEvent) => {
    setCurrencySymbol(event.target.value as CurrencySymbol);
  };

  const getCurrencyAmount = (value: number) => {
    return currencyString + tooltipNumberFormatter(value);
  };

  return (
    <>
      <Typography
        gutterBottom
        sx={{ fontStyle: "italic", display: { xs: "block", sm: "none" } }}
      >
        If you are on mobile, this article is best viewed on "Desktop site" mode
        due to the interactive visualisations.
      </Typography>
      <br />
      <Typography gutterBottom>
        A guide on <b>what</b> financial freedom is, and <b>how</b> you can
        reach it, explained in an interactive and visual way.
      </Typography>
      <br />
      <BlogSubTitle titleText={"What is financial freedom?"} />
      <Quotation>
        <Typography gutterBottom>
          <b>Financial freedom</b> - A state of freedom where you can spend your
          time as you wish, free from the need to work for money. Your time is
          all yours.
        </Typography>
      </Quotation>
      <Typography>
        When you’re financially free, work becomes a choice. This allows you to
        engage in work on your terms. This could be work you are passionate
        about with an organisation aligned with your values. Or part-time work
        on a schedule that suits you.
      </Typography>
      <br />
      <Typography>
        Alternatively, you could choose not to work at all - enjoying hobbies,
        spending quality time with loved ones, travelling... you are free to
        enjoy life as you wish.
      </Typography>
      {/* <br />
  <Typography gutterBottom>
    So financial freedom is about having that freedom to choose how you
    spend all of your time, and whether and how you work.
  </Typography> */}
      <br />
      <br />
      <BlogSubTitle titleText={"How is financial freedom reached?"} />
      <Quotation>
        <Typography gutterBottom sx={{ display: "inline" }}>
          <b>Financial freedom is reached when</b> you have enough wealth to
          support your lifetime expenditure without having to work. You can
          think of this as "living off your investments".
        </Typography>
      </Quotation>
      <Typography>
        In order to be free financially, you need{" "}
        <InlineItalic>
          "enough wealth to support your lifetime expenditure"
        </InlineItalic>{" "}
        . This is simple enough to say, and as you'll find out below, simple to
        do with only 2 key steps. However, <InlineItalic>simple</InlineItalic>{" "}
        does not mean <InlineItalic>quick</InlineItalic>, and it can take years
        of consistency to achieve. The only requirement is being able to{" "}
        <MyTooltip
          tooltipText={
            "I appreciate this is not possible for everyone, especially in the current economic climate. I hope that we can evolve society to a point where financial freedom is an attainable goal for all."
          }
          text={"spend less than you earn"}
        />
        .
        <br />
        <br />
        So financial freedom is reached by building wealth. How do you build
        wealth? There are just 2 key steps to build wealth, saving and
        investing.
      </Typography>
      <br />
      <br />
      <BlogSubTitle titleText={"The 2 key steps to build wealth"} />
      <Typography>These are:</Typography>
      <ul>
        {/* <li>Earn</li> */}
        <li>Save</li>
        <li>Invest</li>
        {/* <li>Leverage</li> */}
      </ul>
      <Typography>
        <InlineItalic>Save</InlineItalic> is saving a proportion of what you
        earn, while <InlineItalic>invest</InlineItalic> means investing those
        savings, so that they're growing above inflation. Pretty simple, right?
        {/* And{" "}
        <InlineItalic>leverage</InlineItalic> is to use borrowing to enable
        higher returns, typically through taking out a mortgage. */}
        <br />
        <br />
        Let's see how these 2 simple steps empower building wealth and reaching
        financial freedom.
      </Typography>
      <br />
      <br />
      <BlogSubTitle titleText={"Meet " + examplePersonName} />
      {/* <Typography>
        and living in the developed world. This gives her a remaining lifespan
        and healthspan of{" "}
        <MyTooltip
          tooltipText={
            "This is a population average, unadjusted for gender or lifestyle"
          }
          text={"around"}
        />{" "}
        59 and 43 years. Lifespan is equivalent to the concept of{" "}
        <InlineItalic>life expectancy</InlineItalic>. Similarly, healthspan is
        related to <InlineItalic>health</InlineItalic> expectancy. This is
        defined by the World Health Organisation, who publish statistics on the
        ages at which we are expected to move from a healthy state to a state of
        living with disability. Sorry for the morbid nature of these statistics,
        but estimated time until disability and death are useful life markers
        for us to consider for {examplePersonName}.
      </Typography> */}
      {/* <br />
      <BlankChart />
      <br /> */}
      <Typography>
        {examplePersonName} is age {examplePersonSetup.age}, earning{" "}
        {getCurrencyAmount(examplePersonSetup.earnings)}, spending{" "}
        {getCurrencyAmount(examplePersonSetup.expenditure)} a year, and has{" "}
        {getCurrencyAmount(examplePersonSetup.savings)} in savings. In what
        currency? Choose for yourself:{" "}
        <CurrencySelector
          currencySymbol={currencySymbol}
          handleCurrencyChange={handleCurrencyChange}
        />
        <br />
        <br />
        Let's see how {examplePersonName}'s financial situation will unfold over
        her lifetime:
      </Typography>
      <br />
      <StandardProjectionWithCaseInput />
      <br />
      <Typography>
        This is a pretty boring chart. Because {examplePersonName} is not saving
        or investing, her net worth remains flat over her lifetime.{" "}
        {examplePersonName}{" "}
        <InlineBold>never reaches financial freedom</InlineBold> and has to
        continue working throughout her lifetime. The model ends at age 100.
        <br />
        <br />
        Did you notice lifespan and healthspan? These correspond to life
        expectancy and health expectancy as defined by the World Health
        Organisation, and are useful life markers to consider.
        <br />
        <br />
        Let's see how saving and investing empower {examplePersonName} to reach
        financial freedom.
        {/* Some of the ways it is unrealistic (there are many) are
        that {examplePersonName}'s expenditure and earnings remain unchanging
        over her lifetime, and there is no state pension in old age. */}
        {/* This model will be expanded upon throughout the article to show how the
        4 steps of the financial freedom blueprint bring about financial
        freedom. */}
      </Typography>
      <br />
      <br />
      <BlogSubTitle
        titleText={"Saving and investing get you to financial freedom"}
      />
      <Typography>
        Let's see how saving and then investing change {examplePersonName}'s
        lifetime finances to reach financial freedom.
      </Typography>
      <br />
      {/* <Typography variant="h6" gutterBottom>
        Earn
      </Typography>
      <Typography>
        We've started assuming that {examplePersonName} is already earning. If
        you're not currently earning, step 1 is securing an income.
      </Typography>
      <br />
      <br /> */}
      <Typography variant="h6" gutterBottom>
        Saving
      </Typography>
      <Typography>
        What is the effect on {examplePersonName}'s trajectory if she saves some
        of her income? If she found room in her budget to reduce her expenses,
        how would her lifetime financial picture change and at what age would
        she reach financial freedom? Use the slider to play around with the
        model below to find out. The slider represents {examplePersonName}'s{" "}
        <InlineItalic>expenditure</InlineItalic> - lower expenditure means more
        savings.
      </Typography>
      <br />
      <WithSavingProjectionSlider />
      <br />
      <Typography>
        Saving means that {examplePersonName} is accumulating wealth while she
        is working. You can see {examplePersonName}'s net worth increasing over
        time when she is saving. {examplePersonName} is able to reach financial
        freedom when she saves enough - visually this is the high point on the
        curve.
        <br />
        <br />
        {examplePersonName} reaches financial freedom at age 81 (18.8 years
        earlier), if she saves {getCurrencyAmount(5000)} a year - a quarter of
        her income.
      </Typography>
      <br />
      <br />
      <Typography variant="h6" gutterBottom>
        Invest
      </Typography>
      <Typography>
        A really important step to reach financial freedom is investing those
        savings. Once {examplePersonName} starts investing her savings, her
        lifetime financial picture improves drastically. Move the slider below
        to see how investment returns impact {examplePersonName}'s lifetime
        finances.
      </Typography>
      <br />
      <WithInvestingProjectionSlider />
      <br />
      <Typography>
        Investing has a powerful impact over the long-term. Investing at 5% cuts{" "}
        {examplePersonName}'s financial freedom age from 81 down to 46, a
        massive difference of <InlineBold>35 years</InlineBold>!
        {/* This also gives{" "}
        {examplePersonName}{" "}
        <InlineBold color={opulColors.opulDarkGreen}>
          22 years of healthspan
        </InlineBold>{" "}
        where she is predicted to be both healthy and financially free. */}
        <br />
        <br />
        Around 5% is the annualised long-term return above inflation for global
        equities (based on the{" "}
        <Link
          // color="#ffffff"
          href="https://www.credit-suisse.com/media/assets/corporate/docs/about-us/research/publications/credit-suisse-global-investment-returns-yearbook-2022-summary-edition.pdf"
        >
          Credit Suisse Yearbook 2022
        </Link>
        , which uses 120 years of data).
      </Typography>
      <br />
      <br />
      {/* <Typography variant="h6" gutterBottom>
        Leverage
      </Typography>
      <Typography>
        Leverage is also known as borrowing. Most people achieve this by taking
        out a mortgage - where an individual borrows money from a bank and uses
        it to purchase a house. Leverage can be used to amplify investment
        returns by borrowing money at a low rate and investing in an asset which
        is returning a higher rate. Let's see the effect of {examplePersonName}{" "}
        taking out a {getCurrencyAmount(borrowingScenario.amount)} mortgage for{" "}
        {borrowingScenario.yearsLength} years at{" "}
        {borrowingScenario.borrowingRate}%, starting in{" "}
        {borrowingScenario.yearsUntil} years time.
      </Typography>
      <br />
      <WithLeverage borrowingScenario={borrowingScenario} />
      <br />
      <Typography>
        We can see that using leverage has accelerated {examplePersonName}'s
        financial freedom by another 6 years, down to age 40. This means an
        extra{" "}
        <InlineBold color={opulColors.opulDarkGreen}>
          15% of her healthspan
        </InlineBold>{" "}
        is spent being financially free.
        <br />
        <br />
        While leverage amplifies investment returns, bear in mind that it
        amplifies investment losses too.
      </Typography> */}
      {/* <br />
      <br /> */}
      <BlogSubTitle titleText={"Summary"} />
      <Typography>
        Taking the steps of saving and investing, {examplePersonName} has set a
        course to build wealth and has improved her projected time to reach
        financial freedom from{" "}
        <InlineItalic>not reaching it by age 100</InlineItalic> to{" "}
        <InlineBold>reaching it in just 21 years time</InlineBold>!
      </Typography>
      <br />
      <br />
      <BlogSubTitle
        titleText={"Can I personalise this modelling for my situation?"}
      />
      <Typography>
        You absolutely can! Natural questions you might be asking are{" "}
        <InlineItalic>"How many years will it take me?"</InlineItalic> and{" "}
        <InlineItalic>
          "What is the effect of changing my spending habits?"
        </InlineItalic>{" "}
        . The Opul model provides personalised answers to these questions, and
        helps you manage your journey over time. You can sign up here:
      </Typography>
      <br />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ButtonSignUp />
      </Box>
      <br />
      <br />
      <BlogSubTitle titleText={"Model notes"} />
      <Typography>
        The model shown in this article is purposely kept as simple as possible
        while being sufficient to demonstrate the fundamentals of financial
        freedom. There are many things it hasn't taken into account: changing
        expenditure or earnings over time, cost of childcare, state pension,
        variability of returns... etc. More accurate and complex modelling is
        available by signing up.
        <br />
        <br />
        Inflation is taken into account by an assumption that earnings and
        spending are moving in line with inflation, and that investment returns
        are above inflation.
        {/* [still to write:]
        - Next article - investing
        - Risk - to be followed up with investing introduction article 
        */}
      </Typography>
      <br />
    </>
  );
};
