import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

type Props = {
  animate?: boolean;
  animationDuration?: number;
  text?: string | null;
  justifyContent?:
    | "flex-start"
    | "center"
    | "flex-end"
    | "space-between"
    | "space-around"
    | "space-evenly";
};

export const AnimatedText: React.FC<Props> = ({
  animate = true,
  text,
  justifyContent,
  animationDuration = 300,
}) => {
  // Use a state to trigger the animation
  const [triggerAnimation, setTriggerAnimation] = useState(false);

  // Trigger the animation by toggling the triggerAnimation state
  // whenever the number prop changes
  useEffect(() => {
    if (animate) {
      setTriggerAnimation(true);
      const timer = setTimeout(() => {
        setTriggerAnimation(false);
      }, animationDuration);

      return () => clearTimeout(timer);
    }
  }, [text, animate, animationDuration]);

  return (
    <Grid
      container
      spacing={0}
      sx={{
        ...(justifyContent && { justifyContent }),
        px: 0.5,
      }}
    >
      <Typography
        sx={{
          fontSize: "0.875rem",
          // Enlarge the number momentarily when the triggerAnimation state is true
          transform: triggerAnimation && animate ? "scale(1.5)" : "scale(1)",
          transition: animate
            ? `transform ${animationDuration / 2}ms ease`
            : undefined, // Smooth transition for the transform property
        }}
      >
        {text}
      </Typography>
    </Grid>
  );
};
