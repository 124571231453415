import { Container, Box } from "@mui/material";
import React from "react";

type Props = {
  children: React.ReactNode;
  pxForMediumScreen?: number;
};

export const MediumWidthContainer: React.FC<Props> = ({
  children,
  pxForMediumScreen = 10,
}) => {
  return (
    <Container maxWidth="md" sx={{ pb: 2, pt: 4 }}>
      <Box
        sx={{
          px: { xs: 0, sm: 0, md: pxForMediumScreen }, // Adjust padding for different screen sizes
        }}
      >
        {children}
      </Box>
    </Container>
  );
};
