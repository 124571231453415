import React from "react";
import { useApis } from "../../../Common/Context/ApiContext";
import { CareerDefinitionForm, CareerDefn } from "./CareerDefinitionForm";

type Props = {
  initialValues: CareerDefn;
  triggerRerender: () => void;
  id: number;
  setExpanded: React.Dispatch<React.SetStateAction<string | false>>;
};

export const UpdateCareerDefinitionForm: React.FC<Props> = ({
  initialValues,
  triggerRerender,
  id,
  setExpanded,
}) => {
  const updateCareerDefinitionRequest = useApis().careerDefinitionApis.update;
  const updateCurrentCareerDefinitionRequest =
    useApis().careerDefinitionApis.updateCurrent;
  const deleteCareerDefinitionRequest = useApis().careerDefinitionApis.delete;

  const handleUpdateCareerDefinitionFormSubmit = async (
    careerDefinition: CareerDefn
  ) => {
    await updateCareerDefinitionRequest({ ...careerDefinition, id: id });
    triggerRerender();
    setExpanded(false);
  };

  const handleUpdateCurrentCareerDefinitionClick = async () => {
    await updateCurrentCareerDefinitionRequest(id);
    triggerRerender();
  };

  const handleDeleteClick = async () => {
    await deleteCareerDefinitionRequest(id);
    triggerRerender();
    setExpanded(false);
  };

  return (
    <CareerDefinitionForm
      initialValues={initialValues}
      handleFormSubmit={handleUpdateCareerDefinitionFormSubmit}
      extraButtons={{
        handleDeleteClick: handleDeleteClick,
        handleMakeDefaultClick: handleUpdateCurrentCareerDefinitionClick,
      }}
    />
  );
};
