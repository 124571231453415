import { Typography } from "@mui/material";
import React from "react";

type Props = {
  variant:
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "subtitle1"
    | "subtitle2"
    | "body1"
    | "body2"
    | "caption"
    | "button"
    | "overline"
    | "inherit"
    | undefined;
  text: string | null | undefined;
  color?: string;
  isItalic?: boolean;
};

export const BoldColouredTypography: React.FC<Props> = ({
  variant,
  text,
  color,
  isItalic,
}) => {
  return (
    <Typography
      variant={variant}
      display="inline"
      sx={{
        fontWeight: "bold",
        color: { color },
        fontStyle: isItalic ? "italic" : undefined,
      }}
    >
      {text}
    </Typography>
  );
};

BoldColouredTypography.defaultProps = {
  color: "#000000",
  isItalic: false,
};
