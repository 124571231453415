import React from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { FF01Results } from "../../../Types/results";

type Props = {
  fF01s: FF01Results;
};

const renderFF01TableCell = (text: string, tooltip: JSX.Element) => {
  return (
    <Grid container spacing={0}>
      <Grid xs={8}>
        {text}{" "}
        <Tooltip title={tooltip}>
          <InfoOutlinedIcon fontSize="small" />
        </Tooltip>
      </Grid>
      <Grid xs={4}></Grid>
    </Grid>
  );
};

export const FF01s: React.FC<Props> = ({ fF01s }) => {
  return (
    <Box sx={{ pt: 1 }}>
      <Typography>
        Sensitivity metrics of your financial freedom age to one-off and
        repeated expenditures:
      </Typography>
      <TableContainer component={Paper} elevation={0} sx={{ pt: 1 }}>
        <Table size="small">
          <TableBody>
            <TableRow
              key={1}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {renderFF01TableCell(
                  "FF01",
                  <div>
                    Cost in minutes of financial freedom of: <br />a one-off
                    unit of expenditure above your budget
                  </div>
                )}
              </TableCell>
              <TableCell>{fF01s.fF01Minutes} minutes</TableCell>
            </TableRow>
            <TableRow
              key={2}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {renderFF01TableCell(
                  "FF01D",
                  <div>
                    Cost in days of financial freedom of: <br />a unit of
                    expenditure paid daily forever
                  </div>
                )}
              </TableCell>
              <TableCell>{fF01s.fF01DDays} days</TableCell>
            </TableRow>
            <TableRow
              key={3}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {renderFF01TableCell(
                  "FF01W",
                  <div>
                    Cost in days of financial freedom of: <br />a unit of
                    expenditure paid weekly forever
                  </div>
                )}
              </TableCell>
              <TableCell>{fF01s.fF01WDays} days</TableCell>
            </TableRow>
            <TableRow
              key={4}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {renderFF01TableCell(
                  "FF01M",
                  <div>
                    Cost in days of financial freedom of: <br />a unit of
                    expenditure paid monthly forever
                  </div>
                )}
              </TableCell>
              <TableCell>{fF01s.fF01MDays} days</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {/* <Typography>FF01: {fF01s.fF01Minutes} minutes</Typography>
      <Typography>FF01D: {fF01s.fF01DDays} days</Typography>
      <Typography>FF01W: {fF01s.fF01WDays} days</Typography>
      <Typography>FF01M: {fF01s.fF01MDays} days</Typography>
      <br /> */}
      <br />
      <Typography>
        These are useful figures when evaluating expenditure against your
        financial freedom goal.
      </Typography>
    </Box>
  );
};
