import { DateOnly } from "../Types/myPrimitives";

export function convertStrToNumNullable(str: string | null | undefined) {
  if (str === null || str === undefined) return null;
  return convertStrToNum(str);
}

export function convertStrToNum(str: string) {
  if (str === "") {
    return null;
  }
  const num = Number(str);
  return isNaN(num) ? null : num;
}

export function stringToDateOnly(dateStr: string): DateOnly {
  const parts = dateStr.split("-");

  if (parts.length !== 3) {
    throw new Error("Invalid date format. Expected format: yyyy-mm-dd");
  }

  const year = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10);
  const day = parseInt(parts[2], 10);

  if (isNaN(year) || isNaN(month) || isNaN(day)) {
    throw new Error("Invalid date components in the string");
  }

  return { year, month, day };
}

export function convertToDateOnly(date: Date): DateOnly {
  return {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate(),
  };
}

//assumes date is coming from a date picker which gives the chosen date as local time date with no hours
export const CreateAbstractUTCDate = (date: Date) => {
  let newDate = new Date(date);
  // move the time away from midnight, opposite direction of utc offset
  const offset = -newDate.getTimezoneOffset();
  // use trunc() because there could be negative offsets, too.
  newDate.setHours(Math.trunc(offset / 60), offset % 60);
  return newDate;
};
