import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { Article } from "./Article";
import { blogs } from "./Blogs";
import { Box, Container } from "@mui/material";
import BlogData from "./BlogData";
import { draftBlogs } from "./DraftBlogs";
import { MediumWidthContainer } from "../Generic/MediumWidthContainer";

interface BlogDetailProps {}

export const BlogDetail: React.FC<BlogDetailProps> = () => {
  const location = useLocation();
  const { id } = useParams<{ id: string }>();
  if (!id) return <p>Blog id not found.</p>;

  const isDraftRoute = /\/draft\/\d+$/.test(location.pathname);

  let blog: BlogData | undefined;
  if (isDraftRoute) {
    blog = draftBlogs.find((b) => b.id === parseInt(id));
  } else {
    blog = blogs.find((b) => b.id === parseInt(id));
  }

  if (!blog) return <p>Blog not found!</p>;

  return (
    <MediumWidthContainer>
      <Article title={blog.title} content={blog.content} />
    </MediumWidthContainer>
  );
};

export default BlogDetail;
