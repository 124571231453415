import React from "react";
import { Typography } from "@mui/material";
import { BoldColouredTypography } from "../../Generic/BoldColouredTypography";
import { opulColors } from "../../../Common/Constants/Constants";
import { LifeExpectancyResults } from "../../../Types/results";

type Props = {
  lifeExpectancyResults: LifeExpectancyResults;
  baseCaseDaysUntilFF: number | null | undefined;
};

export const LifeExpectancyCard: React.FC<Props> = ({
  lifeExpectancyResults,
  baseCaseDaysUntilFF,
}) => {
  function GetHealthyAndFinanciallyFreeDays() {
    if (baseCaseDaysUntilFF) {
      return (
        lifeExpectancyResults.healthyLifeExpectancyDaysLeft -
        baseCaseDaysUntilFF
      ).toString();
    } else {
      return "";
    }
  }

  return (
    <div>
      <Typography display="inline">You are financially free in </Typography>
      <BoldColouredTypography
        variant="h5"
        text={baseCaseDaysUntilFF?.toString()}
        color={opulColors.baseCaseColor}
      />{" "}
      <Typography display="inline">
        days. <br />
        Where you will have{" "}
      </Typography>
      <BoldColouredTypography
        variant="h5"
        text={GetHealthyAndFinanciallyFreeDays()}
        color={opulColors.opulDarkBlue}
      />{" "}
      <Typography display="inline">
        healthy and financially free days to enjoy.
      </Typography>
      <br />
      <br />
      <Typography display="inline">Statistically, you have </Typography>
      <BoldColouredTypography
        variant="h5"
        text={lifeExpectancyResults.lifeExpectancyDaysLeft.toString()}
        color="#2a8dad"
      />{" "}
      <Typography display="inline">
        days left. <br />
        And{" "}
      </Typography>
      <BoldColouredTypography
        variant="h5"
        text={lifeExpectancyResults.healthyLifeExpectancyDaysLeft.toString()}
        color={opulColors.opulLightGreen}
      />{" "}
      <Typography display="inline">healthy days left.</Typography>
      {/* Your life expectancy is age{" "}
        <text className="bold">
          {lifeExpectancyResults.lifeExpectancyAge}
        </text>{" "}
        (in{" "}
        <text className="bold">
          {lifeExpectancyResults.lifeExpectancyYearsLeft}
        </text>{" "}
        years).
        <br />
        Your healthy life expectancy is age{" "}
        <text className="bold">
          {lifeExpectancyResults.healthyLifeExpectancyAge}
        </text>{" "}
        (in{" "}
        <text className="bold">
          {lifeExpectancyResults.healthyLifeExpectancyYearsLeft}
        </text>{" "}
        years). This is the number of years you are expected to be in good health. */}
      <br />
      <br />
      <Typography sx={{ fontStyle: "italic" }} display="inline">
        Life expectancies calculated from{" "}
        <a
          href={
            "https://www.who.int/data/gho/data/indicators/indicator-details/GHO/healthy-life-expectancy-(hale)-at-birth-(years)"
          }
        >
          World Health Organisation
        </a>{" "}
        data. Healthy life expectancy is an indication of the age at which
        people on average fall into a state of ill-health.
      </Typography>
    </div>
  );
};

export default LifeExpectancyCard;
