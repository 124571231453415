import { MyTitle } from "../../Generic/Title";
import { Grid, GridSize, Paper } from "@mui/material";
import React from "react";

type Props = {
  childComponent: React.ReactNode;
  titleText: string;
  lgBreakpoint: GridSize;
  xlBreakpoint: GridSize;
  height: number;
};

export const DashboardPaper: React.FC<Props> = ({
  childComponent,
  titleText,
  lgBreakpoint,
  xlBreakpoint,
  height,
}) => {
  return (
    <Grid item xs={12} md={8} lg={lgBreakpoint} xl={xlBreakpoint}>
      <Paper
        sx={{
          p: 2,
          minHeight: { height },
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <MyTitle>{titleText}</MyTitle>
        {childComponent}
      </Paper>
    </Grid>
  );
};
