import React from "react";
import { ProjectionsFromCaseInputs } from "./ProjectionsFromCaseInputs";
import { examplePersonGeneralInputs } from "../ExamplePersonGeneralInputs";
import { RechartsGenericMultipleInputs } from "../../../../../Types/recharts";

type Props = {};

export const StandardProjectionWithCaseInput: React.FC<Props> = () => {
  return (
    <ProjectionsFromCaseInputs
      projectionScenariosRequest={{
        generalInputs: examplePersonGeneralInputs,
        expenditureScenario: null,
        realReturnScenario: null,
        borrowingScenario: null,
      }}
      updateResponse={(response: RechartsGenericMultipleInputs) => {
        return { ...response, yAxisDomain: [0, 50000] };
      }}
    />
  );
};
