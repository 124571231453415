import React from "react";
import { Box, Tab, Tabs } from "@mui/material/";
import { PortfolioAtDate } from "./Values/PortfolioAtDate";
import { PortfolionCalibrationRequest } from "../../../Types/requests";
import { a11yProps, TabPanel } from "../../Generic/TabPanel";
import { AssetInputs } from "../../../Types/assetTypes";
import { ManageAccounts } from "./Setup/ManageAccounts";
import { IAccountDefinitionApis } from "../../../Common/Context/ApiContext";

type Props = {
  assetInputs: AssetInputs | undefined;
  accountDefinitionApis: IAccountDefinitionApis;
  portfolioCalibrationRequest: (dto: PortfolionCalibrationRequest) => void;
};

export const PortfolioInputs: React.FC<Props> = ({
  assetInputs,
  accountDefinitionApis,
  portfolioCalibrationRequest,
}) => {
  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          justifyContent: "center",
        }}
      >
        <Tabs
          centered
          value={tabValue}
          onChange={handleTabChange}
          aria-label="basic tabs example"
        >
          <Tab label="Setup" {...a11yProps(0)} />
          <Tab label="Values" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={0}>
        <ManageAccounts
          accountDefinitions={assetInputs?.accountDefinitions}
          accountDefinitionApis={accountDefinitionApis}
        />
      </TabPanel>

      <TabPanel value={tabValue} index={1}>
        {assetInputs && (
          <PortfolioAtDate
            assetInputs={assetInputs}
            portfolioCalibrationRequest={portfolioCalibrationRequest}
          />
        )}
      </TabPanel>
    </>

    // <Container maxWidth={false}>
    //   <Grid container spacing={1} justifyContent="center" sx={{ py: 1 }}>
    //     <DashboardPaper
    //       childComponent={
    //         <PortfolioAtDate
    //           assetInputs={assetInputs}
    //           portfolioCalibrationRequest={portfolioCalibrationRequest}
    //         />
    //       }
    //       titleText="Add or edit portfolio calibration"
    //       lgBreakpoint={4}
    //       xlBreakpoint={4}
    //       height={650}
    //     />
    //     <DashboardPaper
    //       childComponent={
    //         <ManageAssets
    //           assetDefinitions={assetInputs?.assetDefinitions}
    //           assetDefinitionRequest={assetDefinitionRequest}
    //         />
    //       }
    //       titleText="Asset setup"
    //       lgBreakpoint={6}
    //       xlBreakpoint={6}
    //       height={650}
    //     />
    //     {/* <DashboardPaper
    //       childComponent={
    //         historyResults != null && (
    //           <DeleteNetWorthCalibrations
    //             netWorthCalibrations={historyResults.netWorthCalibrations}
    //             handleNetWorthDelete={handleNetWorthDelete}
    //           />
    //         )
    //       }
    //       titleText="Delete portfolio calibration"
    //       lgBreakpoint={3}
    //       xlBreakpoint={3}
    //       height={350}
    //     /> */}
    //     <DashboardPaper
    //       childComponent={
    //         historyResults &&
    //         assetInputs && (
    //           <StackedAreaChart
    //             historyResults={historyResults}
    //             assetDefinitions={assetInputs.assetDefinitions}
    //           />
    //         )
    //       }
    //       titleText="Detailed history"
    //       lgBreakpoint={5}
    //       xlBreakpoint={5}
    //       height={350}
    //     />
    //   </Grid>
    //   <br />
    // </Container>
  );
};
