import React from "react";
import { MyButton } from "../Header/MyButton";
import { SignUp } from "../Header/SignUp";
import { useAuth } from "../../Common/Context/AuthContext";

type Props = {};

export const ButtonSignUp: React.FC<Props> = () => {
  const { userSignUp } = useAuth();
  const [signUpOpen, setSignUpOpen] = React.useState(false);

  const handleClickSignUp = () => {
    setSignUpOpen(true);
  };

  const handleClose = () => {
    setSignUpOpen(false);
  };

  return (
    <>
      <MyButton buttonText="Sign up" onClick={handleClickSignUp} />
      <SignUp
        signUpOpen={signUpOpen}
        handleClose={handleClose}
        signUp={userSignUp}
      />
    </>
  );
};
