import React, { Dispatch, SetStateAction, useContext } from "react";
import { GeneralInputsComponent } from "./GeneralInputsComponent";
import { UrlContext } from "../../../Common/Context/UrlContext";
import { UserTokenContext } from "../../../Common/Context/UserTokenContext";
import { postApiRequestThatSetsModelState } from "../../../Common/Apis/genericApiFunctions";
import { GeneralInputsContext } from "../../../Common/Context/GeneralInputsContext";
import { BaseCaseModelResults } from "../../../Types/results";
import {
  AllUserInputs,
  PersistentUserGeneralInputs,
} from "../../../Types/inputs";
import { DateOnly } from "../../../Types/myPrimitives";
import { convertToDateOnly } from "../../../Common/functions";

type Props = {
  setResults: Dispatch<SetStateAction<BaseCaseModelResults | null>>;
  setAllUserInputs: Dispatch<SetStateAction<AllUserInputs | null>>;
};

export const GeneralInputsWrapper: React.FC<Props> = ({
  setResults,
  setAllUserInputs,
}) => {
  const urls = useContext(UrlContext);
  const token = useContext(UserTokenContext);
  const generalInputs = useContext(GeneralInputsContext);

  const handleGeneralInputsChangePersistent = (
    generalInputs: PersistentUserGeneralInputs
  ) => {
    const url = urls.updateInputsAndCalculate;
    const date = generalInputs.dateOfBirth;
    const dateOfBirth: DateOnly = convertToDateOnly(date);
    const dto = {
      ...generalInputs,
      dateOfBirth: dateOfBirth,
    };

    postApiRequestThatSetsModelState(
      dto,
      url,
      token,
      setResults,
      setAllUserInputs
    );
  };

  return (
    <GeneralInputsComponent
      generalInputs={generalInputs}
      handleGeneralInputsChange={handleGeneralInputsChangePersistent}
    />
  );
};
