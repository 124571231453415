import * as React from "react";
import { Grid, Container } from "@mui/material";
import LifeExpectancyCard from "./LifeExpectancyCard";
import { DashboardPaper } from "./DashboardPaper";

import { ExpenseComparator } from "./ExpenseComparator";
import { PortfolioPieChart } from "../Charts/PortfolioPieChart";
import { RechartsUnixSecondsStackedArea } from "../Charts/rechartsUnixSecondsStackedArea";
import { RechartsChartMultipleGeneric } from "../Charts/RechartsChartMultipleGeneric";
import { FF01s } from "./FF01s";
import { BaseCaseModelResults } from "../../../Types/results";
import { SavingsRateDefinition } from "../../../Types/inputs";
import {
  formatUnixTickAsy,
  tooltipNumberFormatter,
  formatUnixTickAsMMMyyyy,
  tooltipNumberFormatterStringToString,
} from "../../../Common/formattingFunctions";

type Props = {
  results: BaseCaseModelResults | null;
};

export const Dashboard: React.FC<Props> = ({ results }) => {
  const projections = results?.projections;
  const baseCaseResults = projections && projections.baseCaseProjection;

  return (
    <Container
      maxWidth="xl"
      sx={{
        py: 1,
      }}
    >
      <Grid container spacing={2}>
        <DashboardPaper
          childComponent={
            results &&
            results.totalNetworthWithBaseProjection && (
              <RechartsChartMultipleGeneric
                inputs={{
                  ...results.totalNetworthWithBaseProjection,
                  xAxisTickFormatter: formatUnixTickAsy,
                  yAxisTickFormatter: tooltipNumberFormatter,
                  showTooltip: true,
                  tooltipLabelFormatter: formatUnixTickAsMMMyyyy,
                  tooltipFormatter: tooltipNumberFormatterStringToString,
                  // showLegend: true,
                }}
              />
            )
          }
          titleText="Net-worth history and projection"
          lgBreakpoint={7}
          xlBreakpoint={7}
          height={450}
        />

        <DashboardPaper
          childComponent={
            results?.history && (
              <PortfolioPieChart
                assetClassValues={
                  results.history.latestDateResults.assetClassValues
                }
                accountValues={results.history.latestDateResults.accountValues}
              />
            )
          }
          titleText="Current portfolio"
          lgBreakpoint={5}
          xlBreakpoint={5}
          height={450}
        />
        <DashboardPaper
          childComponent={
            projections &&
            baseCaseResults &&
            baseCaseResults.daysUntilFinancialFreedom && (
              <LifeExpectancyCard
                lifeExpectancyResults={projections.lifeExpectancyResults}
                baseCaseDaysUntilFF={baseCaseResults?.daysUntilFinancialFreedom}
              />
            )
          }
          titleText="Timeline"
          lgBreakpoint={5}
          xlBreakpoint={5}
          height={360}
        />
        <DashboardPaper
          childComponent={
            results?.history && (
              <RechartsUnixSecondsStackedArea
                setup={results.history.accountHistory}
              />
            )
          }
          titleText="Portfolio history"
          lgBreakpoint={7}
          xlBreakpoint={7}
          height={360}
        />
        {/* <DashboardPaper
          childComponent={
            results?.projections && (
              <NetWorthProjectionChart
                currentSituationNetWorthProjection={
                  results.projections.financialFreedomResults
                    .financialFreedomCaseResults[baseCaseId].netWorthByMonthEnd
                }
                age={
                  results.projections.lifeExpectancyResults.ageYearsUnrounded
                }
              />
            )
          }
          titleText="Net worth projection"
          lgBreakpoint={7}
          xlBreakpoint={7}
          height={450}
        /> */}
        <DashboardPaper
          childComponent={
            projections && <FF01s fF01s={projections.fF01Results} />
          }
          titleText="FF01s"
          lgBreakpoint={4}
          xlBreakpoint={4}
          height={350}
        />
        <DashboardPaper
          childComponent={<ExpenseComparator />}
          titleText="Expense heuristic"
          lgBreakpoint={4}
          xlBreakpoint={4}
          height={350}
        />
        {/* <DashboardPaper
          childComponent={
            projections && (
              <BatnaResultHandler batnaResults={projections.batnaResults} />
            )
          }
          titleText="BATNA"
          lgBreakpoint={4}
          xlBreakpoint={4}
          height={350}
        /> */}
      </Grid>
    </Container>
  );
};
