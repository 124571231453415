import { Button } from "@mui/material";
import React from "react";

type Props = {
  buttonText: string;
  onClick: () => void;
};

export const MyButton: React.FC<Props> = (props) => {
  return (
    <Button
      onClick={props.onClick}
      sx={{
        textTransform: "none",
        backgroundColor: "common.white",
        // color: "common.white",
        py: 1,
        px: 7,
      }}
    >
      {props.buttonText}
    </Button>
  );
};
