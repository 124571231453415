import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from "@mui/material";
import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ReadAllExpenditureDefinitionsResponseDto } from "../../../Types/apiResponses";
import { MediumWidthContainer } from "../../Generic/MediumWidthContainer";
import { MyButton } from "../../Header/MyButton";
import { CreateExpenditureDefinitionForm } from "../Expenditure/CreateExpenditureDefinitionForm";
import { UpdateExpenditureDefinitionForm } from "../Expenditure/UpdateExpenditureDefinitionForm";

const createNewPanelKey = "panelNew";

type Props = {
  expenditureDefinitions: ReadAllExpenditureDefinitionsResponseDto;
  triggerRerender: () => void;
};

export const ExpenditureDefinitions: React.FC<Props> = ({
  expenditureDefinitions,
  triggerRerender,
}) => {
  const [isCreateVisible, setIsCreateVisible] = React.useState<boolean>(false);
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  function handleCreateNew(): void {
    setIsCreateVisible(true);
    setExpanded(createNewPanelKey);
  }

  return (
    <MediumWidthContainer pxForMediumScreen={0}>
      <Typography>
        Use the expenditure and one off costs by date to define different
        expenditure paths for comparison. Costs are for everything that's not
        career related.
      </Typography>
      <br />
      {expenditureDefinitions?.map((defn, index) => {
        return (
          <Accordion
            key={`panel${index}`}
            expanded={expanded === `panel${index + 1}`}
            onChange={handleAccordionChange(`panel${index + 1}`)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography sx={{ width: "33%", flexShrink: 0 }}>
                {defn.value.name}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <UpdateExpenditureDefinitionForm
                initialValues={defn.value}
                triggerRerender={triggerRerender}
                id={defn.key}
                setExpanded={setExpanded}
              />
            </AccordionDetails>
          </Accordion>
        );
      })}
      {isCreateVisible && (
        <Accordion
          key={createNewPanelKey}
          expanded={expanded === createNewPanelKey}
          onChange={handleAccordionChange(createNewPanelKey)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography sx={{ width: "33%", flexShrink: 0 }}>
              Create new expenditure definition
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CreateExpenditureDefinitionForm
              triggerRerender={triggerRerender}
              setIsCreateVisible={setIsCreateVisible}
            />
          </AccordionDetails>
        </Accordion>
      )}
      <br />
      <MyButton buttonText={"Create new"} onClick={handleCreateNew} />
    </MediumWidthContainer>
  );
};
