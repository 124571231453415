import {
  Grid,
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import {
  Formik,
  FieldArray,
  Field,
  FieldProps,
  Form,
  FormikErrors,
} from "formik";
import React from "react";
import { opulColors } from "../../../../Common/Constants/Constants";
import {
  AccountDefinition,
  AssetCalibrationValue,
  AssetInputs,
  IdValue,
} from "../../../../Types/assetTypes";
import * as yup from "yup";
import { TypographyAndTextField } from "../../TypographyAndTextField";
import {
  DateOnly,
  Dictionary,
  equalsDateOnly,
} from "../../../../Types/myPrimitives";
import { stringToDateOnly } from "../../../../Common/functions";

const getInitialAssets = (accountDefinitions: AccountDefinition[]) => {
  let formikAssetSetup: { id: number; value: number | string }[] = [];
  let formikAccountSetup: {
    id: number;
    value: number | string;
  }[] = [];
  let accountMapping: Dictionary<number> = {};
  let assetMapping: Dictionary<number> = {};
  let counter: number = 0;
  accountDefinitions.forEach((account, index) => {
    accountMapping[account.accountInfo.id] = index;
    formikAccountSetup.push({ id: account.accountInfo.id, value: "" });
    account.assetDefinitions &&
      account.assetDefinitions.forEach((asset, assetIndex) => {
        assetMapping[asset.nameAndId.id] = counter;
        formikAssetSetup.push({ id: asset.nameAndId.id, value: "" });
        counter++;
      });
  });

  return {
    initialFormikSetup: {
      accounts: formikAccountSetup,
      assets: formikAssetSetup,
      date: "",
    },
    formikMappings: { accounts: accountMapping, assets: assetMapping },
  };
};

const validationSchema = yup.object({
  date: yup
    .date()
    .max(new Date(), "Can't be in the future")
    .required("Required")
    .typeError("Invalid date"),
  accounts: yup.array().of(
    yup.object({
      value: yup.number().nullable().typeError("Must be a number"),
    })
  ),
  assets: yup.array().of(
    yup.object({
      value: yup.number().nullable().typeError("Must be a number"),
    })
  ),
});

type Props = {
  assetInputs: AssetInputs;
  portfolioCalibrationRequest: (dto: any) => void;
};

export const EditCalibration: React.FC<Props> = ({
  assetInputs,
  portfolioCalibrationRequest,
}) => {
  const assetSetup = getInitialAssets(assetInputs.accountDefinitions);

  const dates: DateOnly[] = assetInputs.assetCalibrations.map((x) => x.key);

  type formikState = {
    accounts: {
      id: number;
      value: number | string;
    }[];
    assets: {
      id: number;
      value: number | string;
    }[];
    date: string;
  };

  function createAccountAndAssetJSX(accountDefinitions: AccountDefinition[]) {
    let assetCount = 0;

    return accountDefinitions.map((account, accountIndex) => {
      return (
        <Box
          pb={2}
          mb={2}
          // sx={{ borderBottom: 1, borderColor: "grey.500" }}
          key={account.accountInfo.id}
        >
          <TypographyAndTextField
            name={account.accountInfo.name}
            fieldName={`accounts.${accountIndex}.value`}
            label="Value"
            bold={true}
          />
          {account.assetDefinitions?.map((asset, assetIndex) => {
            let assetJsx = (
              <div key={assetIndex}>
                <TypographyAndTextField
                  name={" - " + `${asset.nameAndId.name}`}
                  fieldName={`assets.${assetCount}.value`}
                  label="Value"
                />
              </div>
            );
            assetCount++;
            return assetJsx;
          })}
        </Box>
      );
    });
  }

  const filterFieldsWithValues = (
    input: { id: number; value: number | string }[]
  ) => {
    let output: { id: number; value: number | string }[] = [];
    input.forEach((x) => {
      if (x.value !== "") {
        output.push({ id: x.id, value: x.value });
      }
    });
    return output;
  };

  const sendOutput = (formikState: formikState) => {
    const accounts = filterFieldsWithValues(formikState.accounts);
    const assets = filterFieldsWithValues(formikState.assets);

    const output = {
      accounts: accounts,
      assets: assets,
      date: formikState.date,
    };

    portfolioCalibrationRequest(output);
  };

  const onDateChange = (
    date: string,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => Promise<void | FormikErrors<formikState>>
  ) => {
    const asDateOnly: DateOnly = stringToDateOnly(date);
    const calibration = getCalibration(assetInputs, asDateOnly);

    const assetsCalibrationsMap = new Map(
      calibration.assetCalibrations.map((x) => [x.id, x])
    );
    const accountsCalibrationsMap = new Map(
      calibration.accountCalibrations.map((x) => [x.id, x])
    );

    assetSetup.initialFormikSetup.accounts.forEach((element, index) => {
      const newValue = accountsCalibrationsMap.get(element.id);

      if (newValue === undefined) {
        setFieldValue(`accounts.${index}.value`, "");
      } else {
        setFieldValue(`accounts.${index}.value`, newValue.value);
      }
    });

    assetSetup.initialFormikSetup.assets.forEach((element, index) => {
      const newValue = assetsCalibrationsMap.get(element.id);
      if (newValue === undefined) {
        setFieldValue(`assets.${index}.value`, "");
      } else {
        setFieldValue(`assets.${index}.value`, newValue.value);
      }
    });
  };

  return (
    <Box sx={{ m: 2 }}>
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={assetSetup.initialFormikSetup}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          sendOutput(values);
          setSubmitting(false);
        }}
      >
        {({ values, errors, touched, setFieldValue }) => (
          <Form>
            <Grid container justifyContent="flex-end">
              <FormControl error={Boolean(errors.date && touched.date)}>
                {/* <InputLabel id="date-label">Date</InputLabel> */}
                <Field name="date">
                  {({ field, meta, form }: FieldProps) => (
                    <Select
                      {...field}
                      labelId="date-label"
                      error={Boolean(errors.date && touched.date)}
                      displayEmpty
                      onChange={(event) => {
                        // First, update Formik's state
                        form.setFieldValue(field.name, event.target.value);

                        // Then, call your custom method with the new value
                        onDateChange(event.target.value, setFieldValue);
                      }}
                    >
                      <MenuItem value="" disabled>
                        <em>Select a date</em>
                      </MenuItem>
                      {dates.map((date) => {
                        const dateString = `${date.year}-${String(
                          date.month
                        ).padStart(2, "0")}-${String(date.day).padStart(
                          2,
                          "0"
                        )}`;
                        return (
                          <MenuItem key={dateString} value={dateString}>
                            {dateString}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                </Field>
              </FormControl>
            </Grid>
            <Grid container spacing={1} sx={{ pb: 1 }}>
              <Grid item xs={12}>
                <FieldArray name="assets">
                  {() => (
                    <>
                      {createAccountAndAssetJSX(assetInputs.accountDefinitions)}
                    </>
                  )}
                </FieldArray>
              </Grid>
            </Grid>
            <Box display="flex" justifyContent="right">
              <Button
                type="submit"
                variant="contained"
                size="large"
                sx={{
                  textTransform: "none",
                  backgroundColor: opulColors.opulDarkBlue,
                  color: "common.white",
                  // py: 1,
                  // px: 7,
                  // fontSize: 20,
                }}
              >
                Save portfolio calibration
              </Button>
            </Box>
            {/* <div>Values</div>
            <pre>{JSON.stringify(values, null, 2)}</pre>
            <div>Errors</div>
            <pre>{JSON.stringify(errors, null, 2)}</pre>
            <div>Touched</div>
            <pre>{JSON.stringify(touched, null, 2)}</pre> */}
          </Form>
        )}
      </Formik>
    </Box>
  );
};

//this could be a generic method
function getCalibration(assetInputs: AssetInputs, asDateOnly: DateOnly) {
  const calibration = assetInputs.assetCalibrations.find((calibration) => {
    return equalsDateOnly(calibration.key, asDateOnly);
  });
  if (calibration === undefined)
    throw new Error(
      "Could not find calibration in assetInputs.assetCalibrations."
    );
  return calibration.value;
}
